import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { getOrEnqueueCaseStatus } from '../repository/get-case-status'
import { brandedAIName } from '@/util/enterprise'

type CaseLawReferenceRunCitatorButtonProps = {
  parent_id: string
  tailwindTextSize: string
  onClickCallback: () => void
}

export default function CaseLawReferenceRunCitatorButton(props: CaseLawReferenceRunCitatorButtonProps) {
  const { parent_id, tailwindTextSize, onClickCallback } = props

  // const dispatch = useAppDispatch()

  // Global State
  const citatorState = useAppSelector((state: RootState) => state.citatorState)
  const caseStatusState = citatorState.caseStatuses[parent_id]

  // Handle Run Citator
  // Open the reference tab to the citator tab
  // Fetch the citator status (if not loading)
  function handleFetch() {
    onClickCallback()

    if (caseStatusState?.loading) return
    console.log('Running citator for', parent_id)

    // Fetch case status
    getOrEnqueueCaseStatus(parent_id)
  }

  return (
    <div className={`flex gap-x-1 items-center font-bold ${tailwindTextSize}`}>
      <div>{brandedAIName} Citator:</div>

      {/* If no data button */}
      {!caseStatusState?.loading && !caseStatusState?.jobProcessing && (!caseStatusState?.data || caseStatusState?.error) && (
        <div className={'text-brand-800 pr-1'}>
          <button className={'text-left hover:underline hover:decoration-solid'} onClick={handleFetch}>
            Analyze case
          </button>
        </div>
      )}

      {/* If Processing */}
      {caseStatusState?.jobProcessing && (
        <div className={`decoration-solid underline text-brand-800 cursor-pointer`}>
          <button className={'hover:underline hover:decoration-solid'} onClick={onClickCallback}>
            Analysis in progress
          </button>
        </div>
      )}

      {/* If data button */}
      {caseStatusState?.data && (
        <div className={'decoration-solid underline text-brand-800 cursor-pointer'}>
          <button className={'hover:underline hover:decoration-solid'} onClick={onClickCallback}>
            {caseStatusState.data.status.replace('_', ' ')}
          </button>
        </div>
      )}

      {/* Loader */}
      {caseStatusState?.loading && (
        <>
          <span className={'font-normal pr-[2px]'}>Analyzing status</span>
          <CircularProgressContinuousSized size={12} thickness={7} />
        </>
      )}
      {caseStatusState?.error && <div className={'text-red-700 font-medium'}>error, please try again</div>}
    </div>
  )
}
