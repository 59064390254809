import { useContext } from 'react'
import { AuthContext } from '@/context/auth-context'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import { useNavigate } from 'react-router'
import signOutOfApp from '@/firebase/auth/sign-out'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function OrgAccessDeniedPage() {
  const { user, userAccountData } = useContext(AuthContext)
  const { toggleWidget } = useHubspotConversations()
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-[160px] sm:w-[200px]" src={brandLogoUrl} alt={hostBrand} />
        </div>

        <div className="mt-6 sm:mx-auto w-full sm:max-w-xl">
          <div className="bg-brand-neutral-50 p-10 shadow sm:rounded-lg">
            <h2 className="mb-1 text-lg text-center font-bold tracking-tight text-brand-neutral-900">Organization Account Inactive</h2>
            <p className="text-center mb-2">Please contact your organization administrator.</p>
            <p className="mb-2 text-center text-brand-neutral-700">
              Organization: <span className="font-bold">{userAccountData?.orgData?.name ?? 'Unknown'}</span> <br />
              Account: <span className="font-bold">{user?.email}</span>
            </p>

            <div className="flex justify-center gap-x-4 mt-5 text-center text-sm text-brand-neutral-500">
              <button
                onClick={() => {
                  signOutOfApp(navigate)
                }}
                className="font-semibold leading-6 text-brand-500 hover:text-brand-400"
              >
                Sign out
              </button>
              {hostBrand != BrandEnum.HAIKU && (
                <button
                  onClick={() => {
                    toggleWidget()
                  }}
                  className="font-semibold leading-6 text-brand-500 hover:text-brand-400"
                >
                  Chat with support
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
