import { footnoteAnchorIdFromIndex } from './ResponseReferencesWrapper'
import { useState } from 'react'
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material'
import ShowSourceButton from '@/routes/dashboard/chat/components-shared/ShowSourceButton'
import { SourceReferenceSchema } from '@/store/store-schema'

/**
 * onShowSourceClick is optional. If provided, a show source button will be rendered and the function will be called onClick
 */
type ResponseReferenceProps = {
  source: SourceReferenceSchema
  responseHighlightedFootnote: number
  onShowSourceClick?: (source: SourceReferenceSchema) => void
}

const ResponseReference: React.FC<ResponseReferenceProps> = (props) => {
  const { source, responseHighlightedFootnote, onShowSourceClick } = props

  // local state
  const [expanded, setExpanded] = useState<boolean>(false)

  // Format the source title based on the metadata
  let sourceTitle = ''

  // Construct the title from storageRefFullPath (Your Files UX)
  if (typeof source.metadata?.storageRefFullPath === 'string') {
    // extract the file name from the full path
    const fileName = source.metadata.storageRefFullPath.split('/').pop()

    sourceTitle += fileName ?? ''
  }
  // Construct the title name from the file_path by splitting the path (Laws and Regulations UX)
  else if (typeof source.metadata?.file_path === 'string') {
    const segments = source.metadata.file_path.toUpperCase().replace(/\-/g, ' ').split('/').join('\n')

    sourceTitle += segments
  }
  // Construct the title from case law source
  else if (typeof source.metadata?.case_id === 'string') {
    const publicationInfo = source.metadata.publication_info

    sourceTitle += `${source.metadata.title}\n`
    sourceTitle += publicationInfo
  }
  // all other cases
  else {
    sourceTitle = 'Source'
  }

  // Trim beginning and ending whitespace
  sourceTitle = sourceTitle.trim()

  // Render a show source button if onShowSourceClick is provided

  return (
    <>
      <div id={footnoteAnchorIdFromIndex(source.footnoteNumber)} className={`animate-fadeIn text-xs text-brand-neutral-500`}>
        <div className="grid grid-cols-[30px_30px_auto] mb-3">
          <div onClick={() => setExpanded(!expanded)} className="pt-1 cursor-pointer">
            {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </div>
          <div onClick={() => setExpanded(!expanded)} className="pt-2 pr-1 cursor-pointer">
            <div className="text-right">{`${source.footnoteNumber}.`}</div>
          </div>
          <div>
            <div className={`p-2 ${responseHighlightedFootnote == source.footnoteNumber ? 'bg-brand-50' : ''}`}>
              <div>
                <p className={'whitespace-pre-wrap'}>{sourceTitle}</p>
                <ShowSourceButton onShowSourceClick={onShowSourceClick} source={source} />
                <p className={(expanded ? '' : 'hidden') + ' ' + 'mt-2'}>{source.text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResponseReference
