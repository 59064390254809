import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { Search } from '@mui/icons-material'
import { useContext, useState } from 'react'
import { useListUsersQuery } from '../apis/org-admin.api'
import { AuthContext } from '@/context/auth-context'
import UserListItemMenu from './UserListItemMenu'
import { OrgAdminListUsersUser } from '../schema/org-admin.api.schema'
import InviteListItemMenu from './InviteListItemMenu'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import InviteMemberDialog from './InviteMemberDialog'
import { useAppDispatch } from '@/store/store-hooks'
import { setStripePortalDialogVisible } from '@/store/slices/ui-state.slice'

enum Tab {
  MEMBERS = 'MEMBERS',
  INVITES = 'INVITES',
  BILLING = 'BILLING',
}

export default function UserList() {
  const { userAccountData } = useContext(AuthContext)
  const dispatch = useAppDispatch()

  // Local state
  const [filter, setFilter] = useState<string>('')
  const [activeTab, setActiveTab] = useState<Tab>(Tab.MEMBERS)
  const [inviteDialogOpen, setInviteDialogOpen] = useState<boolean>(false)

  // Tab styles
  const inactiveTabStyles =
    'grow border-transparent text-brand-neutral-500 hover:border-brand-neutral-300 hover:text-brand-neutral-700 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
  const activeTabStyles = ' grow border-brand-400 text-brand-500 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'

  // Load users
  const {
    data: userListData,
    isLoading: userListIsLoading,
    refetch: userListRefetch,
    isError: userListIsError,
    error: userListError,
  } = useListUsersQuery(userAccountData?.orgData?.id || '')

  const userList = userListData?.organization.users ?? []
  const inviteList = userListData?.organization.invites ?? []

  // Loading
  if (userListIsLoading) {
    return (
      <div className={'grow flex justify-center pt-16'}>
        <CircularProgressContinuous />
      </div>
    )
  }

  // Error
  if (userListIsError) {
    console.error('Error loading user list...', userListError)
    return (
      <div className={'grow flex justify-center pt-16'}>
        <div>
          <div className={'text-red-700 mb-2'}>Error loading user list.</div>
          <button
            className={
              'inline-block rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
            }
            onClick={() => userListRefetch()}
          >
            Retry
          </button>
        </div>
      </div>
    )
  }

  // Filter the received user list by the search input
  const filteredUsers = userList.filter(
    (user) =>
      user.first_name?.toLowerCase().includes(filter.toLowerCase()) ||
      user.last_name?.toLowerCase().includes(filter.toLowerCase()) ||
      user.email.toLowerCase().includes(filter.toLowerCase())
  )

  // Split invites into admins
  const justAdmins = filteredUsers.filter((user) => user.role === 'admin')

  // Sort admins alphabetically
  justAdmins.sort((a, b) => {
    const nameA = a.email.toLowerCase()
    const nameB = b.email.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  // Split invites into users
  const justUsers = filteredUsers.filter((user) => user.role === 'user')

  // Sort users alphabetically
  justUsers.sort((a, b) => {
    const nameA = a.email.toLowerCase()
    const nameB = b.email.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  // Filter the invites
  const filteredInvites = inviteList.filter((invite) => invite.invitee_email.toLowerCase().includes(filter.toLowerCase()))

  // Sort invites alphabetically
  filteredInvites.sort((a, b) => {
    const nameA = a.invitee_email.toLowerCase()
    const nameB = b.invitee_email.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  // Construct the display name
  const displayName = (user: OrgAdminListUsersUser): string | null => {
    switch (true) {
      case user.first_name != null && user.last_name != null:
        return `${user.first_name} ${user.last_name}`
      case user.first_name != null:
        return user.first_name
      case user.last_name != null:
        return user.last_name
      default:
        return null
    }
  }

  return (
    <>
      <InviteMemberDialog
        visible={inviteDialogOpen}
        onInviteSuccess={function (): void {
          // Refresh the user's list
          userListRefetch()
          setActiveTab(Tab.INVITES)
        }}
        onClose={() => setInviteDialogOpen(false)}
      />
      <div className={'mb-4'}>
        <button
          className={
            'flex gap-x-2 rounded-md bg-brand-500 py-2 px-4 text-sm font-semibold text-white align-middle shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
          }
          onClick={() => {
            setInviteDialogOpen(true)
          }}
        >
          <UserPlusIcon height={22} /> Invite members
        </button>
      </div>
      <div className={'grow flex flex-col items-start bg-brand-neutral-50 rounded-md pt-1 mb-4'}>
        <nav className="grow w-full flex items-center mb-4" aria-label="Tabs">
          <button
            onClick={() => {
              setActiveTab(Tab.MEMBERS)
            }}
            className={activeTab == Tab.MEMBERS ? activeTabStyles : inactiveTabStyles}
          >
            Members ({userList.length})
          </button>
          <button
            onClick={() => {
              setActiveTab(Tab.INVITES)
            }}
            className={activeTab == Tab.INVITES ? activeTabStyles : inactiveTabStyles}
          >
            Invites ({inviteList.length})
          </button>
          <button
            onClick={() => {
              setActiveTab(Tab.BILLING)
            }}
            className={activeTab == Tab.BILLING ? activeTabStyles : inactiveTabStyles}
          >
            Billing
          </button>
        </nav>

        {activeTab !== Tab.BILLING && (
          <>
            <div className={'grow w-full flex items-center px-4 mb-4'}>
              <input
                type="text"
                className="grow border border-brand-neutral-300 rounded-md p-2 pr-10 text-sm focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
                placeholder="Search name or email"
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
              <div className={'-ml-10 text-brand-neutral-500 p-2'}>
                <Search />
              </div>
            </div>

            <div className={'w-full general-table-wrapper-overflow-fix'}>
              {' '}
              {/* TABLE WRAPPER OVERFLOW FIX */}
              <table className="w-full items">
                <thead>
                  <tr>
                    <td className={'px-4 pb-1 border-b font-bold'}>Name</td>
                    <td className={'px-4 pb-1 border-b font-bold'}>Status</td>
                    <td className={'px-4 pb-1 border-b font-bold'}></td>
                  </tr>
                </thead>
                <tbody>
                  {/* ROWS - Admins Group */}

                  {activeTab == Tab.MEMBERS && filteredUsers.length == 0 && (
                    <tr key={'no-invites'} className={'text-sm border-b bg-white hover:bg-brand-neutral-100 transition-all duration-300'}>
                      <td colSpan={3} className={'px-4 py-2'}>
                        {filter.length > 0 ? 'No members match the search criteria.' : 'There are currently no members.'}
                      </td>
                    </tr>
                  )}

                  {activeTab == Tab.MEMBERS && justAdmins.length > 0 && (
                    <tr key={'divider-admins'} className={'text-sm border-b bg-brand-neutral-200'}>
                      <td colSpan={3} className={'px-4 py-2'}>
                        <div className={'text-sm text-black font-bold'}>Admins</div>
                      </td>
                    </tr>
                  )}
                  {activeTab == Tab.MEMBERS &&
                    justAdmins.map((user) => {
                      return (
                        <tr
                          key={user.id}
                          className={'text-sm border-b bg-white hover:bg-brand-neutral-100 transition-all duration-300'}
                          title={user.has_access == false ? 'This admin is disabled.' : ''}
                        >
                          <td className={`px-4 py-2 ${user.has_access == false ? 'text-brand-neutral-500 line-through' : ''}`}>
                            <div className={'flex flex-col break-all'}>
                              {displayName(user) && <div className={''}>{displayName(user)}</div>}
                              {user.email}
                            </div>
                          </td>
                          <td className={`px-4 py-2 text-sm capitalize ${user.has_access == false ? 'text-brand-neutral-500 line-through' : ''}`}>
                            {user.role}
                          </td>
                          <td className={'px-4 py-2 text-sm w-10'}>
                            <UserListItemMenu userListItem={user} refreshData={userListRefetch} />
                          </td>
                        </tr>
                      )
                    })}

                  {/* ROWS - Users Group */}
                  {activeTab == Tab.MEMBERS && justUsers.length > 0 && (
                    <tr key={'divider-users'} className={'text-sm border-b bg-brand-neutral-200'}>
                      <td colSpan={3} className={'px-4 py-2'}>
                        <div className={'text-sm text-black font-bold'}>Users</div>
                      </td>
                    </tr>
                  )}
                  {activeTab == Tab.MEMBERS &&
                    justUsers.map((user) => {
                      return (
                        <tr
                          key={user.id}
                          className={`text-sm border-b bg-white hover:bg-brand-neutral-100 transition-all duration-300 `}
                          title={user.has_access == false ? 'This user is disabled.' : ''}
                        >
                          <td className={`px-4 py-2 ${user.has_access == false ? 'text-brand-neutral-500 line-through' : ''}`}>
                            <div className={'flex flex-col break-all'}>
                              {displayName(user) && <div className={''}>{displayName(user)}</div>}
                              {user.email}
                            </div>
                          </td>
                          <td className={`px-4 py-2 text-sm capitalize ${user.has_access == false ? 'text-brand-neutral-500 line-through' : ''}`}>
                            {user.role}
                          </td>
                          <td className={'px-4 py-2 text-sm w-10'}>
                            <UserListItemMenu userListItem={user} refreshData={userListRefetch} />
                          </td>
                        </tr>
                      )
                    })}

                  {/* ROWS - Invites */}

                  {activeTab == Tab.INVITES && filteredInvites.length == 0 && (
                    <tr key={'no-invites'} className={'text-sm border-b bg-white hover:bg-brand-neutral-100 transition-all duration-300'}>
                      <td colSpan={3} className={'px-4 py-2'}>
                        {filter.length > 0 ? 'No invitations match the search criteria.' : 'There are no pending invitations.'}
                      </td>
                    </tr>
                  )}

                  {activeTab == Tab.INVITES &&
                    filteredInvites.map((invite) => {
                      // Format the created_at date nicely
                      const createdAt = invite.created_at
                      const formattedDate = !createdAt
                        ? ''
                        : new Date(createdAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })

                      return (
                        <tr key={invite.id} className={'text-sm border-b bg-white hover:bg-brand-neutral-100 transition-all duration-300'}>
                          <td className={'px-4 py-2'}>
                            <div className={'flex flex-col break-all'}>
                              <div>{invite.invitee_email}</div>
                              <div className={'text-brand-neutral-500'}>{formattedDate}</div>
                            </div>
                          </td>
                          <td className={'px-4 py-2 text-sm capitalize'}>Pending</td>
                          <td className={'px-4 py-2 text-sm w-10'}>
                            <InviteListItemMenu inviteListItem={invite} refreshData={userListRefetch} />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}

        {activeTab === Tab.BILLING && (
          <div className="w-full px-4 py-2">
            <h2 className="text-lg font-semibold mb-4">Billing Information</h2>
            <p className="text-sm mb-6">Manage your subscription's billing details with our billing partner, Stripe.</p>

            <button
              type="submit"
              className="flex gap-x-2 rounded-md bg-brand-500 py-2 px-4 text-sm font-semibold text-white align-middle shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
              onClick={() => dispatch(setStripePortalDialogVisible(true))}
            >
              Manage Subscription
            </button>
          </div>
        )}
      </div>
    </>
  )
}
