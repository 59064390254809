import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { useEffect } from 'react'
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { selectConversationCurrentSource, selectConversationIsLoading, selectSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2QueryMetadataAssistant, ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { toggleSelectedFilesContentExpanded, setSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.slice'
import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { selectConversationSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.selectors'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

type SelectedFilesProps = {
  chatId: string
  isOverLimit: boolean
  someProcessingOrPending: boolean
}

function SelectedFilesControl(props: SelectedFilesProps) {
  const { chatId, isOverLimit, someProcessingOrPending } = props
  const dispatch = useAppDispatch()

  // Conversation State
  const currentSource = useAppSelector((state: RootState) => selectConversationCurrentSource(state, { chatId: chatId })) as ChatV2QueryMetadataAssistant | null
  const isLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId }))
  const isExpanded = useAppSelector((state: RootState) => selectConversationSelectedFilesContentExpanded(state, { chatId }))

  // Selected Files State
  const selectedFilePaths = useAppSelector((state: RootState) => selectSelectedFiles(state, { chatId }))
  const selectedFileProcessingStatuses = useAppSelector((state: RootState) => selectFileProcessingStatusesWithKeys(state, { keys: selectedFilePaths }))
  const someErrors = Object.values(selectedFileProcessingStatuses).some((status) => status === FileProcessingStatus.ERROR)
  const selectedFilesCount = selectedFilePaths.length
  const hasSelectedFiles = selectedFilesCount > 0

  const showFileRequiredError = !hasSelectedFiles && currentSource?.focus_mode === ModeChoice.DOCUMENTQUERY

  // Collapse the box when all files are removed
  useEffect(() => {
    if (!hasSelectedFiles) {
      dispatch(setSelectedFilesContentExpanded({ conversationId: chatId, expanded: false }))
    }
  }, [hasSelectedFiles, chatId])

  // Collapse the box when the conversation starts loading (user submitted query)
  useEffect(() => {
    if (isLoading) {
      dispatch(setSelectedFilesContentExpanded({ conversationId: chatId, expanded: false }))
    }
  }, [isLoading, chatId])

  // Expand the box when there are errors
  useEffect(() => {
    if (someErrors) {
      dispatch(setSelectedFilesContentExpanded({ conversationId: chatId, expanded: true }))
    }
  }, [someErrors, chatId])

  const toggleSelectedFilesBox = () => {
    dispatch(toggleSelectedFilesContentExpanded({ conversationId: chatId }))
  }

  if (!hasSelectedFiles && !showFileRequiredError) return null

  const iconStyles = `self-center h-4 w-4 ${isLoading ? 'text-brand-neutral-500' : 'hover:text-brand-500'}`
  const errorMessageStyles = `flex xs:justify-end m-1 text-red-700 text-xs`

  return (
    <>
      {hasSelectedFiles && !someErrors && (
        // Control for collapsing the selected files box and user feedback
        <button
          className={`ml-auto flex text-xs xs:text-sm mx-1 justify-end text-brand-neutral-500 gap-2 p-1 ${isLoading ? 'cursor-default opacity-50' : 'cursor-pointer'}`}
          onClick={toggleSelectedFilesBox}
          disabled={isLoading}
        >
          {someProcessingOrPending ? (
            <div className={'flex gap-x-2 items-center'}>
              <CircularProgressContinuousSized size={11} thickness={7} /> <div>Processing files...</div>
            </div>
          ) : (
            <>
              {/* Selected files count display */}
              <span>
                {selectedFilesCount}
                {/* Only show "Selected" on screens larger than xs if isOverLimit (to leave space for warning icon) */}
                {!isOverLimit && <span> Selected</span>}
                {isOverLimit && <span className="hidden xs:inline"> Selected</span>}
                {selectedFilesCount > 1 ? ' Files' : ' File'}
              </span>

              {/* Warning icon if files are over limit */}
              {isOverLimit && <ExclamationTriangleIcon className="text-yellow-600 self-center -mb-[2px]" />}
            </>
          )}
          {isExpanded ? <ChevronDownIcon className={iconStyles} /> : <ChevronUpIcon className={iconStyles} />}
        </button>
      )}
      {someErrors && <span className={errorMessageStyles}>Error processing file(s). Please try again.</span>}
      {showFileRequiredError && <span className={errorMessageStyles}>Add a file to continue in analysis mode</span>}
    </>
  )
}

export default SelectedFilesControl
