import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { kUserSettingsApiPath, kSettingsBasePath } from '../../constants/constants-api-paths'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { SettingsGetResponse, SettingsPatchResponse, SettingsGetResponseSchema, SettingsPatchResponseSchema } from '../schemas/settings-schema'
import { prepareRtkQueryHeaders, transformRtkQueryResponse } from '@/store/apis/rtk-query-utils'
import { store } from '@/store/store'
import { setPromptAssistEnabled } from '../store/chat-v2-ux.slice'

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: kPaxtonAppApiBaseUrl(),
    prepareHeaders: async (headers) => await prepareRtkQueryHeaders(headers),
  }),
  endpoints: (builder) => ({
    updateUserSettings: builder.mutation<SettingsPatchResponse, { prompt_assist_enabled: boolean }>({
      query: (settings) => ({
        url: kUserSettingsApiPath,
        method: 'PATCH',
        body: settings,
      }),
      transformResponse: (response: unknown) => {
        const parsed = SettingsPatchResponseSchema.parse(response)
        store.dispatch(setPromptAssistEnabled({ enabled: parsed.data.prompt_assist_enabled }))
        return parsed
      },
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
    getUserSettings: builder.query<SettingsGetResponse, void>({
      query: () => ({
        url: kSettingsBasePath,
        method: 'GET',
      }),
      transformResponse: (response: unknown) => {
        const parsed = SettingsGetResponseSchema.parse(response)
        store.dispatch(setPromptAssistEnabled({ enabled: parsed.data.user.prompt_assist_enabled }))
        return parsed
      },
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
  }),
})

export const { useUpdateUserSettingsMutation, useGetUserSettingsQuery } = settingsApi
