import { chatV2SetVisibleReference } from '@/chat-common/store/chat-v2.slice'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'
import referenceMetadataToTitleSuffix from '@/chat-common/util/reference-metadata-to-title-suffix'
import CaseLawReferenceRunCitatorButton from '@/citator/views/CaseLawRunCitatorReferenceButton'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { isCitatorEnabled } from '@/chat-common/components/reference-viewer/reference-view-utils'
import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import { RootState } from '@/store/store'
import { selectConversationFeature, selectMessageFromId, selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'
import ReferenceMetadataSourcePages from '@/chat-common/util/reference-metadata-pages'

type ChatMessageBubbleReferenceProps = {
  conversationId: string
  messageId: string
  referenceKey: string
}

function isBBCitation(reference: ChatV2MessageReferenceType): boolean {
  return typeof reference.metadata?.bb_citation === 'string'
}

export default function ChatMessageBubbleReference(props: ChatMessageBubbleReferenceProps) {
  const { conversationId, messageId, referenceKey } = props
  const dispatch = useAppDispatch()

  // Redux State Selectors
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))
  const message = useAppSelector((state: RootState) => selectMessageFromId(state, { messageId, chatId: conversationId }))
  const visibleReference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))

  // Inferred values
  const thisReference = message.metadata.references[referenceKey]
  const hasBBCitation = isBBCitation(thisReference)
  const title = referenceMetadataToTitle(thisReference, hasBBCitation)
  const titleSuffix = referenceMetadataToTitleSuffix(thisReference, hasBBCitation)
  const highlight: boolean = visibleReference != null && visibleReference == thisReference
  const parentId = thisReference?.metadata.parent_id

  return (
    <div className={`mb-2 p-1 last:mb-2 ${highlight ? 'bg-brand-100 bg-opacity-90' : ''}`}>
      <div className={'flex gap-x-3'}>
        <button
          onClick={() => {
            dispatch(
              chatV2SetVisibleReference({
                conversationId: message.metadata.conversation_id,
                reference: thisReference,
              })
            )
          }}
          className={`font-bold text-xs text-brand-800 cursor-pointer hover:underline inline-block`}
        >
          [{thisReference.reference_number}] View source
        </button>
        {isCitatorEnabled(feature, thisReference) && parentId && (
          <Link
            to={`/dashboard/reference-viewer/caselaw/${parentId}`}
            target="_blank"
            className={'flex items-end text-brand-800 font-bold text-xs cursor-pointer hover:underline'}
          >
            <span className={''}>New tab</span> <ExternalLinkIcon className={'w-4 h-4 pl-[4px] pb-[0px]'} />
          </Link>
        )}
      </div>
      {hasBBCitation && (
        <div className={`text-xs break-all`}>
          <i>{title}</i>
          {titleSuffix}
        </div>
      )}
      {!hasBBCitation && <div className={`text-xs break-all`}>{title}</div>}
      <ReferenceMetadataSourcePages reference={thisReference} />
      {isCitatorEnabled(feature, thisReference) && parentId && (
        <CaseLawReferenceRunCitatorButton
          parent_id={parentId}
          tailwindTextSize="text-xs"
          onClickCallback={() => {
            // Open the reference tab to the citator tab
            dispatch(
              chatV2SetVisibleReference({
                conversationId: message.metadata.conversation_id,
                reference: thisReference,
                openRefToCitatorTab: true,
              })
            )
          }}
        />
      )}
    </div>
  )
}
