import { fileUploadIsProcessing } from '@/routes/dashboard/files/files-utils'
import { FileNode } from '../hooks/useProcessedFiles'
import { FullMetadata } from 'firebase/storage'
import { FileStatus } from './file-status'

export function getFileStatus(file: FileNode): { status: FileStatus; errorMessage?: string } {
  const customMetadata = file.metadata?.customMetadata || null

  if (!customMetadata) {
    return { status: 'error' }
  }

  const processingError = customMetadata.processing_error === 'true'
  const processing = fileUploadIsProcessing(file.metadata as FullMetadata)

  if (processing) {
    return { status: 'processing' }
  }

  if (processingError) {
    const errorMessage = customMetadata.error || 'An error occurred during processing.'
    return { status: 'error', errorMessage }
  }

  if (customMetadata.image_content_flag === 'FULL') {
    return { status: 'ocr', errorMessage: 'File needs to be converted' }
  }

  return { status: 'complete' }
}
