import { z } from 'zod'

// Organization User
export const OrgAdminListUsersUserSchema = z.object({
  id: z.string(),
  email: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  organization_id: z.string(),
  firebase_id: z.string(),
  role: z.string(),
  has_access: z.boolean().nullable(),
})
export type OrgAdminListUsersUser = z.infer<typeof OrgAdminListUsersUserSchema>

// Organization Invite
export const OrgAdminListUsersInviteSchema = z.object({
  id: z.string(),
  invitee_id: z.string().nullable(),
  invitee_email: z.string(),
  organization_id: z.string(),
  complete: z.boolean(),
  created_at: z.string().optional(),
  modified_on: z.string().optional(),
})
export type OrgAdminListUsersInvite = z.infer<typeof OrgAdminListUsersInviteSchema>

// Organization Response
export const OrgAdminListUsersResponseSchema = z.object({
  organization: z.object({
    id: z.string(),
    name: z.string(),
    seats: z.number(),
    subscription_status: z.string(),
    has_access: z.boolean(),
    users: z.array(OrgAdminListUsersUserSchema),
    invites: z.array(OrgAdminListUsersInviteSchema),
  }),
})

export type OrgAdminListUsersResponse = z.infer<typeof OrgAdminListUsersResponseSchema>
