import { useForm } from 'react-hook-form'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { RootState } from '@/store/store'
import { callDocumentAuditQueryStream } from '@/store/query-streams/document-audit-query-stream'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { DocumentAuditSource, setResponseHighlightedFootnote } from '../../../store/slices/document-audit-stream-response.slice'
import { kSegmentTrackAuditQuerySubmit, kSegmentTrackFootnoteClick } from '@/constants/constants-segment'
import { LightBulbIcon } from '@heroicons/react/24/outline'
import ExamplesBlockAudit from '../examples/ExamplesBlockAudit'
import AuditResponse from './AuditResponse'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import ResponseReferences from '../chat/components-shared/ResponseReferencesWrapper'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { brandedAIFriendlyName } from '@/util/enterprise'

/**
 * THIS IS A CLONE OF THE CHAT PAGE
 * IT COMMENTS OUT SEVERAL FEATURES UNUSED FOR THIS PAGE CURRENTLY, BUT WHICH MAY BE USED IN THE FUTURE
 */

type FormInputs = {
  query: string
  document_1: string
  document_2: string
}

const validationSchema = z.object({
  query: z.string().min(10, 'Please be more specific. At least 10 characters are required.'),
  document_1: z.string().min(50, 'The document must be longer. At least 50 characters are required.'),
  document_2: z.string().min(50, 'The document must be longer. At least 50 characters are required.'),
})

export default function AuditPage() {
  const { trackEvent } = useAnalytics()
  // Store data
  const { sources, streamedResponseString, streamLoading, streamError, responseUniqueFootnotes, responseHighlightedFootnote, searchedQuery } = useAppSelector(
    (state: RootState) => state.documentAuditApiResponse
  )

  // Actions
  const dispatch = useAppDispatch()

  // Filter sources for source display
  const usedSources: DocumentAuditSource[] = sources.filter((source) => responseUniqueFootnotes.includes(source.footnoteNumber))
  const unUsedSources: DocumentAuditSource[] = sources.filter((source) => !responseUniqueFootnotes.includes(source.footnoteNumber))

  // // Show suggestions if we have all the appropriate information
  // const showSuggestions = searchedQuery != null && streamedResponseString != null && searchedQuery != '' && streamedResponseString != '' && !streamLoading

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      query: '',
      document_1: '',
      document_2: '',
    },
    resolver: zodResolver(validationSchema),
  })

  // async function handleSuggestionClick(suggestion: string) {
  //   console.log('suggestion clicked: ', suggestion)
  //   setValue('query', suggestion)

  //   // Set follow up to true only for this situation
  //   await handleSubmit(handleFormSubmit)()
  // }

  async function handleFormSubmit(data: FormInputs) {
    // Track
    analytics.track(kSegmentTrackAuditQuerySubmit)

    console.log('Form submit data: ', data)
    await executeQuery(data)
  }

  // Sign in error component
  function QueryErrorMessage() {
    if (streamError != '') {
      return InlineTipError(streamError)
    } else {
      return null
    }
  }

  async function executeQuery(formInputs: FormInputs) {
    if (streamLoading) return
    callDocumentAuditQueryStream({ query: formInputs.query, document_1: formInputs.document_1, document_2: formInputs.document_2 })
  }

  return (
    <>
      <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0 overflow-y-scroll'}>
        <div className={'flex-grow'}>
          <div className="bg-brand-neutral-50 shadow rounded-lg sm:max-w-screen-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-brand-neutral-900">Compare Documents</h3>
              <div className="my-2 text-sm text-brand-neutral-600">
                <p className="mb-2">Paste the contents of document 1, document 2, then type the query you want to perform.</p>
                <p className="rounded-lg bg-brand-50 p-4 my-4 grid grid-cols-[45px_auto] items-center">
                  <span className={'inline-block h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white'}>
                    <LightBulbIcon />
                  </span>
                  <span className={'inline-block text-xs text-brand-900 text-opacity-70'}>
                    Tip: You can copy and paste entire documents from PDF, Word, Websites, etc.
                    <br />
                    Text formatting does not matter.
                  </span>
                </p>
              </div>

              <form className="" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="w-full">
                  <label htmlFor="document_1" className="block mb-1 text-sm font-medium text-brand-neutral-700">
                    Document 1
                  </label>
                  {errors.document_1 && <p className={'text-red-700 text-sm'}>{errors.document_1.message}</p>}
                  <textarea
                    id="document_1"
                    spellCheck={'false'}
                    rows={3}
                    {...register('document_1', { required: true })}
                    autoComplete="document_1"
                    required
                    className="block w-full rounded-md border-0 p-2 mb-4 text-brand-neutral-900 text-sm shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500"
                    placeholder="Paste document 1 here"
                  />

                  <label htmlFor="document_2" className="block mb-1 text-sm font-medium text-brand-neutral-700">
                    Document 2
                  </label>
                  {errors.document_2 && <p className={'text-red-700 text-sm'}>{errors.document_2.message}</p>}
                  <textarea
                    id="document_2"
                    spellCheck={'false'}
                    rows={3}
                    {...register('document_2', { required: true })}
                    autoComplete="document_2"
                    required
                    className="block w-full rounded-md border-0 p-2 mb-4 text-brand-neutral-900 text-sm shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500"
                    placeholder="Paste document 2 here"
                  />

                  <label htmlFor="query" className="block mb-1 text-sm font-medium text-brand-neutral-700">
                    Your query
                  </label>
                  {errors.query && <p className={'text-red-700 text-sm'}>{errors.query.message}</p>}
                  <textarea
                    id="query"
                    rows={3}
                    {...register('query', { required: true })}
                    autoComplete="query"
                    required
                    className="block w-full rounded-md border-0 p-2 mb-4 text-brand-neutral-900 text-sm shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500"
                    placeholder={`Tell ${brandedAIFriendlyName} what to do here`}
                  />
                </div>
                <div className={'text-right'}>
                  <button
                    type="submit"
                    className="w-full sm:w-auto rounded-md bg-brand-500 px-3 py-2 mt-1 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 "
                    onClick={() => {
                      trackEvent(AnalyticsEvent.CompareDocumentsSubmission)
                    }}
                  >
                    Submit
                    <ButtonLoader loading={streamLoading} />
                  </button>
                </div>
              </form>
              <QueryErrorMessage />
            </div>
          </div>

          {searchedQuery && (
            <div className="mt-8 bg-brand-neutral-50 shadow rounded-lg sm:max-w-screen-lg">
              <div className="px-4 py-5 sm:p-6">
                <AuditResponse
                  queryTitle={searchedQuery}
                  responseString={streamedResponseString}
                  footnoteClicked={(index) => {
                    // Track
                    analytics.track(kSegmentTrackFootnoteClick)

                    dispatch(setResponseHighlightedFootnote(index))
                  }}
                />
                <div className="h-6" />
                {/* Do not display while stream is loading
            {showSuggestions && (
              <SuggestedQuestions
                onSuggestionClick={(suggestion) => handleSuggestionClick(suggestion)}
                userContent={searchedQuery}
                assistantContent={streamedResponseString}
                display={!streamLoading}
              />
            )} */}

                <div className="h-6" />
                <ResponseReferences sectionTitle="References" sources={usedSources} display={true} responseHighlightedFootnote={responseHighlightedFootnote} />
                <div className="h-6" />
                {/* Do not display until we are done loading the whole response stream */}
                <ResponseReferences
                  sectionTitle="Related"
                  sources={unUsedSources}
                  display={!streamLoading}
                  responseHighlightedFootnote={responseHighlightedFootnote}
                />
              </div>
            </div>
          )}

          {/* Show examples if there is no searched query */}
          {!searchedQuery && (
            <>
              <div className="h-5" />
              <ExamplesBlockAudit />
            </>
          )}
        </div>
      </div>
    </>
  )
}
