import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Article } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React from 'react'
import { FileNode } from '../hooks/useProcessedFiles'
import { getFileStatus } from './get-file-status'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

export type FileStatus = 'processing' | 'complete' | 'error' | 'ocr'

const statusConfig: Record<FileStatus, { icon: React.ReactNode; toolTipMessage?: string }> = {
  processing: {
    icon: <CircularProgressContinuousSized className="ml-1 mr-1" size={16} thickness={7} />,
  },
  complete: {
    icon: <Article />,
  },
  error: {
    icon: <ExclamationCircleIcon height={20} className={'text-white bg-red-600 rounded-full'} />,
    toolTipMessage: 'Error processing file, please try again',
  },
  ocr: {
    icon: <ExclamationCircleIcon height={20} className={'text-white bg-red-600 rounded-full'} />,
    toolTipMessage: 'Error processing file, file needs to be converted',
  },
}

export default function DynamicFileStatusIcon({ file }: { file: FileNode }) {
  const { status } = getFileStatus(file)
  const { icon, toolTipMessage } = statusConfig[status as FileStatus]

  return (
    <div className="inline-flex align-middle">
      {toolTipMessage ? (
        <Tooltip title={toolTipMessage} placement="top">
          <span>{icon}</span>
        </Tooltip>
      ) : (
        <span>{icon}</span>
      )}
    </div>
  )
}

export function DynamicStatusIconFromFile(status: FileStatus) {
  const { icon, toolTipMessage } = statusConfig[status]
  return (
    <div className="inline-flex align-middle">
      {toolTipMessage ? (
        <Tooltip title={toolTipMessage} placement="top">
          <span>{icon}</span>
        </Tooltip>
      ) : (
        <span>{icon}</span>
      )}
    </div>
  )
}
