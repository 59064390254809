import { fileUploadIsProcessing, getFileMetadataFromGCS } from '@/routes/dashboard/files/files-utils'
import { store } from '@/store/store'
import * as Sentry from '@sentry/react'
import { FileProcessingStatus, setFileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'

/**
 * Poll for file status until success
 * Updates redux state chatV2UxState.selectedFilesProcessingStatuses
 * @param sourcePath is the path of the file in GCS
 * @returns void
 */
export async function pollForFileStatusUntilSuccess(sourcePath: string) {
  try {
    // Check the file status
    const fileMetadata = await getFileMetadataFromGCS(sourcePath)
    const fileIsProcessing = fileUploadIsProcessing(fileMetadata)

    // If the file processing is false and the file has an error, set the file status to error
    if (fileMetadata.customMetadata?.error) {
      // Set the file status to error
      store.dispatch(setFileProcessingStatus({ sourcePath, status: FileProcessingStatus.ERROR }))
      return
    }

    // If the file is not processing, dispatch success and stop polling
    if (!fileIsProcessing) {
      store.dispatch(setFileProcessingStatus({ sourcePath, status: FileProcessingStatus.SUCCESS }))
      return
    }

    // If the file is still processing, keep polling (recursive)
    store.dispatch(setFileProcessingStatus({ sourcePath, status: FileProcessingStatus.PROCESSING }))
    await new Promise((resolve) => setTimeout(resolve, 2500))
    pollForFileStatusUntilSuccess(sourcePath)
  } catch (error) {
    store.dispatch(setFileProcessingStatus({ sourcePath, status: FileProcessingStatus.ERROR }))

    Sentry.captureException(new Error(`Failed to poll for file's processing status`), {
      extra: {
        error: JSON.stringify(error),
      },
    })

    // Return here to stop the recursive polling
    return
  }
}
