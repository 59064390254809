import { Link } from 'react-router-dom'
import { useGetEditingSessionsQuery } from '../apis/document-editing.api'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { Article } from '@mui/icons-material'
import { PlusIcon } from '@radix-ui/react-icons'

export default function DocumentEditingSessionsList() {
  // Load list (RTK-query)
  const { data, isLoading, refetch, isError, error } = useGetEditingSessionsQuery(null)

  // Loading
  if (isLoading) {
    return (
      <div className={'grow flex justify-center pt-16'}>
        <CircularProgressContinuous />
      </div>
    )
  }

  // Error
  if (isError || !data) {
    console.error('Error loading history...', error)
    return (
      <div className={'grow flex justify-center pt-16'}>
        <div>
          <div className={'text-red-700 mb-2'}>Error loading history.</div>
          <button
            className={
              'inline-block rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
            }
            onClick={() => refetch()}
          >
            Retry
          </button>
        </div>
      </div>
    )
  }

  // Sort by date
  const analyses = [...data.analyses].sort((a, b) => {
    const dateA = new Date(a.created_at)
    const dateB = new Date(b.created_at)
    return dateB.getTime() - dateA.getTime()
  })

  // Data
  return (
    <div className={'flex-grow overflow-y-scroll pt-16 lg:pt-4'}>
      <Link
        to="/dashboard/document-editing/new"
        className={
          'inline-block ml-2 lg:ml-0 py-2 px-3 mb-4 rounded-md bg-brand-500 text-sm font-semibold text-white align-middle shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
        }
      >
        <div className={'flex items-center gap-x-2'}>
          <PlusIcon /> New Contract Analysis
        </div>
      </Link>

      {/* Toolbar */}
      {/* <div className={`px-2 mb-1 flex gap-x-2 ${!toolbarDisabled ? 'opacity-100' : 'opacity-10'} transition-all duration-200 ease-in-out`}>
        <button
          onClick={clearAllFilesAndFolders}
          disabled={toolbarDisabled}
          className={`flex gap-x-1 px-2 py-1 pr-3 items-center rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
            !toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <Cross2Icon width={20} height={20} />
          <div className={'text-sm'}>{selectedFiles.length + selectedFolders.length} selected</div>
        </button>

        <button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          disabled={toolbarDisabled}
          className={`flex gap-x-2 px-2 py-1 pr-3  rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
            !toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <TrashIcon width={20} height={20} />
          <div className={'text-sm'}>Delete</div>
        </button>
      </div> */}

      <div className={'bg-brand-neutral-50 shadow rounded-lg'}>
        <div className={'py-2 font-bold grid grid-cols-[100%_0px] sm:grid-cols-[auto_175px] md:grid-cols-[auto_175px] items-end overflow-hidden'}>
          {/* <div>
            <input
              type="checkbox"
              checked={allFilesAndFoldersSelected}
              className={`w-4 h-4 ${allFilesAndFoldersSelected ? 'opacity-100' : 'opacity-30'} hover:opacity-100 transition-all ease-in-out duration-200`}
              onChange={() => (allFilesAndFoldersSelected ? clearAllFilesAndFolders() : selectAllFilesAndFolders())}
              value={'test'}
            />
          </div> */}
          <p className={'text-sm px-2'}>Analyzed Documents</p>
          <p className={'text-sm px-2 hidden sm:block'}>Date</p>
        </div>
        <div>
          {/* Empty Editing Sessions Display */}
          {analyses.length == 0 && <p className={'text-sm px-4'}>Create a new contract analysis.</p>}

          {/* List Files */}
          {analyses.length > 0 &&
            analyses.map((analysis) => {
              // Format date
              const date: Date = new Date(analysis.created_at)
              const formattedDate = Intl.DateTimeFormat('en-US', {
                dateStyle: 'medium',
                timeStyle: 'short',
              }).format(date)

              // isChecked?
              // const isChecked = selectedFiles.includes(analysis.fullPath)

              return (
                <Link
                  key={analysis.id}
                  to={`/dashboard/document-editing/doc/${analysis.id}`}
                  className={
                    'group border-t-[1px] py-2 grid grid-cols-[100%_0px] sm:grid-cols-[auto_175px] md:grid-cols-[auto_175px] items-center overflow-hidden cursor-pointer hover:bg-brand-neutral-100 transition-all duration-300 ease-in-out'
                  }
                >
                  <div className={'px-2 grid grid-cols-[30px_auto] items-center'}>
                    {/* <input
                  type="checkbox"
                  checked={isChecked}
                  className={`w-4 h-4 ${
                    isChecked ? 'opacity-100' : 'opacity-30'
                  } group-hover:opacity-100 transition-all ease-in-out duration-200 checked:bg-brand-500 rounded-sm`}
                  onChange={handleFileCheckboxChange}
                  value={analysis.fullPath}
                /> */}
                    <Article />
                    <div className={'text-sm overflow-hidden flex items-center'}>
                      <p className={'flex-grow'}>{analysis.title}</p>
                    </div>
                  </div>
                  <p className={'text-sm px-2 hidden sm:block'}>{formattedDate}</p>
                </Link>
              )
            })}
          <div className={'h-12'} />
        </div>
      </div>
    </div>
  )
}
