// PWAUpdateModal.tsx
import React, { useEffect } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { CircularProgressContinuousSized } from '../loaders/CircularProgressContinuous'
import { getBrandName } from '@/util/enterprise'

const PWAUpdateModal: React.FC = () => {
  const { needRefresh, updateServiceWorker } = useRegisterSW()
  const [isUpdateAvailable, setUpdateAvailable] = React.useState(false)
  const [showDelaySpinner, setShowDelaySpinner] = React.useState(false)

  useEffect(() => {
    setUpdateAvailable(needRefresh[0])
  }, [needRefresh])

  const updateApp = async () => {
    // Attempted fix for PWA update modal appearing again after refresh
    // hypothesis is that the user can refresh before the service worker has updated
    setShowDelaySpinner(true)
    await new Promise((resolve) => setTimeout(resolve, 750))

    await updateServiceWorker()
  }

  // Source Selection Overlay Fix
  // Remove headless UI dialog from the DOM if an update is available
  // Allows the PWA update dialog to be clicked since
  // headlessui-portal-root dialog gets dynamically inserted above this in the DOM structure
  // adjacent to the react root, and blocks clicking of the PWA dialog.
  useEffect(() => {
    if (isUpdateAvailable) {
      // Get the dialog element
      const headlessUiDialogRoot = document.body.children.namedItem('headlessui-portal-root')
      console.log('Found headless UI dialog.')

      // Remove the dialog element
      if (headlessUiDialogRoot) {
        console.log('Removing headless UI dialog...')
        headlessUiDialogRoot.remove()
      }
    }
  }, [isUpdateAvailable])

  // Do not show the modal if there is no update available
  if (!isUpdateAvailable) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[999999] bg-black bg-opacity-50">
      <div className="bg-brand-neutral-50 p-6 mx-2 rounded-md shadow-lg relative">
        <h2 className="text-xl font-semibold">App Update Ready</h2>
        <p className="mt-2 mb-2 text-xs text-brand-neutral-500">v{APP_VERSION}</p>
        <p className="mt-2 mb-4">Click refresh to load the latest version of {getBrandName()}.</p>
        <div className={'flex items-center gap-x-2'}>
          <button
            className="inline-flex w-full justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 sm:w-auto"
            onClick={updateApp}
          >
            Refresh
          </button>
          {showDelaySpinner && <CircularProgressContinuousSized size={16} thickness={7} />}
        </div>
      </div>
    </div>
  )
}

export default PWAUpdateModal
