import ReactDOMServer from 'react-dom/server'
import { saveAs } from 'file-saver'
import { ChatV2Message, ChatV2MessageType, ChatV2Conversation } from '@/chat-common/store/chat-v2.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import { kChatHtmlToDocxPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { sanitizeFileName } from '@/routes/dashboard/files/files-utils'
import ChatResponsePrintableBubble from '@/chat-common/components/message-bubbles/chat-response-printable-bubble'
import ChatUserQueryPrintableBubble from '@/chat-common/components/message-bubbles/chat-user-query-printable-bubble'
import * as Sentry from '@sentry/react'

import { store } from '@/store/store'

function getFilteredMessagesArray(conversation: ChatV2Conversation) {
  const messages = conversation?.messages ?? {}
  const messagesArray: ChatV2Message[] = Object.values(messages) ?? []
  return messagesArray.filter(
    (message) => message.metadata.message_type !== ChatV2MessageType.suggested_questions && message.metadata.message_type !== ChatV2MessageType.client_error
  )
}

function buildMessageComponents(messages: ChatV2Message[], conversation: ChatV2Conversation) {
  const messageComponents = messages.map((message) => {
    switch (message.metadata.message_type) {
      case ChatV2MessageType.response:
        return <ChatResponsePrintableBubble key={`response-print-${message.metadata.message_id}`} message={message} />
      case ChatV2MessageType.user_query:
        return <ChatUserQueryPrintableBubble key={`query-print-${message.metadata.message_id}`} message={message} conversation={conversation} />
      default:
        return null
    }
  })
  return <>{messageComponents}</>
}

export async function buildMessagesHTML(messageComponents: JSX.Element, title: string) {
  const messagesHTML = ReactDOMServer.renderToStaticMarkup(messageComponents)

  const document = `
  <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
    </head>
    <body>
      ${messagesHTML}
    </body>
  </html>
  `

  return document
}

async function saveHTMLToFile(html: string, fileName: string) {
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)

  // Create transaction id
  const sentry_transaction_id = nanoid()
  try {
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ html }),
    }

    // Call the backend to convert html to docx file
    const apiUrl = kPaxtonAppApiBaseUrl() + kChatHtmlToDocxPath
    const response = await fetch(apiUrl, requestOptions)

    // Save the docx file
    const blob = await response.blob()
    const outputName = sanitizeFileName(fileName) + '.docx'
    saveAs(blob, outputName)
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `The download is ready.`, durationMs: 3000 }))
  } catch (error) {
    console.error('Error fetching html2Docx:', error)
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'The download has failed.', durationMs: 3000 }))
    throw error
  }
}
export async function copyResponseToClipboard(message: ChatV2Message) {
  const printableMessage = <ChatResponsePrintableBubble message={message} />
  const messagesHTML = await buildMessagesHTML(printableMessage, '')
  const outputHtml = formatHtmlForDocXTransformation(messagesHTML)

  const tempElement = document.createElement('div')
  tempElement.innerHTML = outputHtml
  document.body.appendChild(tempElement)

  const range = document.createRange()
  range.selectNode(tempElement)

  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }

  try {
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob([tempElement.innerText], { type: 'text/plain' }),
      'text/html': new Blob([tempElement.innerHTML], { type: 'text/html' }),
    })

    navigator.clipboard.write([clipboardItem])
    console.log('Content copied to clipboard!')
  } catch (err) {
    console.error('Failed to copy content:', err)
    document.body.removeChild(tempElement)
  }

  if (selection) {
    selection.removeAllRanges()
  }
  document.body.removeChild(tempElement)
}

export async function downloadResponseAsDocxRemote(fileName: string, printableElement: JSX.Element) {
  const messagesHTML = await buildMessagesHTML(printableElement, fileName)
  const outputHtml = formatHtmlForDocXTransformation(messagesHTML)
  saveHTMLToFile(outputHtml, fileName)
}

export async function downloadConversationAsDocxRemote(conversation: ChatV2Conversation | null) {
  if (!conversation) {
    Sentry.captureException(new Error('Conversation is null for downloadConversationAsDocxRemote()'))
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: `Error preparing the download.`, durationMs: 3000 }))
    return
  }

  store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `Preparing download...`, durationMs: 3000 }))

  // Get or construct fileName
  const fileName = conversation.title ?? `${nanoid()}-download`

  const filteredMessagesArray = getFilteredMessagesArray(conversation)
  const messageComponents = buildMessageComponents(filteredMessagesArray, conversation)
  const messagesHTML = await buildMessagesHTML(messageComponents, fileName)
  const outputHtml = formatHtmlForDocXTransformation(messagesHTML)
  saveHTMLToFile(outputHtml, fileName)
}

function indentNestedElements(element: HTMLElement | Element, depth: number, lastTag = '') {
  let currentDepth = depth
  let incrementDepth = false
  Array.from(element.children).forEach((child, index) => {
    const childTagName = child.tagName.toLowerCase()

    // If the first child is not a section, but the last child was, increment the depth
    if (index === 0 && childTagName !== 'section' && lastTag === 'section') {
      // Only increment the depth once per section, prevents siblings from accidentally incrementing the depth
      if (!incrementDepth) {
        currentDepth = currentDepth + 1
        incrementDepth = true
      }

      // Indent the child element with four non-breaking spaces times the depth
      const indentation = '&nbsp;'.repeat(4 * depth)
      child.innerHTML = indentation + child.innerHTML
    }

    // Recursively indent the child's children, increasing the depth
    indentNestedElements(child, currentDepth, childTagName)
  })
}

export function indentHtmlString(htmlString: string) {
  try {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const body = doc.body

    // Start the indentation process with the root element and an initial depth of 0
    indentNestedElements(body, 0)

    // Serialize the document body back to an HTML string

    return body.innerHTML
  } catch (error) {
    console.error('Error indenting html string:', error)
  }

  return htmlString
}

export function formatHtmlForDocXTransformation(sourceHTML: string) {
  // Regex for cleaning the HTML
  const highlightRegex = /<span class="highlighted-snippet bg-yellow-300">(.*?)<\/span>/g
  const h1ToBRegex = /<h\d>(.*?)<\/h\d>/g

  let outputHtml = sourceHTML
  try {
    // Clean the HTML to fit display format
    outputHtml = outputHtml.replace(highlightRegex, '<mark>$1</mark>')
    outputHtml = outputHtml.replace(h1ToBRegex, '<b>$1</b>')

    // // Only intend for specific features
    // if ([ChatV2Feature.lrr.toString(), ChatV2Feature.lrr_v2.toString()].includes(feature)) {
    //   outputHtml = indentHtmlString(outputHtml)
    // }
  } catch (error) {
    console.error('Error processing html string:', error)
  }
  return outputHtml
}

export async function downloadReferenceAsDocX(sourceHTML: string, fileName: string, onLoading: (loading: boolean) => void) {
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw Error(kErrorCodeAuthError)

  // Create transaction id
  const sentry_transaction_id = nanoid()

  const outputHtml = formatHtmlForDocXTransformation(sourceHTML)

  try {
    onLoading(true)
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({ html: outputHtml }),
    }

    // Call the backend to convert html to docx file
    const apiUrl = kPaxtonAppApiBaseUrl() + kChatHtmlToDocxPath
    const response = await fetch(apiUrl, requestOptions)

    // Save the docx file
    const blob = await response.blob()
    const outputName = sanitizeFileName(fileName) + '.docx'
    saveAs(blob, outputName)
    store.dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `${outputName} downloaded`, durationMs: 2000 }))
  } catch (error) {
    console.error('Error fetching html2Docx:', error)
    store.dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'Source download failed', durationMs: 2000 }))
    throw error
  } finally {
    onLoading(false)
  }
}
