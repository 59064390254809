import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { Source } from '@mui/icons-material'
import LrrSourceDialog from '../source-dialogs/lrr/lrr-source-dialog'
import { useState } from 'react'
import { useGetLrrSourcesQuery } from '@/store/apis/hosted-filters-api'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2QueryMetadataLrrV2 } from '@/chat-common/schemas/chat-query-metadata-schema'

type ChatControlsLRRProps = {
  conversationId: string
}

export default function ChatControlsLRR(props: ChatControlsLRRProps) {
  const { conversationId } = props
  const { trackEvent } = useAnalytics()

  // Redux state selectors
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataLrrV2 | null

  // Local state for source window status
  const [sourceWindowOpen, setSourceWindowOpen] = useState<boolean>(false)

  // RTK Query: Fetch the LRR sources
  const { data: lrrSourcesData, isLoading: lrrSourcesLoading, isError: isLrrSourcesError } = useGetLrrSourcesQuery()

  // Inferred values
  const hasSelections = currentSource != null && currentSource.lrr_selections.length > 0

  // Loading and error states
  if (lrrSourcesLoading) return <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
  if (isLrrSourcesError || !lrrSourcesData) return <div className={'text-red-700 text-sm'}>Error loading sources.</div>

  // Infer the selected source label
  // Isolate the top level keys
  const topLevelKeys = Object.keys(lrrSourcesData)

  // Filter top level keys where some of the child sources are checked
  const selectedSourceNameSome = topLevelKeys.filter((key) => {
    const parentObject = lrrSourcesData[key]
    const parentSources = parentObject.sources
    return parentSources.some((source) => currentSource?.lrr_selections.some((selection) => selection.source_name === source.value))
  })

  return (
    <>
      <LrrSourceDialog
        conversationId={conversationId}
        lrrSourcesResponse={lrrSourcesData}
        open={sourceWindowOpen}
        onClose={(value) => setSourceWindowOpen(value)}
      />

      <button
        onClick={() => {
          trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.lrr })
          setSourceWindowOpen(true)
        }}
        className="w-auto rounded-md text-sm px-0 font-semibold text-brand-500 hover:underline"
      >
        Select source <Source fontSize="small" />
      </button>

      <div className={'mt-1'}>
        {!hasSelections && <p className={'text-sm text-red-700 text-left'}>Source is required</p>}
        {selectedSourceNameSome.length > 0 && (
          <p className={'text-sm text-brand-neutral-500'}>
            Source: {selectedSourceNameSome.length > 1 ? `${selectedSourceNameSome[0]}, ${selectedSourceNameSome[1]}` : selectedSourceNameSome[0]}
          </p>
        )}
      </div>
    </>
  )
}
