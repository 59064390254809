import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@/store/store'
import { SidebarItemId } from '@/store/slices/ui-state.slice'

/**
 * Base selector to get the uiState
 */
const selectUIState = (state: RootState) => state.uiState

/**
 * Selector for all Sidebar Items
 */
export const selectSidebarItemsState = createSelector(selectUIState, (uiState) => uiState.sidebarItemsState)

/**
 * Selector for a specific Sidebar Item
 */
export const selectSidebarItemState = (itemId: SidebarItemId) =>
  createSelector(selectSidebarItemsState, (sidebarItems) => sidebarItems.find((item) => item.itemId === itemId))
