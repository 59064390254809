import React from 'react'

interface FileUploadContextType {
  openSystemFileSelector: () => void
}

export const FileUploadContext = React.createContext<FileUploadContextType>({
  openSystemFileSelector: () => {
    // default, in case openSystemFileSelector has not yet been defined
    console.warn('openSystemFileSelector has not been defined')
  },
})
