import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import CitatorCasesQueryView from './citator_tab_views/CitatorCasesQueryView'
import { getOrEnqueueCaseStatus } from '../repository/get-case-status'
import CitatorImportantCases from './citator_tab_views/CitatorImportantCases'
import { useGetCitatorCaseCitationsQuery, useGetCitatorCitingCasesQuery, useGetCitatorRelatedCasesQuery } from '../repository/citiator.api'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { getHelpCenterUrl, getBrandName } from '@/util/enterprise'

export type CaseLawReferenceCitatorTabProps = {
  parent_id: string
}

export default function CaseLawReferenceCitatorTab(props: CaseLawReferenceCitatorTabProps) {
  const { parent_id } = props

  // Local state
  const [query, setQuery] = useState('')

  // case_status state
  const citatorState = useAppSelector((state: RootState) => state.citatorState)
  const caseStatusState = citatorState.caseStatuses[parent_id]
  const caseStatusStateLoading = caseStatusState?.loading
  const caseStatusStateError = caseStatusState?.error
  const caseStatusStateProcessing = caseStatusState?.jobProcessing
  const caseStatus = caseStatusState?.data?.status

  // Build Help Center URL
  const helpCenterUrl = getHelpCenterUrl('legal-research/ai-citator')
  const brandName = getBrandName(true)

  // CitatorTab Initialization - Auto Run
  // If the case_status has not been run, run it
  // Do not auto-run if error (requires manual click)
  useEffect(() => {
    if (!caseStatusStateLoading && !caseStatus && !caseStatusStateError && !caseStatusStateProcessing) {
      console.log('Running citator case_status for parent_id: ', parent_id)
      getOrEnqueueCaseStatus(parent_id)
    }
  }, [caseStatusStateLoading, caseStatusStateError, caseStatusStateProcessing, caseStatus, parent_id])

  //==============================================================================
  // RTK-QUERY DATA FETCHING
  // Case Citations
  const {
    data: caseCitationsData,
    isLoading: caseCitationsIsLoading,
    isFetching: caseCitationsIsFetching,
    refetch: caseCitationsRefetch,
    isError: caseCitationsIsError,
  } = useGetCitatorCaseCitationsQuery(parent_id)

  // Citing Cases
  const {
    data: citingCasesData,
    isLoading: citingCasesIsLoading,
    isFetching: citingCasesIsFetching,
    refetch: citingCasesRefetch,
    isError: citingCasesIsError,
  } = useGetCitatorCitingCasesQuery(parent_id)

  // Related Cases
  const {
    data: relatedCasesData,
    isLoading: relatedCasesIsLoading,
    isFetching: relatedCasesIsFetching,
    refetch: relatedCasesRefetch,
    isError: relatedCasesIsError,
  } = useGetCitatorRelatedCasesQuery(parent_id)

  return (
    <div className={'flex flex-col gap-y-3 pb-2'}>
      <Link to={helpCenterUrl} target="_blank" className={'flex items-end hover:text-brand-700 active:bg-brand-100 pt-1'}>
        <span className={'inline text-sm font-bold text-brand-700 hover:text-brand-500 underline leading-tight'}>
          <QuestionMarkCircleIcon className={'inline w-5 h-5 pl-[2px] mr-1 pb-[0px]'} />
          {brandName} Citator Help Center
          <ExternalLinkIcon className={'inline w-4 h-4 pl-[4px] pb-[0px]'} />
        </span>
      </Link>
      <input
        className="w-full mb-1 rounded-md border-0 bg-brand-neutral-100 px-3 py-2 text-brand-neutral-900 focus:ring-0 sm:text-sm"
        id={'drill-down-search'}
        name={'drill-down-search'}
        placeholder="Filter by name..."
        type="text"
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />
      <CitatorImportantCases caseStatusState={caseStatusState} parentId={parent_id} filterQuery={query} />
      <hr />
      <CitatorCasesQueryView
        title={'Case Citations'}
        isLoading={caseCitationsIsLoading || caseCitationsIsFetching}
        isError={caseCitationsIsError}
        refetch={caseCitationsRefetch}
        data={caseCitationsData}
        filterQuery={query}
      />
      <hr />
      <CitatorCasesQueryView
        title={'Citing Cases'}
        isLoading={citingCasesIsLoading || citingCasesIsFetching}
        isError={citingCasesIsError}
        refetch={citingCasesRefetch}
        data={citingCasesData}
        filterQuery={query}
      />
      <hr />
      <CitatorCasesQueryView
        title={'Related Cases'}
        isLoading={relatedCasesIsLoading || relatedCasesIsFetching}
        isError={relatedCasesIsError}
        refetch={relatedCasesRefetch}
        data={relatedCasesData}
        filterQuery={query}
      />
    </div>
  )
}
