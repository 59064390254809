import { FilesDropZoneRef } from '@/routes/dashboard/files/FilesDropZoneWithTaskState'
import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { StorageReference, getStorage } from 'firebase/storage'
import { ref as firebaseRef } from 'firebase/storage'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getAuth } from 'firebase/auth'
import { getStorageFolderNameByFeature } from './chat-window-dropzone-utils'
import FilesDropZoneWithTaskState from '@/routes/dashboard/files/FilesDropZoneWithTaskState'
import { chatV2CurrentSourceAssistantAddFile, ChatV2Feature } from '../store/chat-v2.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { pollForFileStatusUntilSuccess } from '../store/chat-v2-ux-poll'
import { FileUploadStatusDialogInChat } from '@/routes/dashboard/files/FileUploadStatusDialogInChat'
import { FileProcessingStatus, setFileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { setSelectedFilesContentExpanded } from '../store/chat-v2-ux.slice'
import { FileOverwriteModalProvider } from '@/routes/dashboard/files/file-overwrite-confirmation/file-overwrite-modal-context'

type ChatWindowDropZoneMultifileProps = {
  hideDropZoneUI?: boolean
  chatId: string
  chatFeature: ChatV2Feature
}

export interface ChatWindowDropZoneMultifileRef {
  openSystemFileSelector: () => void
}

const ChatWindowDropZoneMultifile = forwardRef<ChatWindowDropZoneMultifileRef, ChatWindowDropZoneMultifileProps>(function ChatWindowDropZoneMultifile(
  props,
  ref
) {
  const { hideDropZoneUI = false, chatId: conversationId, chatFeature } = props

  // Get the current user
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  const dispatch = useAppDispatch()

  // Derive the currentFolder for uploads
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const uploadFolderPath = `users/${uid}/${getStorageFolderNameByFeature(chatFeature as ChatV2Feature)}`
  const currentFolderRef: StorageReference | null = firebaseRef(storage, uploadFolderPath)

  // Local state
  const [statusDialogOpen, setStatusDialogOpen] = useState<boolean>(false)

  // Expose method for opening the computer file selector from FilesDropZone
  const filesDropZoneRef = useRef<FilesDropZoneRef | null>(null)

  useImperativeHandle(ref, () => ({
    openSystemFileSelector: () => {
      filesDropZoneRef.current?.openSystemFileSelector()
    },
  }))

  const handleTaskListItemUpdated = () => {
    setStatusDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setStatusDialogOpen(false)
  }

  return (
    <div className="px-4">
      <FileOverwriteModalProvider>
        <FilesDropZoneWithTaskState
          ref={filesDropZoneRef}
          onTaskListItemUpdated={handleTaskListItemUpdated}
          currentFolder={currentFolderRef}
          singleFileLimit={false}
          hideDropZoneUI={hideDropZoneUI}
          chatId={conversationId}
          gcsConfirmOverwrite={true}
          // When the user indicates they want to use an existing file
          onUseExistingFileOption={(item) => {
            const sourcePath = item.sourcePath

            // Set as selected file for this chat
            dispatch(chatV2CurrentSourceAssistantAddFile({ conversationId, filePath: sourcePath }))

            // Expand the selected files content
            dispatch(setSelectedFilesContentExpanded({ conversationId, expanded: true }))
          }}
          // When it is a new file or the user indicates they want to overwrite the existing file
          onNewTaskCreated={(item) => {
            const sourcePath = item.sourcePath
            // Add to processing state as pending
            dispatch(setFileProcessingStatus({ sourcePath, status: FileProcessingStatus.PENDING }))

            // Set as selected file for this chat
            dispatch(chatV2CurrentSourceAssistantAddFile({ conversationId, filePath: sourcePath }))

            // Expand the selected files content
            dispatch(setSelectedFilesContentExpanded({ conversationId, expanded: true }))
          }}
          onTaskListItemCompleted={(item) => {
            // Start the polling task for this file
            pollForFileStatusUntilSuccess(item.sourcePath)
          }}
        />
      </FileOverwriteModalProvider>
      <FileUploadStatusDialogInChat open={statusDialogOpen} onClose={handleCloseDialog} chatId={conversationId} />
    </div>
  )
})

export default ChatWindowDropZoneMultifile
