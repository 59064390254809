import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { useContext, useState } from 'react'
import { AuthContext } from '@/context/auth-context'
import { UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZone'
import { StorageReference, getStorage, ref } from 'firebase/storage'
import { kGcsOrgHiddenFolder, kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { CircularProgressDeterminate } from '@/components/loaders/CircularProgressDeterminate'
import ChangeLogoDropZone from './ChangeLogoDropZone'
import uploadFileHandler, { UploadableFile } from '@/routes/dashboard/files/upload-file-handler'
import { nanoid } from 'nanoid'
import { kOrganizationLogoFileName } from '@/constants/constants-ui'

type ChangeLogoDialogProps = {
  visible: boolean
  onComplete: () => void
  onClose: () => void
}

/**
 * The ChangeLogoDialog handles uploading and saving of a logo for the organization.
 */
export default function ChangeLogoDialog(props: ChangeLogoDialogProps) {
  const { visible, onComplete, onClose } = props

  // Local state
  const [error, setError] = useState<string | null>(null)
  const [imageBlob, setImageBlob] = useState<Blob | null>(null)
  const [uploadTask, setUploadTask] = useState<UploadTaskStatus | null>(null)

  // User Context
  const { userAccountData } = useContext(AuthContext)
  const orgId = userAccountData?.legacyDriveCompatibleOrganizationId

  // Derive the currentFolder for uploads
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const uploadFolderPath = `tenants/${orgId}/${kGcsOrgHiddenFolder}` // hidden folder for org that is filtered from file managers / lists
  const currentFolderRef: StorageReference | null = ref(storage, uploadFolderPath)

  // Handle submit
  async function handleSave() {
    if (!imageBlob) {
      setError('No image selected.')
      return
    }

    // Create a unique persistent id for the file
    const fileId = nanoid(22)

    // Create an Uploadable File
    const uploadableFile: UploadableFile = {
      file: imageBlob,
      fileName: kOrganizationLogoFileName,
      id: fileId,
    }

    // Initialize the task and add it to state
    const newUploadTask: UploadTaskStatus = {
      progress: 0,
      complete: false,
      errorMessage: '',
      uploadableFile: uploadableFile,
    }

    // Set the upload task in state
    setUploadTask(newUploadTask)

    // Create a new upload task for this file and pass event handlers to update state based on upload progress and status
    uploadFileHandler({
      uploadableFile,
      currentFolder: currentFolderRef,
      onProgress: function (progress: number): void {
        setUploadTask((prevTask) => {
          if (!prevTask) throw new Error('did you forget to initialize the logo upload task?')

          // Update the task
          const updatedTask: UploadTaskStatus = {
            ...prevTask,
            progress: progress,
            complete: false,
            errorMessage: '',
          }

          return updatedTask
        })
      },
      onError: function (error: Error): void {
        setUploadTask((prevTask) => {
          if (!prevTask) throw new Error('did you forget to initialize the logo upload task? ', error)

          const updatedTask: UploadTaskStatus = {
            ...prevTask,
            errorMessage: 'Error uploading file',
          }
          return updatedTask
        })
      },
      onComplete: function (): void {
        // Set it as completed
        setUploadTask((prevTask) => {
          if (!prevTask) throw new Error('did you forget to initialize the logo upload task?')

          const updatedTask: UploadTaskStatus = {
            ...prevTask,
            complete: true,
          }

          return updatedTask
        })

        // Call onComplete callback
        onComplete()
      },
    })
  }

  // Handle close
  function handleClose() {
    setError(null)
    setImageBlob(null)
    setUploadTask(null)
    onClose()
  }

  // Do not show if not visible
  if (!visible) return null

  // Create URL for the selected file
  const blogImageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-2xl sm:min-w-[600px]">
        <ChangeLogoDropZone
          onImageBlobPrepared={setImageBlob}
          onTaskListItemUpdated={function (uploadTask: UploadTaskStatus): void {
            setUploadTask(uploadTask)

            // When the upload is completed, set it as the chat's currentSource selected file
            if (uploadTask.complete) {
              console.log('File upload completed: organization logo')
            }
          }}
          currentFolder={currentFolderRef}
          singleFileLimit={true}
        />

        <div className={'text-xs text-brand-neutral-500 text-center py-4'}>Your logo will be resized to fit within a 200x125 area.</div>

        {blogImageUrl && (
          <div className={'mb-5 m-auto flex items-center justify-center'}>
            <img className={'max-w-[200px] max-h-[125px]'} src={blogImageUrl} />
          </div>
        )}

        {uploadTask && (
          <div className={'my-2 grid grid-cols-[auto_30px] items-center bg-brand-neutral-50 border rounded-xl p-2'}>
            <div className={'inline-block align-middle text-sm overflow-hidden'}>
              <p>Upload Progress</p>
              <p className={'text-red-600 text-xs'}>{uploadTask.errorMessage.length == 0 ? '' : uploadTask.errorMessage}</p>
            </div>
            <div className={'inline-block align-middle'}>
              <CircularProgressDeterminate value={uploadTask.progress} error={uploadTask.errorMessage.length > 0 ? true : false} />
            </div>
          </div>
        )}

        {error && <div className={'my-2 text-left text-red-700 text-sm'}>{error}</div>}
        <div className={'flex gap-x-4 justify-center items-center pt-5'}>
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
            onClick={handleClose}
            disabled={false}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={handleSave}
          >
            <div className={'flex items-center gap-x-1'}>
              <div>Save</div>
              <ButtonLoader loading={false} />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
