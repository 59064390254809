import React, { createContext, useState } from 'react'
import FileOverwriteModal from './file-overwrite-modal'

export enum FileUploadOverwriteConfirmationOptions {
  OVERWRITE = 'OVERWRITE',
  USE_EXISTING = 'USE_EXISTING',
  CANCEL = 'CANCEL',
}

type FileOverwriteModalContextType = {
  showFileOverwriteConfirmation: (filePaths: string[]) => Promise<FileUploadOverwriteConfirmationOptions>
}

export const FileOverwriteModalContext = createContext<FileOverwriteModalContextType | undefined>(undefined)

export const FileOverwriteModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filePaths, setFilePaths] = useState<string[]>([])
  const [resolveReject, setResolveReject] = useState<{
    resolve: (value: FileUploadOverwriteConfirmationOptions) => void
    reject: (reason?: any) => void
  } | null>(null)

  const showFileOverwriteConfirmation = (filePaths: string[]) => {
    return new Promise<FileUploadOverwriteConfirmationOptions>((resolve, reject) => {
      setFilePaths(filePaths)
      setResolveReject({ resolve, reject })
      setIsOpen(true)
    })
  }

  const handleResponse = (response: FileUploadOverwriteConfirmationOptions) => {
    if (resolveReject) {
      resolveReject.resolve(response)
      cleanup()
    }
  }

  const cleanup = () => {
    setIsOpen(false)
    setFilePaths([])
    setResolveReject(null)
  }

  return (
    <FileOverwriteModalContext.Provider value={{ showFileOverwriteConfirmation }}>
      {children}
      {isOpen && (
        <FileOverwriteModal
          filePaths={filePaths}
          onOverwrite={() => handleResponse(FileUploadOverwriteConfirmationOptions.OVERWRITE)}
          onUseExisting={() => handleResponse(FileUploadOverwriteConfirmationOptions.USE_EXISTING)}
          onCancel={() => handleResponse(FileUploadOverwriteConfirmationOptions.CANCEL)}
        />
      )}
    </FileOverwriteModalContext.Provider>
  )
}
