import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { Folder } from '@mui/icons-material'
import { useState } from 'react'

import { Cross2Icon, TrashIcon } from '@radix-ui/react-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { useAppDispatch } from '@/store/store-hooks'
import { useGetAllFilesQuery } from '../api/files.api'
import CreateFolderDialog from '../../files/create-folder-components/CreateFolderDialog'
import DeleteConfirmDialog from '../../files/dialogs/files-delete-confirm'
import FileDeleteConfirmDialog from '../../files/dialogs/single-file-delete-confirm'
import { downloadFile } from '../../files/files-utils'
import FilesActionsMenu from '../../files/FilesActionsMenu'
import { FileNode, FolderNode, NodeType } from '../types'
import RenameItemDialog from '../../files/dialogs/rename-file'
import { DynamicStatusIconFromFile } from '@/chat-common/components/source-dialogs/utils/file-status'

export default function FileDriveList() {
  // *******************************************
  // Get Files And Folders
  const { data, isLoading } = useGetAllFilesQuery()

  //local state
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [activeItem, setActiveItem] = useState<FileNode | FolderNode | null>(null)

  // Dialogs
  const [openRenameFileDialog, setOpenRenameFileDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showSingleDeleteDialog, setShowSingleDeleteDialog] = useState(false)
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false)

  // TODO: Implement location for navigation to folders
  const location = useLocation()
  const navigate = useNavigate()

  const baseDriveLocationPath = location.pathname.split('/').slice(0, 4).join('/')

  function storageRefPathForUri(storageRef: string): string {
    const storagePath = storageRef.split('/').slice(2).join('/')
    return encodeURIComponent(storagePath)
  }


  const dispatch = useAppDispatch()

  if (isLoading) return <CircularProgressContinuousSized size={30} thickness={5} />

  const folders = data && !isLoading ? data.filter((item) => item.type === NodeType.FOLDER) : ([] as FolderNode[])
  const files = data && !isLoading ? data.filter((item) => item.type === NodeType.FILE) : ([] as FileNode[])

  // const isRootFolder = location.pathname == '/dashboard/files'

  // Check if a file is selected
  function isFileSelected(fileId: string) {
    return selectedItems.includes(fileId)
  }

  // *******************************************
  // Handle checkbox change for files
  function handleFileCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target

    // Add or remove the file from the selected files array
    if (checked) {
      setSelectedItems([...selectedItems, value])
    } else {
      setSelectedItems(selectedItems.filter((file) => file != value))
    }
  }

  //allFilesSelected ? clearSelectedItems() : selectAllFiles()
  function clearAllFilesAndFolders() {
    clearSelectedItems()
  }

  function selectAllFilesAndFolders() {
    selectAllFiles()
  }

  // Clear selected files
  function clearSelectedItems() {
    setSelectedItems([])
  }

  // Select all files
  function selectAllFiles() {
    const allFiles = files ? files.map((file) => file.id) : []
    const allFolders = folders ? folders.map((folder) => folder.id) : []
    setSelectedItems([...allFiles, ...allFolders])
  }

  // Function to rename a file
  async function handleRename(newName: string): Promise<void> {
    if (!activeItem) {
      console.error('No active item selected to rename')
      return
    }

    try {

      //Keep this logic to handle file extension

      //Check if input value contains extension
      // const hasExtension = /\.\w+$/.test(newName)
      // const originalExtension = activeItem.sourceLocation.split('.')[1]
      // if (originalExtension.length <= 1) throw new Error() //Error out in case we can't find a original file extension

      // const newNameWithExtension = hasExtension ? newName : newName + `.${originalExtension}`

      // const newPath = activeItem.fullPath.replace(/[^/]+$/, newNameWithExtension)
      console.log('renaming file:', activeItem, newName, 'not implemented yet')

      setOpenRenameFileDialog(false)
    } catch (error) {
      setOpenRenameFileDialog(false)
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: `Unable to rename the file ${activeItem.name}`, durationMs: 2000 }))
      console.error('Error renaming file:', error)
    }
  }

  // Handle Delete Confirmation
  async function handleDeleteFile() {
    if (!activeItem) {
      console.error('No active item selected to delete')
      return
    }

    console.log('deleting file:', activeItem, 'not implemented yet')
  }

  async function handleDeleteConfirm() {
    console.log('Deleting files: ', selectedItems, 'and folders not implemented yet')
  }

  async function handleFileDownload(file: FileNode) {
    try {
      await downloadFile(file.sourceLocation, file.name)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: file.name + ' downloaded', durationMs: 2000 }))
    } catch (e) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: file.name + ' download failed', durationMs: 2000 }))
      throw e
    }
  }

  function formatDate(date: string) {
    const dateObj: Date = new Date(date)
    return Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(dateObj)
  }

  function formatSize(size: number) {
    if (size < 1000000) return `${Math.round(size / 1000)} KB`
    return `${(size / 1000000).toFixed(1)} MB`

  }

  if (files && folders) {
    if (files.length == 0 && folders.length == 0 && isLoading) return
  }

  if (isLoading) return <CircularProgressContinuousSized size={30} thickness={5} />

  const fileFiles = selectedItems.length === 1 ? 'file' : 'files'
  const allFilesAndFoldersSelected = selectedItems.length === files.length + folders.length
  const toolbarDisabled = selectedItems.length == 0

  return (
    <div>
      {/* Actions Dialog */}
      <DeleteConfirmDialog
        title={selectedItems.length > 1 ? 'Delete Files Permanently?' : 'Delete File Permanently?'}
        fileMessage={selectedItems.length > 0 ? `${selectedItems.length} ${fileFiles} will be deleted forever.` : null}
        //TODO we need to improve the message to include folders
        folderMessage={''}
        onConfirm={handleDeleteConfirm}
        closeDialog={() => setShowDeleteDialog(false)}
        visible={showDeleteDialog}
      />
      <FileDeleteConfirmDialog
        fileName={activeItem?.name}
        onConfirm={handleDeleteFile}
        closeDialog={() => setShowSingleDeleteDialog(false)}
        visible={showSingleDeleteDialog}
      />
      <RenameItemDialog open={openRenameFileDialog} onSave={(data) => handleRename(data.itemName)} onClose={() => setOpenRenameFileDialog(false)} />
      <CreateFolderDialog
        open={openCreateFolderDialog}
        onSave={async (data) => {
          const { folderName } = data
          console.log('Creating folder:', folderName, 'not implemented yet')
        }}
        onClose={() => setOpenCreateFolderDialog(false)}
      />

      {/* Toolbar */}
      <div className={`px-2 mb-1 flex gap-x-2 ${!toolbarDisabled ? 'opacity-100' : 'opacity-10'} transition-all duration-200 ease-in-out`}>
        <button
          onClick={clearAllFilesAndFolders}
          disabled={toolbarDisabled}
          className={`flex gap-x-1 px-2 py-1 pr-3 items-center rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${!toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
            }`}
        >
          <Cross2Icon width={20} height={20} />
          <div className={'text-sm'}>{selectedItems.length} selected</div>
        </button>

        <button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          disabled={toolbarDisabled}
          className={`flex gap-x-2 px-2 py-1 pr-3 rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${!toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
            }`}
        >
          <TrashIcon width={20} height={20} />
          <div className={'text-sm'}>Delete</div>
        </button>
      </div>

      {/* Files table header */}
      <div
        className={'p-2 grid grid-cols-[19px_100%_0px_0px] sm:grid-cols-[19px_auto_166px_0px] md:grid-cols-[19px_auto_175px_82px] items-end overflow-hidden'}
      >
        <div>
          <input
            type="checkbox"
            checked={allFilesAndFoldersSelected}
            className={`w-4 h-4 ${allFilesAndFoldersSelected ? 'opacity-100' : 'opacity-30'} hover:opacity-100 transition-all ease-in-out duration-200`}
            onChange={() => (allFilesAndFoldersSelected ? clearAllFilesAndFolders() : selectAllFilesAndFolders())}
            value={'test'}
          />
        </div>
        <p className={'text-sm px-2'}>Name</p>
        <p className={'text-sm px-2 hidden sm:block'}>Last modified</p>
        <p className={'text-sm px-2 hidden md:block'}>File size</p>
      </div>

      <div>
        {/* List Folders */}
        {folders.map((folder) => {
          return (
            <div
              key={folder.name}
              className={
                'border-t-[1px] py-2 grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden cursor-pointer hover:bg-brand-100'
              }
            >
              <div className={'px-2 grid grid-cols-[22px_32px_auto] items-center'}>
                <input
                  type="checkbox"
                  checked={isFileSelected(folder.id)}
                  className={`w-4 h-4 ${isFileSelected(folder.id) ? 'opacity-100' : 'opacity-30'} group-hover:opacity-100 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm`}
                  onChange={handleFileCheckboxChange}
                  value={folder.id}
                />
                <Folder
                  onClick={() => {
                    navigate(`${baseDriveLocationPath}/${storageRefPathForUri(folder.folderPath)}`)
                    clearAllFilesAndFolders()
                  }}
                />
                <p
                  onClick={() => {
                    navigate(`${baseDriveLocationPath}/${storageRefPathForUri(folder.folderPath)}`)
                    clearAllFilesAndFolders()
                  }}
                  className={'text-sm overflow-hidden'}
                >
                  {folder.name}
                </p>
              </div>
              <p className={'text-sm px-2 hidden sm:block'}></p>
              <p className={'text-sm px-2 hidden md:block'}></p>
            </div>
          )
        })}

        {/* List Files */}
        {files.map((file) => {
          if (file.type === NodeType.FILE) {

            const { status, id, name, error, size, createdAt } = file

            // Select Icon
            return (
              <div
                key={id}
                className={
                  'group border-t-[1px] py-[6px] grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden  hover:bg-brand-100'
                }
              >
                <div className={'px-2 grid grid-cols-[22px_30px_auto] items-center'}>
                  {/* Microsoft one drive style file selection */}
                  <input
                    type="checkbox"
                    checked={isFileSelected(id)}
                    className={`w-4 h-4 ${isFileSelected(id) ? 'opacity-100' : 'opacity-30'} group-hover:opacity-100 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm`}
                    onChange={handleFileCheckboxChange}
                    value={id}
                  />
                  <div>{DynamicStatusIconFromFile(status)}</div>
                  <div className={'text-sm overflow-hidden flex items-center'}>
                    <p className={'flex-grow'}>
                      {name}
                      {error ? <span className={'text-red-700 ml-1'}>Error Processing File</span> : ''}
                    </p>
                    <div>
                      <FilesActionsMenu
                        onSelect={() => setActiveItem(file)}
                        onRenameClick={() => setOpenRenameFileDialog(true)}
                        onDownloadClick={() => {
                          if (status === 'complete') {
                            handleFileDownload(file)
                          }
                        }}
                        onDeleteClick={() => {
                          setActiveItem(file)
                          setShowSingleDeleteDialog(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className={'text-sm px-2 hidden sm:block'}>{formatDate(createdAt)}</p>
                <p className={'text-sm px-2 hidden md:block'}>{formatSize(size)}</p>
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}
