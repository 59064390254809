import { ChatV2MessageReferenceType } from '../store/chat-v2.slice'

export default function referenceMetadataToTitleSuffix(reference: ChatV2MessageReferenceType, isBBCitation = false): string {
  // Format the source suffix based on the metadata
  let suffix = ''

  // CASELAW V2 TITLE SUFFIX
  if (typeof reference.metadata?.parent_id === 'string') {
    // If isBBCitation, a title was already generated so only build the suffix
    if (isBBCitation) {
      const citation = reference.metadata.citation ?? ''
      const citationSuffix = reference.metadata.citation_suffix ?? ''
      const details = `, ${citation} ${citationSuffix}`

      suffix += `${details}`
    }
  }
  // Trim beginning and ending whitespace
  suffix = suffix.trim()

  return suffix
}
