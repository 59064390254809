import { useEffect, useState } from 'react'

/**
 * Annotation Toggle Component
 * @param props is designed to receive {children: string} elements from the <Markdown> component 'components' property.
 * @returns a JSX element that toggles the visibility of an annotation area.
 */
export default function AnnotationToggleButton(props: any) {
  const { children } = props

  // Active State
  const [active, setActive] = useState(false)

  // Footnote detecting regex
  const footnoteRegex = /\[\d+\]/g

  // Extract the footnote number
  const footnoteNumber = children.match(footnoteRegex)?.[0]?.slice(1, -1)

  // Annotation Selector Interactivity Event Listeners
  // Effect to add the click event listener to highlighted problem
  // ONLY MAKE VISIBLE - won't hide
  useEffect(() => {
    const elements = document.getElementsByClassName(`annotation-selector-${footnoteNumberInt}`)
    if (elements.length == 0) return

    // Function to add the glow class
    const addGlow = () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add('glow')
      }
    }

    // Function to remove the glow class
    const removeGlow = () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('glow')
      }
    }

    // Add event listener to all elements
    for (let i = 0; i < elements.length; i++) {
      const inlineHighlightedProblemText = elements[i] as HTMLElement

      inlineHighlightedProblemText.addEventListener('click', () => {
        const annotationArea = document.getElementById(`annotation-area-${footnoteNumberInt}`)
        if (!annotationArea) return
        annotationArea.classList.remove('hidden')

        // Set active state
        setActive(true)
      })

      // Add hover event listeners
      inlineHighlightedProblemText.addEventListener('mouseenter', addGlow)
      inlineHighlightedProblemText.addEventListener('mouseleave', removeGlow)
    }

    // Remove hidden class from annotation block
    return () => {
      for (let i = 0; i < elements.length; i++) {
        const inlineHighlightedProblemText = elements[i] as HTMLElement

        inlineHighlightedProblemText.removeEventListener('click', onClick)
        inlineHighlightedProblemText.removeEventListener('mouseenter', addGlow)
        inlineHighlightedProblemText.removeEventListener('mouseleave', removeGlow)
      }
    }
  })

  // Effect to add click event listener to annotation close button
  // ONLY MAKE HIDDEN - won't show
  useEffect(() => {
    const closeAnnotation = document.getElementById(`close-annotation-${footnoteNumberInt}`)
    if (!closeAnnotation) return

    closeAnnotation.addEventListener('click', () => {
      const annotationArea = document.getElementById(`annotation-area-${footnoteNumberInt}`)
      if (!annotationArea) return
      annotationArea.classList.add('hidden')

      // Set active state
      setActive(false)
    })
  })

  // If no footnote number, return nothing
  if (!footnoteNumber) return null

  // Convert to an int
  const footnoteNumberInt = parseInt(footnoteNumber)

  // Onclick handler to show / hide the annotation
  const onClick = () => {
    const annotationArea = document.getElementById(`annotation-area-${footnoteNumberInt}`)
    if (!annotationArea) return
    annotationArea.classList.toggle('hidden')

    // Toggle the active state
    setActive(!active)
  }

  // Display user friendly indexes starting at 1
  return (
    <button onClick={onClick} className={`inline font-bold text-brand-700 text-sm px-1 py-[1px] rounded-md ${active ? 'bg-brand-400 bg-opacity-20' : ''}`}>
      [ {footnoteNumberInt + 1} ]
    </button>
  )
}
