import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { setRetitleConversationVisible } from '@/store/slices/ui-state.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState, useEffect, useRef } from 'react'
import patchConversationTitle from '@/chat-common/fetch/patch-conversation-title'
import { selectConversationFeature } from '../store/chat-v2.selectors'
import { useParams } from 'react-router-dom'
import { ChatV2Feature } from '../store/chat-v2.slice'
import loadMissingConversationIntoState from '../fetch/fetch-missing-conversation'

type RetitleConversationProps = {
  refetchChatList: () => void
}

const RetitleConversationDialog: React.FC<RetitleConversationProps> = ({ refetchChatList }) => {
  const { feature } = useParams()

  // Store - whether dialog is visible
  const dispatch = useAppDispatch()
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const visible = uiState.retitleConversationDialogVisible
  const conversationId = uiState.retitleConversationId

  // Get conversation feature (needed for refresh function)
  const conversationFeature =
    useAppSelector((state: RootState) =>
      selectConversationFeature(state, {
        chatId: conversationId ?? '',
      })
    ) ?? (feature as ChatV2Feature)

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [newTitle, setNewTitle] = useState<string>('')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (visible && inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100) // Immediate focus doesn't work, delaying 100 millis
    }
    setNewTitle('') // Clear the input field
  }, [visible]) // Depend on 'visible' to re-apply focus when it becomes visible again

  const closeDialog = () => {
    dispatch(setRetitleConversationVisible({ visible: false, conversationId: conversationId }))
  }

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeDialog()
    }
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleEscKey)
    }
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [visible])

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (newTitle.trim()) {
      requestConversationTitleChange()
    }
  }

  async function requestConversationTitleChange() {
    try {
      setError(null)
      setLoading(true)
      if (!conversationId) throw new Error('Conversation ID not found')
      // Send request to change conversation title
      await patchConversationTitle({ conversationId, newTitle })

      // FIRST: referch the conversation to update the conversation state title
      // Make it available in-state
      await loadMissingConversationIntoState(
        conversationId,
        conversationFeature,
        () => null,
        () => null,
        true
      )

      // Refetch chat list to update the sidebar items
      await refetchChatList()

      // timeout for 300 milliseconds
      setTimeout(() => {
        closeDialog()
      }, 300)
    } catch (error) {
      console.error(error)
      setError('There was an error changing the conversation title. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeDialog}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-4 rounded-md shadow-lg relative z-[1000] w-full max-w-sm sm:max-w-md">
        <h2 className="mb-1 text-lg font-semibold">Rename Chat</h2>

        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            ref={inputRef}
            className="w-full p-2 mb-4 border border-brand-neutral-300 rounded-md"
            placeholder="Enter new title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <div className={'flex gap-x-4 justify-end items-center'}>
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
              onClick={closeDialog}
            >
              Cancel
            </button>

            {/* TODO: Disabled styled loading button */}
            {loading && <CircularProgressContinuousSized size={20} thickness={7} />}

            {/* Loading complete */}
            {!loading && (
              <button
                type="submit"
                className={`inline-flex justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm ${
                  !newTitle.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-brand-400'
                }`}
                disabled={loading || !newTitle.trim()}
              >
                Submit
              </button>
            )}
          </div>
        </form>

        {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
      </div>
    </div>
  )
}

export default RetitleConversationDialog
