import { Docx2HtmlComment, Docx2HtmlCommentTree, Docx2HtmlCommentType } from '../schema/docx2html.schema'

// Extract comments from the HTML string
// Comments may be nested.
// - The comment-start tags may be siblings or scattered.
// - The comment-end tags reveal the nesting structure of comments and their replies.
//
// EXAMPLE nested commend-end HTML:
//  <p>
//    <span class="comment-start" id="1">Comment content</span>
//    <span class="comment-start" id="2">Comment content</span>
//    Normal text
//  </p>
//  <p>
//    <span class="comment-end" id="3">Comment content</span>
//    Normal text
//    <span class="comment-end" id="4">Comment content</span>
//    <span class="comment-end" id="1">
//      <span class="comment-end" id="2">
//        <span class="comment-start" id="4"></span>
//      </span>
//      <span class="comment-end" id="3"></span>
//    </span>
//  </p>

//

/**
 * Extract Comments
 * @param htmlInput is the same HTML string that is rendered for document display
 * @returns an array of comment arrays. Each comment array represents a top-level comment and its children.
 */
export function docx2HtmlExtractComments(htmlInput: string): Docx2HtmlCommentTree {
  // Initialize comment tree
  const commentTree: Docx2HtmlCommentTree = {}

  // Parse the HTML string
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlInput, 'text/html')
  // console.log('Analyzing doc: ', doc)

  // Get all comment-start elements
  const commentStartElements = doc.getElementsByClassName('comment-start')
  // console.log('Start elements: ', commentStartElements)

  // Get all commentEnd elements
  const commentEndElements = doc.getElementsByClassName('comment-end')

  // Validate all elements have an id
  const valid = Array.from(commentStartElements).every((el) => el.id !== '') && Array.from(commentEndElements).every((el) => el.id !== '')
  if (!valid) {
    throw new Error('Comment without ID found. All comment-start and comment-end elements must have an id attribute.')
  }

  // Identify all top-level commend-end elements (filter to exclude elements with a parent comment-end element)
  const topLevelCommentEndElements = Array.from(commentEndElements).filter((el) => !el.parentElement?.classList.contains('comment-end'))
  // console.log('Top level comment end elements: ', topLevelCommentEndElements)

  // Sort the keys by the order they appear in the comment-start elements
  // This is necessary because the comment-end elements are not guaranteed to be in order
  // Sort parameter is added to the comment tree because Map key order is not guaranteed
  // Methodology:
  // 1. Get the keys of the comment-start elements
  // 2. Filter out any keys that are not in the topLevelCommentEndElements
  const orderedTopLevelKeys = Array.from(commentStartElements)
    .map((el) => el.id)
    .filter((id) => topLevelCommentEndElements.some((el) => el.id === id))

  // Build the nested comment structure for each top-level comment
  topLevelCommentEndElements.forEach((topLevelComment) => {
    const topLevelCommentId = topLevelComment.id
    const paxtonComment: boolean = ["paxton", "haiku"].includes(topLevelComment.getAttribute('data-author')?.toLowerCase() || '');

    // Create a new comment group for the top-level comment
    commentTree[topLevelCommentId] = {
      sortOrder: orderedTopLevelKeys.indexOf(topLevelCommentId),
      paxtonComment: paxtonComment,
      commentType: determineCommentType(topLevelCommentId, doc),
      comments: buildCommentGroup(topLevelComment, commentStartElements),
    }
  })

  return commentTree
}

/**
 * Determine Comment Type
 * Determine the type of comment based on the presence of insertions and deletions between the start and end of the comment.
 * @param commentId is the ID of the comment
 * @param htmlDoc is the parsed HTML document where all insertions and deletions have been marked up with part-of-comment-id-${commentId} class
 * @returns {Docx2HtmlCommentType} based on the comment start and end elements
 */
function determineCommentType(commentId: string, htmlDoc: Document): Docx2HtmlCommentType {
  // Get all elements tagged with the part-of-comment-id-${commentId}
  const commentElements = htmlDoc.getElementsByClassName(`part-of-comment-id-${commentId}`)
  // console.log(`Tagged elements for part-of-comment-id-${commentId}: `, commentElements)

  // Check for insertion, deletion
  const containsInsertion = Array.from(commentElements).some((el) => el.classList.contains('insertion'))
  const containsDeletion = Array.from(commentElements).some((el) => el.classList.contains('deletion'))
  // console.log(`Contains insertion: ${containsInsertion}, Contains deletion: ${containsDeletion}`)

  // Return the comment type
  switch (true) {
    case containsInsertion && containsDeletion:
      return Docx2HtmlCommentType.replacement
    case containsInsertion:
      return Docx2HtmlCommentType.insertion
    case containsDeletion:
      return Docx2HtmlCommentType.deletion
    default:
      return Docx2HtmlCommentType.no_change
  }
}

/**
 * Build Comment Group
 * Build a flat list of comments grouped by top-level comment.
 * @param topLevelComment is a top level comment that we are grouping with it's children for rendering as a flat list of comments
 */
function buildCommentGroup(topLevelComment: Element, commentStartElements: HTMLCollectionOf<Element>): Docx2HtmlComment[] {
  const commentId = topLevelComment.id

  const comments: Docx2HtmlComment[] = []

  // Push the top-level comment if exists
  const parentStartElement = Array.from(commentStartElements).find((el) => el.id === commentId)
  if (!parentStartElement) {
    console.error('Top level comment without matching start element found.', topLevelComment)
    return []
  }
  comments.push({
    id: commentId,
    text: parentStartElement.textContent,
    author: parentStartElement.getAttribute('data-author'),
    date: parentStartElement.getAttribute('data-date'),
  })

  // Get all comment-end elements that are descendants of the top-level comment
  const childElements = Array.from(topLevelComment.getElementsByClassName('comment-end'))

  // Push the children comments if exists
  childElements.forEach((child) => {
    const childStartElement = Array.from(commentStartElements).find((el) => el.id === child.id)
    if (!childStartElement) {
      console.error('Child comment without matching start element found.', child)
      return
    }

    comments.push({
      id: child.id,
      text: childStartElement.textContent,
      author: childStartElement.getAttribute('data-author'),
      date: childStartElement.getAttribute('data-date'),
    })
  })

  return comments
}
