import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { useContext, useState, useEffect } from 'react'
import { useSendInviteMutation } from '../apis/org-admin.api'
import { SendInviteBody } from '../schema/invite.schema'
import { AuthContext } from '@/context/auth-context'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import * as Sentry from '@sentry/browser'

type InviteMemberDialogProps = {
  visible: boolean
  onInviteSuccess: () => void
  onClose: () => void
}

/**
 * The InviteMemberDialog handles fetching and displaying of the Stripe portal Link.
 * This is to decouple the logic from the buttons that may activate this UI.
 */
export default function InviteMemberDialog(props: InviteMemberDialogProps) {
  const { visible, onInviteSuccess, onClose } = props

  // Local state
  const [error, setError] = useState<string | null>(null)
  const [emailInput, setEmailInput] = useState<string>('')

  // RTK-Query Mutation
  const [sendInvite, { isLoading }] = useSendInviteMutation()

  // User Context
  const { userAccountData } = useContext(AuthContext)
  const organizationId = userAccountData?.orgData?.id

  // Map of error detail to user-friendly error message
  const inviteErrorMessages: Record<string, string> = {
    'Invite already exists':
      'This user already has a pending invitation. In the "Invites" tab, you can click "Resend invitation" in the menu next to their invitation.',
    'User already in organization': 'This user is already a member of your organization.',
    default: 'There was an error creating the invitation. Please try again.',
  }

  function getUserFriendlyErrorMessage(errorDetail: string | undefined): string {
    return errorDetail && inviteErrorMessages[errorDetail] ? inviteErrorMessages[errorDetail] : inviteErrorMessages.default
  }

  async function handleSubmit() {
    if (!isLoading) {
      setError(null)
      const trimmedEmail = emailInput.trim()

      try {
        // Send the invite
        const inviteArgs: SendInviteBody = {
          invite: {
            organization_id: organizationId || '',
            invitee_email: trimmedEmail,
          },
        }

        // Use unwrap to handle the promise and catch errors
        await sendInvite(inviteArgs).unwrap()

        // Clear input and close dialog if invite was successfully sent
        setEmailInput('')
        onInviteSuccess()
        onClose()
      } catch (error) {
        let errorMessage = inviteErrorMessages.default

        // Handle FetchBaseQueryError from RTK Query
        if ((error as FetchBaseQueryError).data && typeof (error as FetchBaseQueryError).data === 'object') {
          const errData = (error as FetchBaseQueryError).data as { detail?: string }

          // Check if the error is a 409 Conflict and get specific message if available
          if ((error as FetchBaseQueryError).status === 409) {
            errorMessage = getUserFriendlyErrorMessage(errData.detail)
          }
        } else {
          Sentry.captureException(new Error(`Unable to send invitation to email ${trimmedEmail}`), {
            extra: { error: JSON.stringify(error), email: trimmedEmail, organizationId: organizationId },
          })
        }

        setError(errorMessage)
      }
    }
  }

  // Handle close
  function handleClose() {
    setError(null)
    setEmailInput('')
    onClose()
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmailInput(e.target.value)
    setError(null)
  }

  // Add a global listener for the Escape key to close the modal
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    if (visible) {
      document.addEventListener('keydown', handleEscapeKey)
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [visible, onClose])

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={handleClose}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[350px] max-w-2xl sm:min-w-[600px]">
        <h2 className="mb-3 text-lg font-semibold flex gap-x-2 items-center">
          <PaperAirplaneIcon className="h-10 p-2 rounded-full text-brand-700 bg-brand-100" />
          Invite member
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <input
            className="w-full rounded-md focus:ring-brand-700"
            type="email"
            autoFocus={true}
            placeholder="Email address"
            value={emailInput}
            onChange={handleInputChange}
            required
          />
          {error && <div className="mt-2 mx-1 text-left text-red-700 text-xs">{error}</div>}
          <div className="mt-3 flex gap-x-4 justify-end items-center">
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-white px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            >
              <div className="flex items-center gap-x-1">
                <div>Send invitation</div>
                <ButtonLoader loading={isLoading} />
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
