// The platforms accepted by the endpoint
// The endpoint uses the ID, the title is for the user
const platforms = [
  { id: 'Default', title: 'Platform Agnostic' },
  { id: 'LexisNexis', title: 'LexisNexis' },
  { id: 'Westlaw', title: 'Westlaw' },
]

type BooleanBuilderPlatformOptionsProps = {
  onChange: (platformId: string) => void
  selectedPlatformId: string
}

export default function BooleanBuilderPlatformOptions(props: BooleanBuilderPlatformOptionsProps) {
  const { onChange, selectedPlatformId } = props

  return (
    <div className={'mb-4'}>
      <label className="block mb-1 text-sm font-medium text-brand-neutral-700">Select the target platform</label>
      <fieldset className="">
        <legend className="sr-only">Select the target platform</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {platforms.map((platform) => (
            <div key={platform.id} className="flex items-center">
              <input
                id={platform.id}
                name="notification-method"
                type="radio"
                onChange={() => onChange(platform.id)}
                defaultChecked={selectedPlatformId == platform.id}
                className="h-4 w-4 border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
              />
              <label htmlFor={platform.id} className="ml-3 block text-sm text-brand-neutral-900">
                {platform.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}
