import { CheckIcon } from '@heroicons/react/24/solid'
import { OnboardingStep } from '../store/onboarding.slice'
import { useDispatch } from 'react-redux'
import { setCurrentStep } from '../store/onboarding.slice'

type OnboardingProgressProps = {
  steps: OnboardingStep[]
  currentStep: number
}

export default function OnboardingProgress({ steps, currentStep }: OnboardingProgressProps) {
  const dispatch = useDispatch()

  const handleStepClick = (stepNumber: number) => {
    dispatch(setCurrentStep(stepNumber))
  }

  return (
    <nav aria-label="Progress">
      <ol role="list" className="divide-y divide-brand-neutral-300 rounded-md border border-brand-neutral-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.stageLabel} className="relative md:flex md:flex-1">
            <button
              onClick={() => handleStepClick(step.stage)}
              className={`group flex w-full items-center ${
                step.isCompleted ? 'cursor-pointer' : step.stage === currentStep ? 'cursor-default' : 'cursor-not-allowed'
              }`}
              disabled={!step.isCompleted && step.stage !== currentStep}
            >
              {step.isCompleted ? (
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-brand-500 group-hover:bg-brand-800">
                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-brand-neutral-900">{step.stageLabel}</span>
                </span>
              ) : step.stage === currentStep ? (
                <span className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-brand-500">
                    <span className="text-brand-500">{step.stage}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-brand-500">{step.stageLabel}</span>
                </span>
              ) : (
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-brand-neutral-300 group-hover:border-brand-neutral-400">
                    <span className="text-brand-neutral-500 group-hover:text-brand-neutral-900">{step.stage}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-brand-neutral-500 group-hover:text-brand-neutral-900">{step.stageLabel}</span>
                </span>
              )}
            </button>

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
                  <svg fill="none" viewBox="0 0 22 80" preserveAspectRatio="none" className="h-full w-full text-brand-neutral-300">
                    <path d="M0 -2L20 40L0 82" stroke="currentcolor" vectorEffect="non-scaling-stroke" strokeLinejoin="round" />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
