import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'

type RenameItemDialogProps = {
  open: boolean
  onSave: (data: FormInputs) => void
  onClose: (value: boolean) => void
}

type FormInputs = {
  itemName: string
}

const validationSchema = z.object({
  itemName: z
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .pipe(z.string().min(1, 'This is a required field')),
})

export default function RenameItemDialog(props: RenameItemDialogProps) {
  const { open, onSave, onClose } = props
  const [loading, setLoading] = useState<null | boolean>(null)

  const cancelButtonRef = useRef(null)

  // Form state
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
  } = useForm<FormInputs>({
    defaultValues: {
      itemName: '',
    },
    resolver: zodResolver(validationSchema),
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      setLoading(false)
      reset()
    }
    if (errors.itemName) {
      setLoading(false)
      setTimeout(() => {
        clearErrors()
      }, 3000)
    }
  }, [errors.itemName, isSubmitSuccessful, reset])

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-brand-neutral-50 p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[17px] font-medium">
            <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
              <Pencil1Icon className="h-10 w-10 p-2 scale-75 rounded-full text-brand-700 bg-brand-100" />
              Rename File
            </h2>
          </Dialog.Title>
          <Dialog.Description className="mt-[10px] mb-5 text-[15px] leading-normal">
            <p className="text-brand-neutral-800 my-5">
              <strong>Please note:</strong> renaming this file will not update past chats.
            </p>
            <p className="text-brand-neutral-800 my-5">
              Previous chats will still reference the old file name. The file will be temporarily unavailable while it's being renamed.
            </p>
          </Dialog.Description>
          <form onSubmit={handleSubmit(onSave)}>
            <fieldset className="mb-[15px] flex items-center gap-5">
              <input
                id="itemName"
                autoFocus={true}
                {...register('itemName', { required: true })}
                className="block w-full rounded-md border-0 p-2 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-700 sm:text-sm sm:leading-6"
                placeholder="New name"
              />
            </fieldset>
            {errors.itemName && <p className={'text-sm text-red-700'}>{errors.itemName.message}</p>}
            <div className="mt-[25px] gap-3 flex justify-end">
              <Dialog.Close asChild>
                <button
                  type="button"
                  className={`bg-brand-neutral-50 text-brand-neutral-900 ring-brand-neutral-300 hover:bg-brand-neutral-100 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:w-auto h-auto`}
                  onClick={() => onClose(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </Dialog.Close>
              <Dialog.Close asChild>
                <button
                  type="submit"
                  className={`bg-brand-500 text-white hover:bg-brand-400 mt-3 inline-flex w-full items-center justify-center rounded-md gap-2 px-3 py-2 text-sm font-semibold shadow-sm sm:w-auto h-auto`}
                  onClick={() => setLoading(true)}
                >
                  Save
                  <ButtonLoader loading={loading ?? false} />
                </button>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
