import { z } from 'zod'

export const UserSettingsSchema = z.object({
  prompt_assist_enabled: z.boolean(),
})

export const SettingsGetResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    user: UserSettingsSchema,
    organization: z.record(z.any()).optional().default({}),
  }),
})

export const SettingsPatchResponseSchema = z.object({
  success: z.boolean(),
  data: UserSettingsSchema,
})

export type UserSettings = z.infer<typeof UserSettingsSchema>
export type SettingsGetResponse = z.infer<typeof SettingsGetResponseSchema>
export type SettingsPatchResponse = z.infer<typeof SettingsPatchResponseSchema>
