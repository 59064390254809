/**
 * Utility functions for managing application state using local/session storage.
 */

const SIDEBAR_ITEMS_STATE_KEY = 'sidebarItemsState'

/**
 * Saves the current state of the sidebar items to local storage.
 *
 * @param sidebarItemsState The current state of the sidebar items to be saved
 */
export const saveSidebarItemsStateToLocalStorage = (sidebarItemsState: any) => {
  try {
    localStorage.setItem(SIDEBAR_ITEMS_STATE_KEY, JSON.stringify(sidebarItemsState))
  } catch (error) {
    console.error('Failed to save sidebar items state to localStorage:', error)
  }
}

/**
 * Loads the state of the sidebar items from local storage.
 *
 * @returns The parsed state from local storage, or `null` if nothing is found or parsing fails
 */
export const loadSidebarItemsStateFromLocalStorage = (): any | null => {
  try {
    const sidebarItemsStateFromLocalStorage = localStorage.getItem(SIDEBAR_ITEMS_STATE_KEY)
    return sidebarItemsStateFromLocalStorage ? JSON.parse(sidebarItemsStateFromLocalStorage) : null
  } catch (error) {
    console.error('Failed to parse sidebar items state from localStorage:', error)
    localStorage.removeItem(SIDEBAR_ITEMS_STATE_KEY)
    return null
  }
}
