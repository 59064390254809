import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataDrafting, ChatV2QueryMetadataDraftingSchema, initialDraftingMetadata } from '../schemas/chat-query-metadata-schema'

// Default new drafting chat source
export const defaultNewDraftingChatSourceValues: ChatV2QueryMetadataDrafting = {
  doc_type: '',
  relevant_parties: '',
  key_details: '',
  jurisdiction: '',
  additional_preferences: '',
}

// Set the source_name for a conversation's currentSource
export const reducerCurrentSourceDraftingChatMetadata = (
  state: ChatV2State,
  action: PayloadAction<{
    conversationId: string
    draftingMetadata: ChatV2QueryMetadataDrafting
  }>
) => {
  const { conversationId, draftingMetadata } = action.payload

  // Validate currentSource against schema
  const conversation = state.conversations[conversationId]
  if (conversation.currentSource == null)
    conversation.currentSource = {
      ...initialDraftingMetadata,
    }

  const validation = ChatV2QueryMetadataDraftingSchema.safeParse(conversation.currentSource)
  if (!validation.success) throw new Error(`Attempting to set currentSource for wrong metadata type: ${validation.error}`)

  // Type the current source metadata
  const currentSource = conversation.currentSource as ChatV2QueryMetadataDrafting

  currentSource.doc_type = draftingMetadata.doc_type
  currentSource.relevant_parties = draftingMetadata.relevant_parties
  currentSource.key_details = draftingMetadata.key_details
  currentSource.jurisdiction = draftingMetadata.jurisdiction
  currentSource.additional_preferences = draftingMetadata.additional_preferences
}
