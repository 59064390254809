import { useStartNewChat } from '@/chat-common/hooks/use-start-new-chat'
import { useParams } from 'react-router-dom'
import { ChatV2Feature } from '../store/chat-v2.slice'
import { newChatButtonLabel } from './chat-window-utils'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import AddCircleIcon from '@mui/icons-material/AddCircle'

type NewChatButtonProps = {
  onClickCallback?: () => void
  isCompact?: boolean
}

export default function NewChatButton(props: NewChatButtonProps) {
  const { onClickCallback, isCompact } = props
  const { trackEvent } = useAnalytics()
  const { chatFeature } = useParams()
  const { startNewChat } = useStartNewChat()

  const handleClick = () => {
    startNewChat(chatFeature as ChatV2Feature, onClickCallback)
    trackEvent(AnalyticsEvent.NewChat, { feature: chatFeature as ChatV2Feature })
  }

  return (
    <button
      onClick={handleClick}
      className={`focus:outline-none ${
        isCompact ? 'p-2 text-brand-500 hover:text-brand-400' : 'w-full rounded-md bg-brand-500 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400'
      }`}
      aria-label="Start new chat"
      title="New Chat"
    >
      {isCompact ? (
        <AddCircleIcon style={{ fontSize: '28px' }} />
      ) : (
        <span className="inline-block whitespace-nowrap">{newChatButtonLabel(chatFeature as ChatV2Feature)}</span>
      )}
    </button>
  )
}
