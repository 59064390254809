import { ChatV2Message } from '@/chat-common/store/chat-v2.slice'
import referenceMetadataToTitle from '@/chat-common/util/reference-metadata-to-title'

type ChatMessageBubbleReferenceProps = {
  message: ChatV2Message
  referenceKey: string
}

export default function ChatMessageBubbleReferencePrintable(props: ChatMessageBubbleReferenceProps) {
  const { message, referenceKey } = props

  const reference = message.metadata.references[referenceKey]
  const title = referenceMetadataToTitle(reference)

  return (
    <p>
      [{referenceKey}] {title}
    </p>
  )
}
