import { FullMetadata } from 'firebase/storage'

//create enum for type Can be file or folder
export enum NodeType {
  FILE = 'file',
  FOLDER = 'folder',
}

// export enum FileStatus {
//   READY = 'ready',
//   PROCESSING = 'processing',
//   ERROR = 'error',
// }
export type FileStatus = 'processing' | 'complete' | 'error' | 'ocr'

interface BaseNode {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  parentId: string
  folderPath: string
  error?: Record<string, string>
}

export interface FolderNode extends BaseNode {
  type: NodeType.FOLDER
}

export interface FileNode extends BaseNode {
  type: NodeType.FILE
  sourceLocation: string
  status: FileStatus
  extension: string
  size: number
  metadata?: FullMetadata
}

export type TreeNode = Array<FolderNode | FileNode>
