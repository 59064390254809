import { getAuth } from 'firebase/auth'
import { store } from '@/store/store'
import * as Sentry from '@sentry/browser'
import { FirebaseError } from 'firebase/app'
import { authErrorTranslate } from '@/firebase/auth/auth-error-translate'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { BaseWSEvent } from '@/store/ws-clients/ws.types'
import chatV2Dispatcher from './chat-v2-dispatcher'
import {
  ChatV2Conversation,
  ChatV2Feature,
  ChatV2Message,
  ChatV2MessageType,
  addClientErrorMessageBubble,
  chatV2SetConversationLoadingStatus,
} from './store/chat-v2.slice'
import { fetchConversationFollowUpQuestions } from './fetch/fetch-suggested-questions'
import { nanoid } from 'nanoid'
import { brandedAIFriendlyName } from '@/util/enterprise'

/**
 * Chat V2 Websocket Query Args
 * @param metadata type options are the only accepted metadata types for given features
 */
export type ChatV2WebsocketQueryArgs = {
  feature: ChatV2Feature
  conversation: ChatV2Conversation
  userMessage: ChatV2Message
  anonymousRateLimitedExceededFunction: () => void
}

/**
 * Chat V2 Websocket Query
 * Handles the connection to the websocket server, deliverying the query payload,
 * and directing the streaming response.
 *
 * TODO: Handle browser close / navigation event to gracefully close websocket
 */
export default async function runChatV2WebsocketQuery(args: ChatV2WebsocketQueryArgs) {
  const { feature, conversation, userMessage, anonymousRateLimitedExceededFunction } = args

  // Holds the active conversation id, which may change during a migration event
  let activeConversationId = conversation.id
  let activeMessageId: string | null = null

  // Migration callback
  // Change the active conversation id for future events in this function
  const updateActiveConversationId = (newConversationId: string) => {
    activeConversationId = newConversationId
  }

  // Active Message ID callback
  // Change the active message id for future events in this function
  const updateActiveMessageId = (newMessageId: string) => {
    activeMessageId = newMessageId
  }

  // Set loading status
  store.dispatch(chatV2SetConversationLoadingStatus({ conversationId: activeConversationId, isLoading: true }))

  const auth = getAuth()
  const currentUser = auth.currentUser

  // Standard Sentry error object extras
  const defaultExtras = {
    feature,
    original_conversation_id: conversation.id,
    migrated_to_conversation_id: activeConversationId,
    isPending: conversation.isPending,
    query: userMessage.text,
    currentSource: JSON.stringify(conversation.currentSource),
    currentUser,
    onLine: navigator.onLine,
    cookieEnabled: navigator.cookieEnabled,
  }

  if (currentUser == null) {
    Sentry.captureException(new Error('WS Client Error: currentUser == null'), {
      extra: {
        ...defaultExtras,
      },
    })
    return
  }

  // Check if the user is anonymous
  const isAnonymous = currentUser.isAnonymous

  // Get the auth token
  let token: string | undefined
  try {
    token = await currentUser.getIdToken()
  } catch (e) {
    // TODO: Render the error to the UX

    if (e instanceof FirebaseError) {
      try {
        // Will log a sentry error if this is an unexpected non-UX error
        // Otherwise we don't need to report these general user UX account errors (like network failed, incorrect pw, etc.)
        await authErrorTranslate(e, currentUser.email ?? '')

        return
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            ...defaultExtras,
          },
        })
      }
    } else {
      Sentry.captureException(e, {
        extra: {
          ...defaultExtras,
        },
      })
      return
    }
  }

  // Null check the token
  if (typeof token !== 'string') {
    Sentry.captureException(new Error(`Authentication Error: User id token is not a string. Value: ${token}. cannot establish connection.`), {
      extra: {
        ...defaultExtras,
      },
    })
    // TODO: setUxException('An unexpected error has occurred. Please try again.')
    return
  }

  // Determine the websocket endpoint
  const wsEndpoint = `${kPaxtonAppApiBaseUrl(true)}/api/v1/chat/ws`

  // Create the new websocket connection
  const ws = new WebSocket(wsEndpoint)

  // Set the sentry transaction scope and get the id
  const sentry_transaction_id = nanoid()

  // On Open
  ws.onopen = async (event) => {
    // Ensure trusted
    if (!event.isTrusted) {
      Sentry.captureException(new Error(`ws.onopen - UNTRUSTED. event: ${event}`), {
        extra: {
          ...defaultExtras,
          event: event,
          eventJson: JSON.stringify(event),
        },
      })
      return
    }

    // Send Auth Token and sentry transaction_id
    // This is always the first step and will allow the connection to remain open for this transaction
    await ws.send(JSON.stringify({ bearer: token!, transaction_id: sentry_transaction_id }))

    // Construct the query payload
    const metadata = conversation.currentSource
    const queryPayload = {
      feature,
      conversation_id: conversation.isPending ? null : conversation.id, // do not send conversation id with a pending conversation, because we need the server to generate an id
      query: userMessage.text,
      metadata: metadata,
    }

    // ELSE
    // Send the normal query payload
    console.log('Sending chat query payload: ', queryPayload)
    await ws.send(JSON.stringify(queryPayload))
  }

  // Handle received messages
  ws.onmessage = (event: BaseWSEvent) => {
    // Ensure trusted
    if (!event.isTrusted) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`ws.onmessage - UNTRUSTED. data: ${event.data}`), {
          extra: {
            ...defaultExtras,
          },
        })
      })

      return
    }
    // console.log('Received websocket event: ', event)

    // Send data to dispatcher
    const pendingConversationId = conversation.isPending ? conversation.id : null // If this is not a pending conversation we don't need to pass the id, and it can be null
    chatV2Dispatcher(pendingConversationId, updateActiveConversationId, updateActiveMessageId, userMessage, event.data)
  }

  // Handle websocket exceptions
  ws.onerror = (event) => {
    // Sentry captures more information about the error than the console, leave this as log
    console.log('Websocket error event: ', event)

    // Set loading status to false
    store.dispatch(chatV2SetConversationLoadingStatus({ conversationId: activeConversationId, isLoading: false }))

    // NOTE: DO NOT NEED TO ADD AN ERROR MESSAGE BUBBLE HERE
    // AN ERROR IS ALWAYS CALLED AS A PAIR WITH onclose

    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(new Error(`ws.onerror`), {
        extra: {
          ...defaultExtras,
          event: event,
          eventJson: JSON.stringify(event),
          eventType: event.type,
          eventIsTrusted: event.isTrusted,
          anonymousUser: isAnonymous,
        },
      })
    })
  }

  // Handle websocket close
  ws.onclose = (event) => {
    console.log('Websocket connection closed. Event: ', event)

    // Handle close codes
    const code = event.code
    const reason = event.reason

    // Default onclose extras
    const onCloseExtras = {
      ...defaultExtras,
      event: event,
      eventJson: JSON.stringify(event),
      eventCode: code,
      eventReason: reason,
      anonymousUser: isAnonymous,
    }

    // Ensure trusted
    if (!event.isTrusted) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`ws.onclose - UNTRUSTED. Code: ${code}. Reason: ${reason}`), {
          extra: {
            ...onCloseExtras,
          },
        })
      })
      return
    }

    // ON ALL CLOSE CASES
    // Set loading status to false
    store.dispatch(chatV2SetConversationLoadingStatus({ conversationId: activeConversationId, isLoading: false }))

    // ON SOME CLOSE CASES
    // Handle normal closure and perform follow up tasks
    if (code === 1000) {
      // If the message_type is response and is_error is false, fetch suggested questions
      if (activeMessageId) {
        const activeConversation = store.getState().chatV2State.conversations[activeConversationId] ?? null
        const activeMessage = activeConversation.messages[activeMessageId] ?? null
        if (
          activeMessage &&
          activeMessage.metadata.message_type == ChatV2MessageType.response &&
          !activeMessage.metadata.is_error &&
          activeMessage.metadata.get_follow_up_questions
        ) {
          // Do not fetch suggested questions for these features:

          if (activeConversation.feature == ChatV2Feature.contractanalysis || activeConversation.feature == ChatV2Feature.contractsearch) {
            // Do nothing
          } else {
            // Fetch suggested questions
            fetchConversationFollowUpQuestions(activeConversationId, activeConversation.feature)
          }
        }
      }
    }

    // Actions to take for all error codes except excluded
    else {
      // Ignore this reason - does not need reporting
      if (reason == 'Rate limit exceeded') {
        // Do nothing
      }

      // Capture the Sentry Exception with the transaction id (same for all error codes)
      else {
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })
          Sentry.captureException(new Error(`ws.onclose - Code: ${code}. Reason: ${reason}`), {
            extra: {
              ...onCloseExtras,
            },
          })
        })
      }
    }

    // CLOSE CODE HANDLERS: Unique actions to take for specific close codes
    switch (code) {
      // Normal closure
      case 1000: {
        // Do nothing - handled above
        break
      }

      // Going away (server is going away and sends closing frame)
      case 1001: {
        // Add error message to the conversation UX
        store.dispatch(
          addClientErrorMessageBubble({
            conversationId: activeConversationId,
            errorMessage: `The connection to ${brandedAIFriendlyName} was unexpectedly disconnected. Please try again.`,
          })
        )
        break
      }

      // Connection disconnected without close frame (unexpected / likely connetivity loss likely from client-side issue)
      case 1006: {
        // Get most recent message in the conversation
        const state = store.getState().chatV2State
        const conversation = activeConversationId in state.conversations ? state.conversations[activeConversationId] : null
        const messages = conversation?.messages ?? []

        // Get the most recent message
        const mostRecentMessage = Object.values(messages).reverse()[0] ?? null

        // If the most recent message is a response, it means we were disconnected while it was being generated
        let text = `The connection to ${brandedAIFriendlyName} was unexpectedly disconnected. Please try again.`

        // If the message started generating, and we are disconnected from the internet, the user may be able to retrieve it on refresh,
        // because this condition suggests the 1006 is caused by our internet connection termination.
        if (mostRecentMessage.metadata.message_type == ChatV2MessageType.response && !navigator.onLine) {
          text = `${brandedAIFriendlyName} was disconnected while generating your response. Refresh once the task has completed to see the response, or retry.`
        }

        // Add error message to the conversation UX
        store.dispatch(
          addClientErrorMessageBubble({
            conversationId: activeConversationId,
            errorMessage: text,
          })
        )
        break
      }

      // Generic Policy Violation - check reason
      case 1008: {
        // Rate limit exceeded
        if (event.reason === 'Rate limit exceeded') {
          if (isAnonymous) {
            // Add error message to the conversation UX
            store.dispatch(
              addClientErrorMessageBubble({
                conversationId: activeConversationId,
                errorMessage: 'Your rate limit for the day has been exceeded. Create a free account to submit more requests.',
              })
            )

            anonymousRateLimitedExceededFunction()
            return
          } else {
            // Add error message to the conversation UX
            store.dispatch(
              addClientErrorMessageBubble({
                conversationId: activeConversationId,
                errorMessage: 'Your rate limit for the day has been exceeded. Your limit will reset by tomorrow.',
              })
            )
          }
        }

        break
      }

      // 1011 - Server termination errors
      case 1011: {
        // Add error message to the conversation UX
        store.dispatch(
          addClientErrorMessageBubble({
            conversationId: activeConversationId,
            errorMessage: `${brandedAIFriendlyName} encountered an unexpected error. Please try again.`,
          })
        )

        break
      }

      // 1013 - Try again later
      case 1013: {
        // Add error message to the conversation UX
        store.dispatch(
          addClientErrorMessageBubble({
            conversationId: activeConversationId,
            errorMessage: `${brandedAIFriendlyName} was unable to fulfill your request due to high service load. Please try again.`,
          })
        )

        break
      }

      // Handle all other cases
      default: {
        store.dispatch(
          addClientErrorMessageBubble({
            conversationId: activeConversationId,
            errorMessage: `${brandedAIFriendlyName} encountered an unexpected error. Please try again.`,
          })
        )

        break
      }
    }
  }
}
