import { setSidebarItemsState } from '@/store/slices/ui-state.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { loadSidebarItemsStateFromLocalStorage } from '@/util/storage-utils'
import { ReactNode } from 'react'

/**
 * AppInit Component
 * - Component to handle application initialization tasks
 *
 * BLOCKING:
 * - Can be used to block the application until initialization is complete
 * - Conditionally render children based on initialization status
 *
 * NON-BLOCKING:
 * - Can be used to execute startup functions in the background that are not-blocking
 */
export const AppInit = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch()

  // Load the sidebar items state from localStorage
  const loadedSidebarItemsState = loadSidebarItemsStateFromLocalStorage()
  if (loadedSidebarItemsState) {
    dispatch(setSidebarItemsState(loadedSidebarItemsState))
  }

  return children
}
