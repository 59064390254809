import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { kLinkPrivacyPolicyUrl, kLinkTermsOfServiceUrl } from '../../constants/constants-links'
import { PaxtonDisclaimer } from '@/constants/constants-components'
import { AuthContext } from '../../context/auth-context'
import googleAuthRedirect from '../../firebase/auth/google-auth-redirect'
// import azureAuthRedirect from '../../firebase/auth/azure-auth-redirect'
import signInEmail from '../../firebase/auth/sign-in-email'
import { authErrorTranslate } from '../../firebase/auth/auth-error-translate'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { kSegmentTrackSignIn } from '@/constants/constants-segment'
import InlineTipError from '../inline-tips/InlineTipError'
import { FirebaseError } from 'firebase/app'
import * as Sentry from '@sentry/browser'
import { brandedAIFriendlyName } from '@/util/enterprise'

type FormInputs = {
  email: string
  password: string
}

type SignInFormProps = {
  switchToSignUp: () => void
  switchToForgotPassword: () => void
  onSuccess: () => void
  tooltipMessage?: string
}

export default function SignInForm(props: SignInFormProps) {
  const { switchToSignUp, switchToForgotPassword, onSuccess, tooltipMessage } = props

  const authContext = useContext(AuthContext)
  const user = authContext?.user

  // Redirect if the user is not null
  useEffect(() => {
    if (user != null && !user.isAnonymous) {
      onSuccess()
    }
  }, [onSuccess, user])

  // Local State
  const [validationError, setValidationError] = useState('')
  const [loading, setLoading] = useState(false)

  // Form Hook
  const { register, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  // Sign in error component
  function ValidationErrorMessage() {
    if (validationError != '') {
      return (
        <div>
          <p className="text-center text-red-700">{validationError}</p>
        </div>
      )
    } else {
      return null
    }
  }

  // Handle Sign Up
  async function handleSignIn(data: FormInputs) {
    setValidationError('')
    setLoading(true)
    try {
      await signInEmail(data.email, data.password)
      // console.log('Signed in user: ', result)

      // Track
      analytics.track(kSegmentTrackSignIn, {
        method: 'email',
      })

      // onSuccess
      onSuccess()
    } catch (e) {
      if (e instanceof FirebaseError) {
        const validationError = await authErrorTranslate(e, data.email)
        setValidationError(validationError)
      } else {
        setValidationError(`Unexpected error. ${brandedAIFriendlyName} has been notified.`)

        Sentry.captureException(e, {
          extra: {
            email: data.email,
          },
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {tooltipMessage && <div className={'mb-4'}>{InlineTipError(tooltipMessage)}</div>}
      <h2 className="mb-2 text-center text-2xl font-bold tracking-tight text-brand-neutral-900">Sign in</h2>

      <form className="space-y-6" method="POST" onSubmit={handleSubmit((data) => handleSignIn(data))}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email-address"
              {...register('email', { required: true })}
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Email address"
            />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              {...register('password', { required: true })}
              type="password"
              autoComplete="current-password"
              required
              minLength={6}
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Password"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-brand-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
          >
            Sign in
            <ButtonLoader loading={loading} />
          </button>
        </div>

        <ValidationErrorMessage />
      </form>

      <p className="mt-5 text-center text-sm text-brand-neutral-500">
        Need an account?{' '}
        <button onClick={switchToSignUp} className="font-semibold leading-6 text-brand-500 hover:text-brand-400">
          Sign up
        </button>
      </p>

      <p className="mt-5 text-center text-sm text-brand-neutral-500">
        Forgot your password?{' '}
        <button onClick={switchToForgotPassword} className="font-semibold leading-6 text-brand-500 hover:text-brand-400">
          Send password reset email
        </button>
      </p>

      <div>
        <div className="relative mt-5">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-brand-neutral-200" />
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-brand-neutral-50 px-6 text-brand-neutral-900">Or continue with</span>
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-4">
          <button
            onClick={() => googleAuthRedirect()}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#ffffff] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] border border-slate-200 hover:shadow transition duration-150"
          >
            <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
            <span className="text-sm font-semibold leading-6 text-black">Google</span>
          </button>
        </div>

        {/* <div className="mt-2 grid grid-cols-1 gap-4">
          <button
            onClick={() => azureAuthRedirect()}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#ffffff] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] border border-slate-200 hover:shadow transition duration-150"
          >
            <img className="w-6 h-6" src="https://www.svgrepo.com/show/349454/microsoft.svg" loading="lazy" alt="microsoft logo" />
            <span className="text-sm font-semibold leading-6 text-black">Microsoft</span>
          </button>
        </div> */}
      </div>

      <div className="mt-5 text-xs text-center">
        <a href={kLinkTermsOfServiceUrl} target="_blank">
          <span className="underline whitespace-nowrap">Terms of Service</span>
        </a>
        &nbsp;&nbsp;&nbsp;
        <a href={kLinkPrivacyPolicyUrl} target="_blank">
          <span className="underline whitespace-nowrap">Privacy Policy</span>
        </a>
      </div>
      <div className="mt-5 text-xs text-center">
        <PaxtonDisclaimer />
      </div>
    </>
  )
}
