import putMessageFeedback from '@/chat-common/fetch/put-message-feedback'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { ChatV2Message } from '@/chat-common/store/chat-v2.slice'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

type MessageFeedbackDialogProps = {
  message: ChatV2Message
  positive: boolean
  visible: boolean
  onClose: () => void
}

/**
 * The MessageFeedbackDialog handles fetching and displaying of the Stripe portal Link.
 * This is to decouple the logic from the buttons that may activate this UI.
 */
export default function MessageFeedbackDialog(props: MessageFeedbackDialogProps) {
  const { message, positive, visible, onClose } = props

  // Local state
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [text, setText] = useState<string>('')

  // Handle submit
  async function handleSubmit() {
    if (!loading) {
      console.log('Handling feedback submission for message: ', message.metadata.message_id)

      try {
        setError(null)
        setLoading(true)

        // Thumb
        const thumb: FeedbackThumb = positive ? FeedbackThumb.UP : FeedbackThumb.DOWN

        // Submit the feedback
        putMessageFeedback({ message: message, thumb: thumb, thumb_text: text })

        // Perform a 1 second delay
        await new Promise((resolve) => setTimeout(resolve, 1000))

        onClose()
      } catch (error) {
        console.error(error)
        setError('There was an error submitting your feedback. Please try again.')
      } finally {
        setLoading(false)
      }
    }
  }

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-2xl sm:min-w-[600px]">
        <h2 className="mb-2 text-lg font-semibold flex gap-x-2 items-center">
          {positive ? (
            <HandThumbUpIcon className="h-10 p-2 rounded-full text-green-700 bg-green-100" />
          ) : (
            <HandThumbDownIcon className="h-10 p-2 rounded-full text-red-700 bg-red-100" />
          )}{' '}
          Provide feedback
        </h2>
        <textarea
          className={`w-full h-28 rounded-md`}
          placeholder={`${positive ? 'What did you like about the response?' : 'What was the issue with the response? How could it be improved?'}`}
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>

        <div className={'h-4'} />
        <div className={'flex gap-x-4 justify-end items-center'}>
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
            onClick={onClose}
          >
            Cancel
          </button>

          {/* Loading complete, real link */}

          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={handleSubmit}
          >
            <div className={'flex items-center gap-x-1'}>
              <div>Submit feedback</div>
              <ButtonLoader loading={loading} />
            </div>
          </button>
        </div>
        {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
      </div>
    </div>
  )
}
