import * as Sentry from '@sentry/react'
import { ChatV2MessageReferenceType, ChatV2ReferenceType } from '../store/chat-v2.slice'
import { PaxtonHighlightArea, paxtonHighlightAreaSchema } from '@/components/react-pdf-viewer/schema/paxton-highlight-area'

enum PageNumsVersion {
  ORIGINAL = 'ORIGINAL',
  PDF_VIEWER = 'PDF_VIEWER',
}

type ReferenceMetadataSourcePagesProps = {
  reference: ChatV2MessageReferenceType
}

/**
 * Reference Metadata Source Pages
 * Uses conditional logic to extract the page numbers appropriately from different source types.
 * Renders them for the user
 * @returns {React.FC} of the page indexes
 */
export default function ReferenceMetadataSourcePages(props: ReferenceMetadataSourcePagesProps): JSX.Element {
  const { reference } = props

  // Placeholder array for found page numbers
  const pageNums = Array<number>()

  // Determine page number extraction technique
  const pageNumsVersion = reference?.metadata?.process_version === 'V2' ? PageNumsVersion.PDF_VIEWER : PageNumsVersion.ORIGINAL

  switch (pageNumsVersion) {
    // Extract page numbers from the original reference metadata
    case PageNumsVersion.ORIGINAL: {
      if (typeof reference.metadata?.page_num != 'undefined' && reference.metadata?.page_num != '') {
        // Clean the pageNum value
        const pageNum = reference.metadata.page_num.toString().replace('-', '').trim()

        // Convert pageNum to an integer
        const pageNumInt = parseInt(pageNum)

        // If the pageNum is a valid number, add it to the array
        if (!isNaN(pageNumInt)) {
          pageNums.push(pageNumInt)
        }
      }
      break
    }

    // Extract page numbers from the PDF viewer reference highlight data
    case PageNumsVersion.PDF_VIEWER: {
      const uniquePageNums = getUniqueHighlightPageNumbers(reference)
      pageNums.push(...uniquePageNums)
      break
    }

    default:
      break
  }

  const referenceType = reference?.metadata?.reference_type

  // Hide page numbers display if there are no page numbers found or if it's a full document reference
  const shouldHidePageDisplay = pageNums.length === 0 || referenceType === ChatV2ReferenceType.documentquery_full_doc

  if (shouldHidePageDisplay) {
    return <></>
  }

  // Determine prefix based on the number of pages
  const prefix = pageNums.length > 1 ? 'Pages' : 'Page'

  return (
    <div>
      <div className={'text-xs text-brand-neutral-500'}>
        {prefix} {pageNums.join(', ')}
      </div>
    </div>
  )
}

/**
 * Aggregate Relevant Highlight Page Numbers
 * Each highlight is associated with a pageIndex.
 * This function aggregates the unique pageIndexes from the highlight data.
 * Converts the pageIndexes to page numbers.
 * @param reference
 * @returns {Array<number>} of unique page numbers (indexes + 1)
 */
function getUniqueHighlightPageNumbers(reference: ChatV2MessageReferenceType): Array<number> {
  // Validate the reference:
  const valid = reference?.metadata?.process_version === 'V2' && reference.relevant_highlights instanceof Array
  if (!valid) {
    console.log('invalid reference: ', reference)
    Sentry.captureException(new Error(`Received invalid reference for highlight page index aggregation`), {
      extra: {
        receivedReference: JSON.stringify(reference),
      },
    })
    return []
  }

  // Get the highlights array
  const highlightPageIndexes = reference.relevant_highlights as Array<PaxtonHighlightArea>

  // Validate based on our schema
  const validHighlight = paxtonHighlightAreaSchema.safeParse(highlightPageIndexes[0])
  if (!validHighlight.success) {
    console.log('invalid highlight: ', validHighlight.error)
    Sentry.captureException(new Error(`Received invalid highlight page index for aggregation`), {
      extra: {
        checkedHighlight: JSON.stringify(highlightPageIndexes[0]),
        error: JSON.stringify(validHighlight.error),
      },
    })
    return []
  }

  // Create an array of unique  pageIndex values
  const pageIndexes = Array<number>()
  for (const { pageIndex } of highlightPageIndexes) {
    // Convert the pageIndex to a page number
    const pageNum = pageIndex + 1

    if (!pageIndexes.includes(pageNum)) {
      pageIndexes.push(pageNum)
    }
  }

  // Sort in ascending order
  pageIndexes.sort((a, b) => a - b)

  return pageIndexes
}
