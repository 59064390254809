import { useState } from 'react'
import { useAppDispatch } from '@/store/store-hooks'
import { chatV2SetFormValidationError } from '@/chat-common/store/chat-v2.slice'

const minYearValue = 1776
const maxYearValue = 2099

export enum DateRangeOptions {
  SINCE_ANY = 'Any time',
  SINCE_2023 = 'Since 2023',
  SINCE_2022 = 'Since 2022',
  SINCE_2019 = 'Since 2019',
  CUSTOM_RANGE = 'Custom',
}

export enum YearType {
  LOW = 'yearLow',
  HIGH = 'yearHigh',
}

type DateRangeSelectorProps = {
  label?: string
  conversationId: string
  yearLow: number | null
  yearHigh: number | null
  setDateRange: (yearLow: number | null, yearHigh: number | null) => void
}

export default function DateRangeSelector(props: DateRangeSelectorProps) {
  const { label, conversationId, yearLow, yearHigh, setDateRange } = props
  const dispatch = useAppDispatch()
  const [showCustomDateRange, setShowCustomDateRange] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | null>(null)

  // Determine the selected drop down value from state conditions
  function getDateDropdownValue() {
    if (showCustomDateRange) return DateRangeOptions.CUSTOM_RANGE
    if (yearLow == null && yearHigh == null) return DateRangeOptions.SINCE_ANY
    if (yearLow === 2023 && yearHigh == null) return DateRangeOptions.SINCE_2023
    if (yearLow === 2022 && yearHigh == null) return DateRangeOptions.SINCE_2022
    if (yearLow === 2019 && yearHigh == null) return DateRangeOptions.SINCE_2019
    return DateRangeOptions.CUSTOM_RANGE
  }

  const dateDropdownValue = getDateDropdownValue()

  // Prevent users from being able to enter non-numeric values in the number input fields
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (['e', 'E', '+', '-'].includes(event.key)) {
      event.preventDefault()
    }
  }

  function handleDateRangeOptionChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedOption = event.target.value as DateRangeOptions
    const showCustomDateRangeOnChange = selectedOption === DateRangeOptions.CUSTOM_RANGE
    setShowCustomDateRange(showCustomDateRangeOnChange)

    // If not show custom range, set state accordingly based on the enum
    if (!showCustomDateRangeOnChange) {
      if (formError && dateDropdownValue === DateRangeOptions.CUSTOM_RANGE) {
        // Moving from custom range to a preset range, remove form errors from custom range
        setFormError(null)
        dispatch(chatV2SetFormValidationError({ conversationId, formValidationError: false }))
      }
      switch (selectedOption) {
        case DateRangeOptions.SINCE_ANY:
          setDateRange(null, null)
          break
        case DateRangeOptions.SINCE_2023:
          setDateRange(2023, null)
          break
        case DateRangeOptions.SINCE_2022:
          setDateRange(2022, null)
          break
        case DateRangeOptions.SINCE_2019:
          setDateRange(2019, null)
          break
        default:
          break
      }
    }
  }

  function handleCustomRangeInput(yearType: YearType) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      let yearValue: number | null = parseInt(event.target.value)
      if (isNaN(yearValue)) yearValue = null
      if (yearType === YearType.LOW) {
        validateCustomRangeInput(yearValue, yearHigh)
        setDateRange(yearValue, yearHigh)
      } else {
        validateCustomRangeInput(yearLow, yearValue)
        setDateRange(yearLow, yearValue)
      }
    }
  }

  /**
   * Validate Custom Range Form Input
   * Handles invalid input and corrections by setting conversation formValidationError
   * and local error ux
   */
  function validateCustomRangeInput(yearLow: number | null, yearHigh: number | null) {
    if (yearLow == null || yearHigh == null || yearLow < minYearValue || yearLow > yearHigh || yearHigh > maxYearValue || yearHigh < yearLow) {
      setFormError(`Please enter a valid range between ${minYearValue} and ${maxYearValue}.`)
      // Set conversation error
      dispatch(chatV2SetFormValidationError({ conversationId, formValidationError: true }))
    } else {
      setFormError(null)
      // Remove conversation error
      dispatch(chatV2SetFormValidationError({ conversationId, formValidationError: false }))
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-4">
        <div>
          <label htmlFor="date-range-option" className="block text-sm font-bold text-brand-neutral-700">
            {label ?? 'Date range'}
          </label>
          <select
            value={dateDropdownValue}
            id="date-range-option"
            name="date-range-option"
            className="mt-1 block w-full rounded-md border-0 py-[4.5px] px-2 pr-8 hover:bg-brand-neutral-50 text-brand-neutral-900 ring-1 ring-inset ring-brand-neutral-300 focus:ring-2 focus:ring-brand-neutral-600 text-sm"
            onChange={handleDateRangeOptionChange}
          >
            {Object.values(DateRangeOptions).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {(showCustomDateRange || dateDropdownValue === DateRangeOptions.CUSTOM_RANGE) && (
          <div className="flex gap-2">
            <div>
              <label htmlFor="date-range-start" className="block text-sm font-bold text-brand-neutral-700">
                Start
              </label>
              <input
                type="number"
                min={minYearValue}
                max={maxYearValue}
                value={yearLow?.toString() ?? ''}
                id="date-range-start"
                name="date-range-start"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mt-1 block rounded-md border-0 py-[4.5px] px-2 hover:bg-brand-neutral-50 text-brand-neutral-900 ring-1 ring-inset ring-brand-neutral-300 focus:ring-2 focus:ring-brand-neutral-600 text-sm"
                onChange={handleCustomRangeInput(YearType.LOW)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <label htmlFor="date-range-end" className="block text-sm font-bold text-brand-neutral-700">
                End
              </label>
              <input
                type="number"
                min={minYearValue}
                max={maxYearValue}
                value={yearHigh?.toString() ?? ''}
                id="date-range-end"
                name="date-range-end"
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none mt-1 block rounded-md border-0 py-[4.5px] px-2 hover:bg-brand-neutral-50 text-brand-neutral-900 ring-1 ring-inset ring-brand-neutral-300 focus:ring-2 focus:ring-brand-neutral-600 text-sm"
                onChange={handleCustomRangeInput(YearType.HIGH)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        )}
      </div>
      {formError && <div className="mt-1 ml-1 text-red-700 text-xs">{formError}</div>}
    </div>
  )
}
