import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import RenderReactPdfViewer from '@/components/react-pdf-viewer/render-react-pdf-viewer'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectVisibleReference, selectVisibleReferenceRenderTrigger } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewPdfProps = {
  conversationId: string
}

/**
 * Reference View - React PDF Viewer
 * This is a situation specific wrapper for the generic RenderReactPdfViewer component.
 *
 * @param props
 * @returns {JSX.Element}
 */
export default function ReferenceViewPdf(props: ReferenceViewPdfProps): JSX.Element {
  const { conversationId } = props
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)

  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))
  const renderTrigger = useAppSelector((state: RootState) => selectVisibleReferenceRenderTrigger(state, { chatId: conversationId }))

  // Local state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pdfFile, setPdfFile] = useState<File | null>(null)

  // Fetch the PDF file from GCS
  async function fetchPdfFromGcs(reference: ChatV2MessageReferenceType) {
    // Reset state
    setLoading(false)
    setError(false)
    setPdfFile(null)

    // Get the path from the reference
    const path = reference.metadata.storage_ref_full_path || reference.metadata.storage_path
    if (typeof path !== 'string') {
      Sentry.captureException(new Error('Ref metadata storage path is not a string'), { extra: { referenceMetadata: JSON.stringify(reference.metadata) } })
      setError(true)
      return
    }

    // Load the PDF file from Firebase storage
    try {
      setLoading(true)

      // Fetch the md
      const storageRef = ref(storage, path)
      const blob: Blob = await getBlob(storageRef)
      const blobType = blob.type

      // Make sure it's a PDF file
      if (blobType !== 'application/pdf') {
        Sentry.captureException(new Error('PDF Reference Blob is not application/pdf'), { extra: { referenceMetadata: JSON.stringify(reference.metadata) } })
        setError(true)
        return
      }

      // Set the PDF file
      setPdfFile(blob as File)
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  // Fetch thePDF whenever the reference changes
  useEffect(() => {
    if (!reference) return

    // Call the function to load and set the HTML content
    fetchPdfFromGcs(reference)
  }, [reference, renderTrigger])

  // Render loading
  if (loading) {
    return (
      <div className={'p-2'}>
        <CircularProgressContinuousSized size={12} thickness={7} />
      </div>
    )
  }

  // Render error
  if (error || !pdfFile || !reference) {
    return <div className={'p-2'}>Error loading PDF</div>
  }

  return <RenderReactPdfViewer pdfFile={pdfFile} highlightAreas={reference.relevant_highlights ?? []} />
}
