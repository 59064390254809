import { useRef } from 'react'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatWindow from '@/chat-common/components/chat-window'
import ErrorComponent from '@/components/error/error-component'
import { useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReferenceViewerColumn from '@/chat-common/components/reference-viewer/reference-viewer-column'
import ReferenceViewerSlideover from '@/chat-common/components/reference-viewer/reference-viewer-slideover'
import { RootState } from '@/store/store'
import debounce from 'lodash.debounce'
import { isPendingConversationId } from '@/constants/constants-ui'
import {
  selectConversationExists,
  selectConversationVisibleReferenceExists,
  selectVisibleReferenceUsesPDFRenderer,
} from '@/chat-common/store/chat-v2.selectors'
import { getScreenSize, ScreenSize } from '@/util/screen-size'
import { useStartNewChat } from '@/chat-common/hooks/use-start-new-chat'
import { CircularProgress } from '@mui/material'

/**
 * Chat V2 Page
 * Main templating page for Chat
 * @returns
 */
export default function ChatV2Page() {
  const { chatFeature, chatId } = useParams()
  const navigate = useNavigate()
  const { startNewChat } = useStartNewChat()

  // Column width ref
  const referenceColumnRef = useRef<HTMLDivElement | null>(null)

  // Screen Size enum can be used for conditional rendering and styles
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize())

  // Check if this is a valid feature
  const validFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Derive whether this is a pending conversation
  const isPendingConversation = isPendingConversationId(chatId)

  // Watch the custom selector that only updates when the boolean value of the conversation existing or not changes
  const conversationExists = useAppSelector((state: RootState) => selectConversationExists(state, { chatId }))

  // Watch for a new conversation trigger in the state (pending -> server conversation migration) (will trigger some UX actions / redirects)
  const newConversationTrigger = useAppSelector((state: RootState) => state.chatV2State.newConversationIdUXTrigger)

  // Visible Reference
  const conversationHasVisibleReference = useAppSelector((state: RootState) => selectConversationVisibleReferenceExists(state, { chatId }))
  const referenceUsesPdfRenderer = useAppSelector((state: RootState) => selectVisibleReferenceUsesPDFRenderer(state, { chatId }))

  // SCREEN RESIZE HANDLER
  // Functions that should run every time the screen size changes
  // Debounced to reduce the number of calls on resize
  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      // Set the screen size state
      setScreenSize(getScreenSize())
    }, 50)

    window.addEventListener('resize', debouncedHandleResize)

    debouncedHandleResize() // Initial calculation

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      debouncedHandleResize.cancel() // Cancel any pending debounced calls
    }
  }, [])

  // Automatically start a new chat if there's no chatId
  useEffect(() => {
    if (!chatId && validFeature && chatFeature) {
      startNewChat(chatFeature as ChatV2Feature)
    }
  }, [chatId, validFeature, chatFeature, startNewChat])

  /**
   * REDIRECT ON REFRESH
   * - CASE: This is a Pending Conversation After Refresh
   *
   * After refresh, the pending conversation id will only exist inside the URL. Not in state.
   * IF:
   * - There is a pending conversation id in the url
   * - AND this conversation does not exist in state
   * THEN: redirect to remove the pending conversation id from the url
   */
  useEffect(() => {
    if (!chatId || !isPendingConversation) return
    if (!validFeature || !chatFeature) return

    // If it exists in state, return early because we don't need to redirect because the user just created it (not post refresh)
    if (conversationExists) return

    // Else, it's not in state.
    // Check if a pending conversation id is in the url and redirect
    if (isPendingConversation) {
      navigate(`/dashboard/chat/${chatFeature}`)
    }
  })

  /**
   * REDIRECT
   * - Redirect from viewed pending conversation to new received conversation
   * - For when the server returns the official conversation and ID for what we started as a pending conversation client-side
   *
   * When a new conversation is detected in state...
   * - IF the new conversation is for this feature
   * - IF the new conversation has a matching fromPendingId value of the current screen
   * - THEN: Navigate to the new conversation id
   */
  useEffect(() => {
    if (!newConversationTrigger || !chatId || !validFeature) return

    // Check if the new conversation is for this feature
    if (newConversationTrigger.feature !== chatFeature) {
      return
    }

    // Check if the new conversation has a matching fromPendingId value of the current pending conversation
    if (newConversationTrigger.fromPendingId === chatId) {
      navigate(`/dashboard/chat/${chatFeature}/${newConversationTrigger.id}`)
    }
  }, [navigate, newConversationTrigger])

  // IF INVALID FEATURE: show 404 error
  if (!validFeature || !chatFeature) {
    console.error(`Invalid chat. Feature: ${chatFeature}, ID: ${chatId}`)

    // Return 404 error component
    return <ErrorComponent code={'404'} title={'Not Found'} message={"We can't find what you're looking for."} />
  }

  // If there's no chatId yet, return a loading indicator while a new chat is being created
  if (!chatId) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <CircularProgress size={24} thickness={5} />
      </div>
    )
  }

  // Whether to show a wider source area (for PDF renderer)
  const wideSourcePanel = referenceUsesPdfRenderer && screenSize != ScreenSize.LARGE

  return (
    <>
      {/* Display 1 or 2 columns depending on whether showReference is true and screen size */}
      {/* PDF Renderer uses 2/5 : 3/5 split, otherwise 1/2 : 1/2 */}
      <div className={`flex flex-grow gap-x-2`}>
        <div className={`${wideSourcePanel ? 'w-2/5' : 'w-1/2'} mt-12 lg:mt-0 mx-2 lg:mx-0 flex flex-grow justify-center`}>
          <ChatWindow chatId={chatId} chatFeature={chatFeature as ChatV2Feature} />
        </div>

        {/* Source view as second column - large screens */}
        {conversationHasVisibleReference && screenSize != ScreenSize.SMALL && (
          <div ref={referenceColumnRef} className={`${wideSourcePanel ? 'w-3/5' : 'w-1/2'} mt-12 lg:mt-0 flex flex-grow overflow-hidden`}>
            <ReferenceViewerColumn conversationId={chatId} />
          </div>
        )}

        {/* Source view as slideover - small screens */}
        {conversationExists && screenSize === ScreenSize.SMALL && <ReferenceViewerSlideover open={conversationHasVisibleReference} conversationId={chatId} />}
      </div>
    </>
  )
}
