import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { chatV2CurrentSourceAssistantAddFile, chatV2CurrentSourceAssistantClearAllFiles, chatV2CurrentSourceAssistantToggleSelectedFile } from '@/chat-common/store/chat-v2.slice'
import { setSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.slice'
import { SourceSelectionDialog } from './source-selection-dialog'
import { selectConversationFeature } from '@/chat-common/store/chat-v2.selectors'


type AssistantDocumentSelectionDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * AssistantDocumentSelectionDialog component.
 *
 * This component is used exclusively in the Assistant features
 * to allow the user to select documents for the Assistant to use by wrapping the SourceSelectionDialog component with the appropriate dispatch functions.
 *
 * @param props - The properties for the AssistantDocumentSelectionDialog component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function AssistantDocumentSelectionDialog(props: AssistantDocumentSelectionDialogProps) {
  const { open, onClose, conversationId } = props
  const dispatch = useAppDispatch()

  const featureName = useAppSelector((state) => selectConversationFeature(state, { chatId: conversationId }))

  const toggleFileSelection = (file: string) => {
    dispatch(setSelectedFilesContentExpanded({ conversationId: conversationId, expanded: true }))
    dispatch(chatV2CurrentSourceAssistantToggleSelectedFile({ conversationId: conversationId, filePath: file }))
  }

  const addFileSelection = (file: string) => {
    dispatch(setSelectedFilesContentExpanded({ conversationId: conversationId, expanded: true }))
    dispatch(chatV2CurrentSourceAssistantAddFile({ conversationId: conversationId, filePath: file }))
  }

  const clearFilesSelection = () => {
    dispatch(chatV2CurrentSourceAssistantClearAllFiles({ conversationId: conversationId }))
  }

  return (
    <SourceSelectionDialog
      conversationId={conversationId}
      open={open}
      onClose={onClose}
      featureName={featureName}
      dispatchFunctions={{
        addFileSelection,
        clearFilesSelection,
        toggleFileSelection,
      }}
    />
  )
}
