import * as Sentry from '@sentry/react'
import { ErrorEvent } from '@sentry/types/types/event'

/**
 * Analyze whether this is a Segment Error Sentry Event
 * Example Segment error arguments array:
 
  [
    Error sending segment performance metrics,
    {
      message: Failed to fetch,
      name: TypeError,
      stack: 
      TypeError: Failed to fetch
      ...
    }
  ]

  PRIMARY ANALYSIS: Whether the first argument includes 'Error sending segment'

 * @param {ErrorEvent} event is a Sentry Event type of event ('@sentry/types/types/event')
 * @returns {boolean} whether this is a Segment Error Sentry Event
 */
export function isSegmentErrorEvent(event: ErrorEvent): boolean {
  let isSegmentError = false

  // Get the breadcrumbs
  const breadcrumbs = event.breadcrumbs

  // Filter for category: "console" breadcrumbs
  const consoleBreadcrumbs = breadcrumbs?.filter((breadcrumb) => breadcrumb.category === 'console')

  // If there are no console breadcrumbs, skip
  if (!consoleBreadcrumbs) {
    return isSegmentError
  }

  // For each console breadcrumb
  for (const breadcrumb of consoleBreadcrumbs) {
    // Get the arguments
    const args = breadcrumb.data?.arguments as Array<any> | undefined

    // If there are no arguments, skip
    if (!args) {
      continue
    }

    // Get the first argument
    const firstArg = args[0]

    // If the first argument is not a string, skip
    if (typeof firstArg !== 'string') {
      continue
    }

    // Check if the first argument is a Segment error
    if (firstArg.includes('Error sending segment')) {
      isSegmentError = true
      break
    }
  }

  // TEMPORARY:
  // Log to sentry that we found a segment error
  if (isSegmentError) {
    console.log('Filtering out segment error from reporting.')
    Sentry.captureMessage('Filtering out Segment Error Sentry Event', { extra: { event, eventJson: JSON.stringify(event) } })
  }

  return isSegmentError
}
