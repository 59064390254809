import { kGcsUserFileUploadsBucketRef, kHiddenFileForFolderName } from '@/constants/constants-gcs'
import { FullMetadata, getMetadata, getStorage, listAll, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

export type FileNode = {
    id: string
    name: string
    isFolder: boolean
    isLoading: boolean
    isExpanded?: boolean
    children?: FileNode[]
    metadata?: FullMetadata
}

type UseProcessedFilesProps = {
    path: string
    selectOnLoad?: boolean
}

async function getFolderContents(path: string): Promise<FileNode[]> {
    const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
    const listRef = ref(storage, path)

    // Fetch immediate children of the directory
    const response = await listAll(listRef)
    let files: FileNode[] = []

    //check if response contains any items or prefixes early to avoid unnecessary processing
    if (response.items.length === 0 && response.prefixes.length === 0) {
        return files
    }

    //Collect metadata promises, pairing each promise with the itemRef
    const metadataPromises = response.items.map(async (itemRef) => ({
        itemRef,
        metadata: await getMetadata(itemRef),
    }))

    //Wait for all metadata to be retrieved
    const resolvedMetadata = await Promise.all(metadataPromises)

    // Step 3: Build the files array using the resolved metadata
    files = resolvedMetadata.map(({ itemRef, metadata }) => ({
        id: itemRef.fullPath,
        name: itemRef.name,
        isFolder: false,
        isLoading: false,
        metadata,
    }))

    // Process subdirectories (without fetching metadata)
    for (const folderRef of response.prefixes) {
        files.push({
            id: folderRef.fullPath,
            name: folderRef.name,
            isFolder: true,
            isExpanded: false,
            isLoading: false,
            children: [], // Empty array to indicate that the folder is not expanded
        })
    }

    return files
}

/**
 * Hook to get all the processed files from the storage using the folders path with uid or organization id
 *
 * @param path - The path of the folder
 * @returns The list of files in the folder
 */
export function useProcessedFiles({ path }: UseProcessedFilesProps) {
    const [files, setFiles] = useState<FileNode[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        let isMounted = true

        async function loadFiles() {
            setLoading(true)
            try {
                //Maybe add a ZOD validation here in case the value we get from FB ever changes data that could break the app.
                const responseFiles = await getFolderContents(path)

                // Order folders first
                responseFiles.sort((a, b) => (a.isFolder === b.isFolder ? 0 : a.isFolder ? -1 : 1))

                if (isMounted) {
                    setFiles(responseFiles.filter((f) => !f.name.endsWith(kHiddenFileForFolderName)))
                }
            } catch (error) {
                Sentry.captureException(new Error('Failed to load files'), {
                    extra: { path, error: JSON.stringify(error) },
                })
            } finally {
                if (isMounted) {
                    setLoading(false)
                }
            }
        }

        loadFiles()

        return () => {
            isMounted = false // Cleanup to prevent setting state on unmounted component
        }
    }, [path])

    const expandFolder = async (folder: FileNode) => {
        folder.isLoading = true
        setFiles([...files]) // Trigger re-render

        try {
            const children = await getFolderContents(folder.id)
            folder.children = children
            folder.isExpanded = true
        } catch (error) {
            Sentry.captureException(new Error('Failed to load folder contents'), {
                extra: { path: folder.id, error: JSON.stringify(error) },
            })
        } finally {
            folder.isLoading = false
            setFiles([...files]) // Update state
        }
    }

    return { loading, files, expandFolder }
}
