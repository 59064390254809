import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuthContext } from '../../context/auth-context'
import { authErrorTranslate } from '../../firebase/auth/auth-error-translate'
import { kLinkPrivacyPolicyUrl, kLinkTermsOfServiceUrl } from '@/constants/constants-links'
import { PaxtonDisclaimer } from '@/constants/constants-components'
import googleAuthRedirect from '../../firebase/auth/google-auth-redirect'
// import azureAuthRedirect from '../../firebase/auth/azure-auth-redirect'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import ReCAPTCHA from 'react-google-recaptcha'
import { EmailAuthProvider } from 'firebase/auth'
import InlineTipError from '../inline-tips/InlineTipError'
import activateReferral from '@/referral/fetch/post-activate-referral'
import { useAppDispatch } from '@/store/store-hooks'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import * as Sentry from '@sentry/browser'
import { removeReferralCode, retrieveReferralCode, validateReferralCodeAndUserAccount } from '@/referral/referral-utils'
import ValidationError from '@/util/errors/validation-error'
import ReferralInviteMessage from '@/referral/views/referral-invite-message'
import { brandedAIFriendlyName } from '@/util/enterprise'

type FormInputs = {
  email: string
  password: string
}

type SignUpFormProps = {
  switchToSignIn: () => void
  onSuccess: () => void
  tooltipMessage?: string
  title?: string
  allowReferral?: boolean
}

export default function SignUpForm(props: SignUpFormProps) {
  const { switchToSignIn, onSuccess, tooltipMessage, title, allowReferral } = props

  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const user = authContext?.user

  // Redirect if the user is not null
  useEffect(() => {
    if (user != null && !user.isAnonymous) {
      onSuccess()
    }
  }, [user])

  // Local State
  const [validationError, setValidationError] = useState('')
  const [loading, setLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState(null)
  const [referralCodeError, setReferralCodeError] = useState('')
  const [referralCode, setReferralCode] = useState<string | null>(null)

  // Initialize referral code state
  useEffect(() => {
    if (allowReferral) {
      const { code, error } = retrieveReferralCode()

      if (code) {
        setReferralCode(code)
      }
      if (error) {
        setReferralCodeError(error)
      }
    }
  }, [allowReferral])

  // Handle recaptcha change
  function onRecaptchaChange(value: any) {
    setRecaptcha(value)
  }

  // Form Hook
  const { register, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  // Sign in error component
  function ValidationErrorMessage() {
    if (validationError != '') {
      return (
        <div>
          <p className="text-center text-red-700">{validationError}</p>
        </div>
      )
    } else {
      return null
    }
  }

  async function handleReferralActivation(referralCode: string) {
    const userAccountData = authContext?.userAccountData

    try {
      validateReferralCodeAndUserAccount(referralCode, userAccountData)

      await activateReferral(referralCode)

      const successMessage = 'Referral activated!'
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: successMessage, durationMs: 2000 }))
    } catch (e: any) {
      console.error('Referral activation failed: ', e.message)

      const errorMessage = `Account created, but the referral could not be activated. ${brandedAIFriendlyName} has been notified.`
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: errorMessage, durationMs: 3000 }))

      if (e instanceof ValidationError) {
        // Capture validation errors in Sentry, other errors are already captured in activateReferral call
        Sentry.captureException(e, {
          extra: {
            referralCode,
            subscriptionStatus: userAccountData?.subscriptionStatus,
            isOrgAccount: userAccountData?.isOrgAccount,
            brand: userAccountData?.brand,
          },
        })
      }
    } finally {
      removeReferralCode()
    }
  }

  // Handle Sign Up
  async function handleSignUp(data: FormInputs) {
    setValidationError('')
    setLoading(true)

    console.log('Recaptcha response: ', recaptcha)
    if (recaptcha == null) {
      setValidationError('Please complete the recaptcha')
      setLoading(false)
      return
    }

    try {
      // await signUpEmail(data.email, data.password)
      console.log('Creating account for anonymous user: ', user)

      // Create the email / pass credential for this user
      const credential = EmailAuthProvider.credential(data.email, data.password)

      // Link the credential and update the AuthContext
      await authContext?.linkCredential(credential)

      // Only trigger referral activation if referrals are allowed and referral code is present
      if (allowReferral && referralCode) {
        await handleReferralActivation(referralCode)
      }

      // onSuccess
      onSuccess()
    } catch (e: any) {
      const validationError = await authErrorTranslate(e, data.email)
      setValidationError(validationError)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {tooltipMessage && <div className={'mb-4'}>{InlineTipError(tooltipMessage)}</div>}
      <h2 className="mb-4 text-center text-2xl font-bold tracking-tight text-brand-neutral-900">{title ?? 'Start your free trial'}</h2>
      <form className="space-y-6" method="POST" onSubmit={handleSubmit((data) => handleSignUp(data))}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Work email address
          </label>
          <div className="mt-2">
            <input
              id="email-address"
              {...register('email', { required: true })}
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Work email address"
            />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Create a password <span className={'text-xs font-normal hidden'}>(not your access code)</span>
          </label>
          <div className="mt-2">
            <input
              id="password"
              {...register('password', { required: true })}
              type="password"
              autoComplete="current-password"
              required
              minLength={6}
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Password"
            />
          </div>
        </div>

        <div className={'g-recaptcha'} data-sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}></div>

        <ReCAPTCHA sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY} onChange={onRecaptchaChange} />

        {allowReferral && referralCode && (
          <div className={'mb-4'}>
            <ReferralInviteMessage />
          </div>
        )}
        {allowReferral && referralCodeError && <div className={'mb-4'}>{InlineTipError(referralCodeError)}</div>}

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-brand-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
          >
            Sign up
            <ButtonLoader loading={loading} />
          </button>
        </div>

        <ValidationErrorMessage />
      </form>

      <p className="mt-5 text-center text-sm text-brand-neutral-500">
        Already have an account?{' '}
        <button onClick={switchToSignIn} className="font-semibold leading-6 text-brand-500 hover:text-brand-400">
          Sign in
        </button>
      </p>

      <div>
        <div className="relative mt-5">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-brand-neutral-200" />
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-brand-neutral-50 px-6 text-brand-neutral-900">Or continue with</span>
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-4">
          <button
            onClick={() => googleAuthRedirect()}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#ffffff] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] border border-slate-200 hover:shadow transition duration-150"
          >
            <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
            <span className="text-sm font-semibold leading-6 text-black">Google</span>
          </button>
        </div>

        {/* <div className="mt-2 grid grid-cols-1 gap-4">
          <button
            onClick={() => azureAuthRedirect()}
            className="flex w-full items-center justify-center gap-3 rounded-md bg-[#ffffff] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] border border-slate-200 hover:shadow transition duration-150"
          >
            <img className="w-6 h-6" src="https://www.svgrepo.com/show/349454/microsoft.svg" loading="lazy" alt="microsoft logo" />
            <span className="text-sm font-semibold leading-6 text-black">Microsoft</span>
          </button>
        </div> */}

        <div className="mt-5 text-xs text-center">
          By creating an account, you agree to our{' '}
          <a href={kLinkTermsOfServiceUrl} target="_blank">
            <span className="underline whitespace-nowrap">terms of service</span>
          </a>
          &nbsp;and&nbsp;
          <a href={kLinkPrivacyPolicyUrl} target="_blank">
            <span className="underline whitespace-nowrap">privacy policy</span>
          </a>
          .
        </div>
        <div className="mt-5 text-xs text-center">
          <PaxtonDisclaimer />
        </div>
      </div>
    </>
  )
}
