import { CircularProgressContinuous, CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kGcsOrgHiddenFolder, kGcsUserFileUploadsBucketRef, kHiddenFileForFolderName } from '@/constants/constants-gcs'
import { Article, CreateNewFolder, Folder, KeyboardArrowRight } from '@mui/icons-material'
import { FullMetadata, StorageReference, deleteObject, getMetadata, getStorage, listAll, ref, uploadBytes, list } from 'firebase/storage'
import { useCallback, useEffect, useState } from 'react'
import CreateFolderDialog from './create-folder-components/CreateFolderDialog'
import { kSegmentTrackFolderCreation } from '@/constants/constants-segment'
import { Cross2Icon, TrashIcon } from '@radix-ui/react-icons'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate } from 'react-router-dom'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { useAppDispatch } from '@/store/store-hooks'
import { fileUploadIsProcessing, downloadFile, copyFile } from './files-utils'
import RenameFileDialog from './dialogs/rename-file'
import DeleteConfirmDialog from './dialogs/files-delete-confirm'
import FileDeleteConfirmDialog from './dialogs/single-file-delete-confirm'
import FilesActionsMenu from './FilesActionsMenu'

type FilesListProps = {
  reloadTrigger: number
  currentFolderRef: StorageReference
}

export default function FilesList(props: FilesListProps) {
  const { currentFolderRef, reloadTrigger } = props
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // Derived Values
  // Base Drive Location is the first 4 segments of the URI (/dashboard/drives/drive-type/...) (first segment before first / is empty)
  const baseDriveLocationPath = location.pathname.split('/').slice(0, 4).join('/')
  const driveNameFromPath = location.pathname.split('/').slice(0, 4).pop()
  const driveNameLabel = driveNameFromPath == 'org-drive' ? 'Shared Drive' : 'My Drive'

  // // Drive root path keeps the first two parts of the path
  // const storageRefDriveRootPath = currentFolderRef.fullPath.split('/').slice(0, 2).join('/')

  // Storage Ref Path For URI removes the first two parts of the path which are the root location for the drive
  function storageRefPathForUri(storageRef: StorageReference): string {
    const storagePath = storageRef.fullPath.split('/').slice(2).join('/')
    return encodeURIComponent(storagePath)
  }
  const storageRefSubFolderPath = storageRefPathForUri(currentFolderRef)
  const atDriveRoot = storageRefSubFolderPath == ''

  // State
  const [files, setFiles] = useState<FullMetadata[]>([])
  const [folders, setFolders] = useState<StorageReference[]>([])
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState<boolean>(false)
  const [openRenameFileDialog, setOpenRenameFileDialog] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<FullMetadata>()
  const [loading, setLoading] = useState<boolean>(false)

  // State - file and folder checkbox status
  // Folder - checked if all files are checked
  // File - checked if it is in the selectedFiles array
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [selectedFolders, setSelectedFolders] = useState<string[]>([])
  const allFilesAndFoldersSelected = selectedFiles.length == files.length && selectedFolders.length == folders.length
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [showSingleDeleteDialog, setShowSingleDeleteDialog] = useState<boolean>(false)

  // *******************************************
  // Get Files And Folders
  // Cached between renders with useCallback
  const getFilesAndFolders = useCallback(
    async (props: { changeLoadingState: boolean; storageRef: StorageReference }) => {
      const { changeLoadingState, storageRef } = props

      if (changeLoadingState) setLoading(true)

      try {
        // Get all the files
        const response = await listAll(storageRef)

        // Filter out hidden folders
        const filteredFolders = response.prefixes.filter((folder) => folder.name != kGcsOrgHiddenFolder)
        setFolders(filteredFolders)

        // Get the metadata for each file
        const metadataPromises: Promise<FullMetadata>[] = []
        response.items.forEach((itemRef) => {
          // Skip hidden files
          if (itemRef.name == kHiddenFileForFolderName) {
            return
          }
          metadataPromises.push(getMetadata(itemRef))
        })

        // Wait for all the metadata to be retrieved
        const metadataResults = await Promise.all(metadataPromises)

        // Update state
        setFiles(metadataResults)
      } catch (e) {
        console.error('Error getting files: ', e)
      } finally {
        setLoading(false)
      }
    },
    [setFiles, setFolders, setLoading]
  )

  // Refresh the file list when the current folder changes + deselect all items from previous path
  useEffect(() => {
    getFilesAndFolders({ changeLoadingState: true, storageRef: currentFolderRef })
    clearAllFilesAndFolders()
  }, [currentFolderRef, reloadTrigger, getFilesAndFolders])

  // *******************************************
  // Polling refresh if files are processing
  // Poll every 5 seconds if the metadata from any file indicates processing status
  useEffect(() => {
    // If there are no files, return
    if (files.length == 0) return

    // If there are no files processing, return
    const someProcessing = files.some((file) => fileUploadIsProcessing(file))
    if (!someProcessing) return

    // Poll every 5 seconds
    const interval = setInterval(() => {
      console.log('Polling for file processing status...')
      getFilesAndFolders({ changeLoadingState: false, storageRef: currentFolderRef })
    }, 5000)

    // Cleanup
    return () => clearInterval(interval)
  })

  // *******************************************
  // Handle checkbox change for files
  function handleFileCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target

    // Add or remove the file from the selected files array
    if (checked) {
      setSelectedFiles([...selectedFiles, value])
    } else {
      setSelectedFiles(selectedFiles.filter((file) => file != value))
    }
  }

  function handleFolderCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target

    // Add or remove the folder from the selected folders array
    if (checked) {
      setSelectedFolders([...selectedFolders, value])
    } else {
      setSelectedFolders(selectedFolders.filter((folder) => folder != value))
    }
  }

  //allFilesSelected ? clearSelectedFiles() : selectAllFiles()
  function clearAllFilesAndFolders() {
    clearSelectedFiles()
    clearSelectedFolders()
  }

  function selectAllFilesAndFolders() {
    selectAllFiles()
    selectAllFolders()
  }

  function clearSelectedFolders() {
    setSelectedFolders([])
  }

  function selectAllFolders() {
    const allFolders = folders.map((folder) => folder.fullPath)
    setSelectedFolders(allFolders)
  }

  // Clear selected files
  function clearSelectedFiles() {
    setSelectedFiles([])
  }

  // Select all files
  function selectAllFiles() {
    const allFiles = files.map((file) => file.fullPath)
    setSelectedFiles(allFiles)
  }

  function displayDirectoryBreadcrumbs() {
    // Decode URL path
    const storagePath = decodeURIComponent(storageRefSubFolderPath)
    const pathArray = storagePath.split('/')

    return pathArray.map((folderName, index) => {
      const lastFolder = index == pathArray.length - 1
      // const folderFullPath = `${storageRefDriveRootPath}/${pathArray.slice(0, index + 1).join('/')}`
      return (
        <div key={index} className={'inline-block text-sm'}>
          <div
            onClick={() => {
              if (lastFolder != null) {
                clearSelectedFiles()
              }
            }}
            className={`font-semibold leading-6 text-brand-neutral-900 ${lastFolder ? '' : 'underline text-brand-800 cursor-pointer'}`}
          >
            {folderName}
          </div>
          {/* This can be put back after we allow nested directories */}
          {/* <div className={'inline-block text-base font-semibold leading-6 text-brand-neutral-900'}>
            <KeyboardArrowRight />
          </div> */}
        </div>
      )
    })
  }

  // Function to rename a file
  async function handleRename(newName: string): Promise<void> {
    if (!activeItem) {
      console.error('No active item selected to rename')
      return
    }

    try {
      //TODO: Improve this method by creating a state for active item
      //Check if input value contains extension
      const hasExtension = /\.\w+$/.test(newName)
      const originalExtension = activeItem.fullPath.split('.')[1]
      if (originalExtension.length <= 1) throw new Error() //Error out in case we can't find a original file extension

      const newNameWithExtension = hasExtension ? newName : newName + `.${originalExtension}`

      const newPath = activeItem.fullPath.replace(/[^/]+$/, newNameWithExtension)

      await copyFile(activeItem.fullPath, newPath, storage)
      await deleteObject(ref(storage, activeItem.fullPath))

      setOpenRenameFileDialog(false)
      // Refresh the file list
      getFilesAndFolders({ changeLoadingState: false, storageRef: currentFolderRef })
    } catch (error) {
      setOpenRenameFileDialog(false)
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: `Unable to rename the file ${activeItem.name}`, durationMs: 2000 }))
      console.error('Error renaming file:', error)
    }
  }

  // Handle Delete Confirmation
  async function handleDeleteFolder(folder: string, deletePromises: Promise<void>[]) {
    const allFilesRef = ref(storage, folder)
    const allFilesResponse = await list(allFilesRef)

    // Directly delete any files in the folder
    allFilesResponse.items.forEach((file) => {
      deletePromises.push(deleteObject(file))
    })

    // Recursively delete subfolders
    for (const prefix of allFilesResponse.prefixes) {
      await handleDeleteFolder(prefix.fullPath, deletePromises)
    }

    return
  }

  // Handle Delete Confirmation
  async function handleDeleteFile() {
    if (!activeItem) {
      console.error('No active item selected to delete')
      return
    }

    // Delete the file
    const fileRef = ref(storage, activeItem.fullPath)
    await deleteObject(fileRef)
    dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `${activeItem.name} deleted successfully.`, durationMs: 2000 }))

    getFilesAndFolders({ changeLoadingState: false, storageRef: currentFolderRef })
  }

  async function handleDeleteConfirm() {
    console.log('Deleting files: ', selectedFiles)

    // Delete the files
    const deletePromises: Promise<void>[] = []
    selectedFiles.forEach((file) => {
      const fileRef = ref(storage, file)
      deletePromises.push(deleteObject(fileRef))
    })

    for (const folder of selectedFolders) {
      await handleDeleteFolder(folder, deletePromises)
    }

    // Wait for all the files to be deleted
    try {
      await Promise.all(deletePromises)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `${deletePromises.length} items deleted successfully.`, durationMs: 2000 }))
    } catch (e) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: `Deletion action failed, please refresh and try again.`, durationMs: 2000 }))
      throw e
    } finally {
      // Clear the selected files
      clearAllFilesAndFolders()
      // Refresh the file list
      getFilesAndFolders({ changeLoadingState: false, storageRef: currentFolderRef })
    }
  }

  async function handleFileDownload(file: FullMetadata) {
    try {
      await downloadFile(file.fullPath, file.name)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: file.name + ' downloaded', durationMs: 2000 }))
    } catch (e) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: file.name + ' download failed', durationMs: 2000 }))
      throw e
    }
  }

  if (files.length == 0 && folders.length == 0 && loading) return <CircularProgressContinuous />

  const fileFiles = selectedFiles.length == 1 ? 'file' : 'files'
  const folderFolders = selectedFolders.length == 1 ? 'folder' : 'folders'
  const toolbarDisabled = selectedFiles.length + selectedFolders.length == 0

  return (
    <div>
      {/* Actions Dialog */}
      <DeleteConfirmDialog
        title={selectedFiles.length > 1 || selectedFolders.length > 1 ? 'Delete Files Permanently?' : 'Delete File Permanently?'}
        fileMessage={selectedFiles.length > 0 ? `${selectedFiles.length} ${fileFiles} will be deleted forever.` : null}
        folderMessage={selectedFolders.length > 0 ? `${selectedFolders.length} ${folderFolders} will be deleted forever.` : null}
        onConfirm={handleDeleteConfirm}
        closeDialog={() => setShowDeleteDialog(false)}
        visible={showDeleteDialog}
      />
      <FileDeleteConfirmDialog
        fileName={activeItem?.name}
        onConfirm={handleDeleteFile}
        closeDialog={() => setShowSingleDeleteDialog(false)}
        visible={showSingleDeleteDialog}
      />
      <RenameFileDialog open={openRenameFileDialog} onSave={(data) => handleRename(data.itemName)} onClose={() => setOpenRenameFileDialog(false)} />
      <CreateFolderDialog
        open={openCreateFolderDialog}
        onSave={async (data) => {
          const { folderName } = data

          // Track
          analytics.track(kSegmentTrackFolderCreation)

          // Create the folder by creating a hidden file at its location
          const hiddenFilePath = `${currentFolderRef.fullPath}/${folderName}/${kHiddenFileForFolderName}`
          const hiddenFileRef = ref(storage, hiddenFilePath)
          const emptyContent = new Blob([]) // Create an empty blob

          // Upload the empty blob as a hidden file
          await uploadBytes(hiddenFileRef, emptyContent)

          // Close the dialog
          setOpenCreateFolderDialog(false)

          // Refresh the file list
          getFilesAndFolders({ changeLoadingState: false, storageRef: currentFolderRef })
        }}
        onClose={() => setOpenCreateFolderDialog(false)}
      />

      {/* Drive Path */}
      <div className={'px-4 pt-4 mb-1'}>
        <div
          onClick={() => navigate(baseDriveLocationPath)}
          className={
            'inline-block font-semibold leading-6 text-brand-neutral-900 text-sm ' + (currentFolderRef != null ? 'underline text-brand-800 cursor-pointer' : '')
          }
        >
          {driveNameLabel}
        </div>
        {atDriveRoot && (
          <>
            <div className={'inline-block text-base font-semibold leading-6 text-brand-neutral-900'}>
              <KeyboardArrowRight />
            </div>
            <button onClick={() => setOpenCreateFolderDialog(true)} className="i w-auto rounded-md text-sm px-0 font-semibold text-brand-500">
              <CreateNewFolder fontSize="small" className={'pb-[2px]'} /> <span className={'inline-block'}>New Folder</span>
            </button>
          </>
        )}
        {!atDriveRoot && (
          <>
            <div className={'inline-block text-base font-semibold leading-6 text-brand-neutral-900'}>
              <KeyboardArrowRight />
            </div>
            {displayDirectoryBreadcrumbs()}
            {/* We can put this in place after we allow nested directories */}
            {/* <button onClick={() => setOpenCreateFolderDialog(true)} className="i w-auto rounded-md text-sm px-0 font-semibold text-brand-500">
              <CreateNewFolder fontSize="small" className={'pb-[2px]'} /> <span className={'inline-block'}>New Folder</span>
            </button> */}
          </>
        )}

        {/* <div className="mt-2 pb-2 text-sm text-brand-neutral-500">
          <p className={'text-xs'}>
            Available for{' '}
            <Link className={'text-brand-500 underline'} to={'/dashboard/chat/documentquery'}>
              analysis
            </Link>{' '}
            when processing is complete. Uploading many large files at once may extend processing times.
          </p>
        </div> */}
      </div>

      {/* Toolbar */}
      <div className={`px-2 mb-1 flex gap-x-2 ${!toolbarDisabled ? 'opacity-100' : 'opacity-10'} transition-all duration-200 ease-in-out`}>
        <button
          onClick={clearAllFilesAndFolders}
          disabled={toolbarDisabled}
          className={`flex gap-x-1 px-2 py-1 pr-3 items-center rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
            !toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <Cross2Icon width={20} height={20} />
          <div className={'text-sm'}>{selectedFiles.length + selectedFolders.length} selected</div>
        </button>

        <button
          onClick={() => {
            setShowDeleteDialog(true)
          }}
          disabled={toolbarDisabled}
          className={`flex gap-x-2 px-2 py-1 pr-3  rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${
            !toolbarDisabled ? 'cursor-pointer' : 'cursor-default'
          }`}
        >
          <TrashIcon width={20} height={20} />
          <div className={'text-sm'}>Delete</div>
        </button>
      </div>
      {/* Files table header */}
      <div
        className={'p-2 grid grid-cols-[19px_100%_0px_0px] sm:grid-cols-[19px_auto_166px_0px] md:grid-cols-[19px_auto_175px_82px] items-end overflow-hidden'}
      >
        <div>
          <input
            type="checkbox"
            checked={allFilesAndFoldersSelected}
            className={`w-4 h-4 ${allFilesAndFoldersSelected ? 'opacity-100' : 'opacity-30'} hover:opacity-100 transition-all ease-in-out duration-200`}
            onChange={() => (allFilesAndFoldersSelected ? clearAllFilesAndFolders() : selectAllFilesAndFolders())}
            value={'test'}
          />
        </div>
        <p className={'text-sm px-2'}>Name</p>
        <p className={'text-sm px-2 hidden sm:block'}>Last modified</p>
        <p className={'text-sm px-2 hidden md:block'}>File size</p>
      </div>
      <div>
        {/* List Folders */}
        {folders.map((folder) => {
          const isChecked = selectedFolders.includes(folder.fullPath)

          const folderNavPath = `${baseDriveLocationPath}/${storageRefPathForUri(folder)}`

          return (
            <div
              key={folder.fullPath}
              className={
                'border-t-[1px] py-2 grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden cursor-pointer hover:bg-brand-neutral-100'
              }
            >
              <div className={'px-2 grid grid-cols-[22px_32px_auto] items-center'}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  className={`w-4 h-4 ${
                    isChecked ? 'opacity-100' : 'opacity-30'
                  } group-hover:opacity-100 transition-all ease-in-out duration-200 checked:bg-brand-500 rounded-sm`}
                  onChange={handleFolderCheckboxChange}
                  value={folder.fullPath}
                />
                <Folder
                  onClick={() => {
                    navigate(folderNavPath)
                    clearAllFilesAndFolders()
                  }}
                />
                <p
                  onClick={() => {
                    navigate(folderNavPath)
                    clearAllFilesAndFolders()
                  }}
                  className={'text-sm overflow-hidden'}
                >
                  {folder.name}
                </p>
              </div>
              <p className={'text-sm px-2 hidden sm:block'}></p>
              <p className={'text-sm px-2 hidden md:block'}></p>
            </div>
          )
        })}

        {files.length + folders.length == 0 && <p className={'text-sm px-4'}>Upload some files to populate this folder.</p>}

        {/* List Files */}
        {files.map((file) => {
          // Format date
          const date: Date = new Date(file.timeCreated)
          const formattedDate = Intl.DateTimeFormat('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short',
          }).format(date)

          // Format file size
          // If the file size is under 1mb, show it in kb
          // If the file size is over 1mb, show it in mb
          let formattedSize = ''
          if (file.size < 1000000) {
            formattedSize = `${Math.round(file.size / 1000)} KB`
          } else {
            formattedSize = `${(file.size / 1000000).toFixed(1)} MB`
          }

          // Mark as processing if the file is not processed and has no error
          const isProcessingError: boolean = typeof file.customMetadata?.error == 'string' && file.customMetadata?.error != ''
          const processing: boolean = fileUploadIsProcessing(file)

          const isChecked = selectedFiles.includes(file.fullPath)

          // Select Icon
          function DynamicIconForStatus() {
            switch (true) {
              case isProcessingError && !processing:
                return <ExclamationCircleIcon height={24} className={'mr-1 text-white bg-red-600 rounded-full'} />

              case processing:
                return (
                  <div className={'h-[24px] w-[24px] pl-[4px] pt-[2px]'}>
                    <CircularProgressContinuousSized size={16} thickness={7} />
                  </div>
                )

              default:
                return <Article />
            }
          }

          return (
            <div
              key={file.fullPath}
              className={
                'group border-t-[1px] py-[6px] grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden  hover:bg-brand-neutral-100'
              }
            >
              <div className={'px-2 grid grid-cols-[22px_30px_auto] items-center'}>
                {/* Microsoft onedrive style file selection */}
                <input
                  type="checkbox"
                  checked={isChecked}
                  className={`w-4 h-4 ${
                    isChecked ? 'opacity-100' : 'opacity-30'
                  } group-hover:opacity-100 transition-all ease-in-out duration-200 checked:bg-brand-500 rounded-sm`}
                  onChange={handleFileCheckboxChange}
                  value={file.fullPath}
                />
                <DynamicIconForStatus />
                <div className={'text-sm overflow-hidden flex items-center'}>
                  <p className={'flex-grow'}>
                    {file.name}
                    {isProcessingError ? <span className={'text-red-700 ml-1'}>Error Processing File</span> : ''}
                  </p>
                  <div>
                    <FilesActionsMenu
                      onSelect={() => setActiveItem(file)}
                      onRenameClick={() => setOpenRenameFileDialog(true)}
                      onDownloadClick={() => handleFileDownload(file)}
                      onDeleteClick={() => {
                        setActiveItem(file)
                        setShowSingleDeleteDialog(true)
                      }}
                    />
                  </div>
                </div>
              </div>
              <p className={'text-sm px-2 hidden sm:block'}>{formattedDate}</p>
              <p className={'text-sm px-2 hidden md:block'}>{formattedSize}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
