import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { brandedAIFriendlyName } from '@/util/enterprise'

type ChatControlsContractSearchProps = object

const ChatControlsContractSearch: React.FC<ChatControlsContractSearchProps> = () => {
  return (
    <div className={'mt-1'}>
      <div className="mb-0 pb-0">
        <div className="rounded-lg bg-yellow-50 p-4 my-4 grid grid-cols-[45px_auto] items-center">
          <div className={'flex h-7 w-7 text-yellow-700 bg-yellow-50 rounded-lg p-1 ring-4 ring-white items-center justify-center'}>
            <ExclamationTriangleIcon />
          </div>
          <span className={'text-xs text-yellow-900 text-opacity-70'}>
            These contracts are sourced from publicly available datasets such as the SEC EDGAR database.
            <br />
            {brandedAIFriendlyName} does not include any user data in the contract search.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ChatControlsContractSearch
