import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { z } from 'zod'
import {
  ChatV2QueryMetadataCaseLawSchema,
  ChatV2QueryMetadataDocumentQuerySchema,
  ChatV2QueryMetadataWebsearchQuerySchema,
  ChatV2QueryMetadataDraftingSchema,
  ChatV2QueryMetadataLrrV2Schema,
  ChatV2QueryMetadataDocumentSummarizationSchema,
  ChatV2QueryMetadataResearchSchema,
  ChatV2QueryMetadataAssistantSchema,
} from '../schemas/chat-query-metadata-schema'
import {
  reducerCurrentSourceLRRAddSource,
  reducerCurrentSourceLRRRemoveSource,
  reducerCurrentSourceLrrPathsAdd,
  reducerCurrentSourceLrrPathsRemove,
  reducerCurrentSourceLrrRemoveAll,
} from './chat-v2-reducers-lrr'
import {
  reducerAddCaseLawCourtIds,
  reducerRemoveCaseLawCourtIds,
  reducerSetCaseLawDateRange,
  reducerSetIncludeUnpublishedCases,
} from './chat-v2-reducers-caselaw'
import {
  reducerCurrentSourceDocumentQueryAddSelectedFile,
  reducerCurrentSourceDocumentQueryClearAll,
  reducerCurrentSourceDocumentQueryToggleSelectedFile,
} from './chat-v2-reducers-documentquery'

import { ChatV2SuggestedQuestions, ChatV2SuggestedQuestionsResponseSchema } from '../fetch/fetch-suggested-questions'

import { reducerCurrentSourceWebsearchSetMetadata } from './chat-v2-reducers-websearch'

import { reducerCurrentSourceDraftingChatMetadata } from './chat-v2-reducers-drafting'
import getDefaultQueryMetadataByFeature from '../schemas/chat-default-schema-values'
import {
  reducerCurrentSourceContractAnalysisClearAll,
  reducerCurrentSourceContractAnalysisSetAsOnlySelectedFile,
  reducerCurrentSourceContractAnalysisToggleSelectedFile,
} from './chat-v2-reducers-contractanalysis'
import {
  reducerCurrentSourceDocumentSummarizationAddSelectedFile,
  reducerCurrentSourceDocumentSummarizationClearAll,
  reducerCurrentSourceDocumentSummarizationToggleSelectedFile,
} from './chat-v2-reducers-documentsummarization'

import { reducerCurrentSourceContractSearchMetadata } from './chat-v2-reducers-contractsearch'
import { ConfidenceWSEventData } from '@/components/confidence-indicator/schema/confidence-indicator-schema'
import {
  reducerCurrentSourceResearchAddFederalCourtIds,
  reducerCurrentSourceResearchRemoveFederalCourtIds,
  reducerCurrentSourceResearchSetCaseLawDateRange,
  reducerCurrentSourceResearchAddStateCourtIds,
  reducerCurrentSourceResearchRemoveStateCourtIds,
  reducerCurrentSourceResearchAddFederalLRRSource,
  reducerCurrentSourceResearchRemoveFederalLRRSource,
  reducerCurrentSourceResearchAddStateLRRSource,
  reducerCurrentSourceResearchRemoveStateLRRSource,
  reducerCurrentSourceResearchRemoveAllStateLRRSources,
  reducerCurrentSourceResearchRemoveAllFederalLRRSources,
  reducerCurrentSourceResearchRemoveAllFederalCourtIds,
  reducerCurrentSourceResearchRemoveAllStateCourtIds,
  reducerCurrentSourceResearchSetIncludeUnpublishedCases,
} from './chat-v2-reducers-research'
import {
  reducerCurrentSourceAssistantAddFile,
  reducerCurrentSourceAssistantClearAllFiles,
  reducerCurrentSourceAssistantSetFocus,
  reducerCurrentSourceAssistantToggleSelectedFile,
} from './chat-v2-reducers-assistant'
import { paxtonHighlightAreaSchema } from '@/components/react-pdf-viewer/schema/paxton-highlight-area'

// Enum of chat types accepted by the server (FeatureType on server)
export enum ChatV2Feature {
  lrr = 'lrr',
  lrr_v2 = 'lrr_v2',
  research = 'research',
  caselaw = 'caselaw',
  websearch = 'websearch',
  assistant = 'assistant',
  documentquery = 'documentquery',
  drafting = 'drafting',
  contractanalysis = 'contractanalysis',
  contractsearch = 'contract_retrieval',
  documentSummarization = 'summarization',
}

// Enum of chat actors
export enum ChatV2MessageType {
  user_query = 'user_query',
  client_error = 'client_error',
  response = 'response',
  suggested_questions = 'suggested_questions',
}

export enum ChatV2ReferenceType {
  documentquery = 'documentquery',
  documentquery_full_doc = 'documentquery_full_doc',
  pol = 'pol',
  caselaw = 'caselaw',
  lrr_v2 = 'lrr_v2',
  websearch = 'websearch',
}

// Conversation type
export type ChatV2Conversation = {
  id: string
  isPending?: boolean // Optional parameter for whether this is a pending conversation (exists client-side only)
  fromPendingId?: string // Optional parameter for handoff between pending and server-provided conversation
  feature: ChatV2Feature
  created_at: string
  isLoading: boolean
  isError: boolean
  messages: Record<string, ChatV2Message>
  currentSource: Record<string, any> | null // Holds the current source for any conversation type. Zod schema can validate at runtime if it's appropriate for a feature or form.
  currentTextInput: string
  formValidationError: boolean
  visibleReference: ChatV2MessageReferenceType | null // Holds the visible reference for a conversation
  visibleReferenceRenderTrigger?: string // Optional parameter for triggering a re-render of the reference viewer
  openRefToCitatorTab?: boolean // Optional parameter for whether to open the reference viewer to the citator tab
  title?: string // Optional parameter for the title of the conversation
}

// References type
export const ChatV2MessageReferenceSchema = z.object({
  reference_number: z.string(),
  text: z.string(),
  metadata: z.record(z.any()),
  relevant_sentences: z.array(z.string()).nullable(),
  relevant_highlights: z
    .array(paxtonHighlightAreaSchema)
    .nullable()
    .describe('Highlight bounding box data compatible with react_pdf_viewer highlighting needs and sentence grouping needs'),
})
export type ChatV2MessageReferenceType = z.infer<typeof ChatV2MessageReferenceSchema>

// Message chunk schema and type (from zod schema)
export const ChatV2MessageChunk = z.object({
  conversation_id: z.string(),
  message_id: z.string(),
  value: z.string(),
})
export type ChatV2MessageChunkType = z.infer<typeof ChatV2MessageChunk>

// Chat message metadata schema and type (from zod schema)
export const ChatV2MessageMetadataSchema = z.object({
  feature: z.nativeEnum(ChatV2Feature, {
    invalid_type_error: 'Feature enum provided is invalid.',
    required_error: 'Feature enum required.',
  }),
  message_type: z.nativeEnum(ChatV2MessageType, {
    invalid_type_error: 'Actor enum provided is invalid.',
    required_error: 'Actor enum required.',
  }),
  created_at: z.string(),
  conversation_id: z.string(),
  message_id: z.string(),
  is_error: z.boolean(),
  get_follow_up_questions: z.boolean(),
  suggested_questions: ChatV2SuggestedQuestionsResponseSchema.optional(),
  suggested_questions_loading: z.boolean().optional(),
  references: z.record(ChatV2MessageReferenceSchema),
})
export type ChatV2MessageMetadataType = z.infer<typeof ChatV2MessageMetadataSchema>

// Message Schema
export const ChatV2MessageSchema = z.object({
  text: z.string(),
  metadata: ChatV2MessageMetadataSchema,
  request_params: z.any().optional(),
  confidence_label: z.string().nullable().optional(),
})
export type ChatV2Message = z.infer<typeof ChatV2MessageSchema>

// Full response schema and type (from zod schema)
export const ChatV2FullResponseSchema = z.object({
  text: z.string(),
  metadata: ChatV2MessageMetadataSchema,
})
export type ChatV2FullResponseType = z.infer<typeof ChatV2FullResponseSchema>

// Allows us to update the UI when a new conversation is added
// Includes all parameters the UX needs to evaluate redirect needs when a new conversation is detected
type NewConversationTrigger = {
  id: string
  feature: ChatV2Feature
  fromPendingId: string | null
}

// State type
export type ChatV2State = {
  newConversationIdUXTrigger: NewConversationTrigger | null
  conversations: Record<string, ChatV2Conversation>
}

// Initial state
const initialState: ChatV2State = {
  newConversationIdUXTrigger: null,
  conversations: {},
}

// Slice / reducers
export const chatV2Slice = createSlice({
  name: 'chatV2State',
  initialState,
  reducers: {
    nullifyData: () => initialState,

    // Add a pending conversation (before we have a conversation id from the server)
    chatV2AddConversation: (state, action: PayloadAction<ChatV2Conversation>) => {
      const conversation = action.payload

      // Add the pending conversation including the user's query message
      state.conversations[conversation.id] = conversation
    },

    // Update the conversation title
    chatV2UpdateConversationTitle: (state, action: PayloadAction<{ conversationId: string; title: string }>) => {
      const { conversationId, title } = action.payload

      const conversation = state.conversations[conversationId]
      if (!conversation) {
        // skip
        return
      }

      // Update the conversation title
      conversation.title = title
    },

    // Delete a pending conversation
    chatV2DeleteConversation: (state, action: PayloadAction<string>) => {
      // Delete the pending conversation
      delete state.conversations[action.payload]
    },

    // Add conversation from fetched messages
    chatV2AddConversationFromMessages: (
      state,
      action: PayloadAction<{ conversationId: string; feature: ChatV2Feature; messages: ChatV2Message[]; title: string }>
    ) => {
      const { conversationId, feature, messages, title } = action.payload

      // Filter for user_query or response because these contain the metadata we need to construct a conversation
      const filteredMessages = messages.filter((message) => {
        return message.metadata.message_type === ChatV2MessageType.user_query || message.metadata.message_type === ChatV2MessageType.response
      })

      // If there are no messages, construct the most basic conversation
      if (filteredMessages.length === 0) {
        state.conversations[conversationId] = {
          id: conversationId,
          feature: feature,
          isLoading: false,
          isError: false,
          created_at: new Date().toISOString(),
          messages: {},
          currentSource: null,
          currentTextInput: '',
          formValidationError: false,
          visibleReference: null,
          title: title,
        }
        return
      }

      // Else - fill the conversation with available message metadata
      // Get the metadata from the most recent message
      const mostRecentMessage = filteredMessages[filteredMessages.length - 1]

      // Get the metadata
      const metadata = mostRecentMessage.metadata
      const payloadMetadataFeature = metadata.feature

      // Get the request params
      const request_params = mostRecentMessage.request_params ?? null

      // Default currentSource for the conversation to null
      let currentSource = null

      // Validate the request parameters and only set the currentSource if one matches
      switch (feature) {
        case ChatV2Feature.lrr:
          console.error('ChatV2Feature.lrr is legacy, this case should not be encountered when clients are fully updated.')
          break
        case ChatV2Feature.lrr_v2:
          if (ChatV2QueryMetadataLrrV2Schema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.caselaw:
          if (ChatV2QueryMetadataCaseLawSchema.safeParse(request_params).success) {
            currentSource = request_params
          } else {
            // Set defaults to new schema format
            console.log(`Migrating old schema to new schema for feature ${feature}`)
            currentSource = getDefaultQueryMetadataByFeature(feature)
          }
          break
        case ChatV2Feature.research:
          if (ChatV2QueryMetadataResearchSchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.documentquery:
          if (ChatV2QueryMetadataDocumentQuerySchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.websearch:
          if (ChatV2QueryMetadataWebsearchQuerySchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.drafting:
          if (ChatV2QueryMetadataDraftingSchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.contractsearch:
          // No request params for contract analysis
          currentSource = getDefaultQueryMetadataByFeature(feature)
          break
        case ChatV2Feature.documentSummarization:
          if (ChatV2QueryMetadataDocumentSummarizationSchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        case ChatV2Feature.assistant:
          if (ChatV2QueryMetadataAssistantSchema.safeParse(request_params).success) {
            currentSource = request_params
          }
          break
        default:
          console.error(`Did you forget to incorporate a new feature (${feature}) into the currentSource propagator?`)
          break
      }

      // Log schema validation issues with non-legacy features
      if (!currentSource && request_params != null && payloadMetadataFeature !== ChatV2Feature.lrr) {
        console.error(`request_params did not match ${feature} schema`, request_params)
      }

      // Create the conversation if it does not exist
      if (!state.conversations[metadata.conversation_id]) {
        state.conversations[metadata.conversation_id] = {
          id: metadata.conversation_id,
          feature: metadata.feature,
          isLoading: false,
          isError: false,
          created_at: metadata.created_at,
          messages: {},
          currentSource: currentSource,
          currentTextInput: '',
          formValidationError: false,
          visibleReference: null,
          title: title,
        }
      }

      // Insert the messages into the conversation
      messages.forEach((message) => {
        state.conversations[metadata.conversation_id].messages[message.metadata.message_id] = message
      })
    },

    // Add conversation from metadata (in a streamed response, we only have the data needed for this when we receive metadata)
    // Optionally included fromPendingId will perform a migration to the new conversation and trigger a redirect in the UX
    chatV2AddConversationFromMetadata: (state, action: PayloadAction<{ metadata: ChatV2MessageMetadataType; fromPendingId: string | null }>) => {
      const { metadata, fromPendingId } = action.payload

      // Check if the conversation already exists
      if (state.conversations[metadata.conversation_id]) {
        // console.log(`Conversation ${metadata.conversation_id} already exists in state. Skipping add.`)
        return
      }

      // Initialize the new conversation
      const newConversation: ChatV2Conversation = {
        id: metadata.conversation_id,
        feature: metadata.feature,
        isLoading: false,
        isError: false,
        created_at: new Date().toISOString(),
        messages: {},
        currentSource: null,
        currentTextInput: '',
        formValidationError: false,
        visibleReference: null,
      }

      // If a pending conversation id is provided, migrate the data to this new conversation
      if (fromPendingId) {
        // Get the pending conversation from the provided id and ensure it exists
        const pendingConversation = state.conversations[fromPendingId]
        if (!pendingConversation) {
          console.error(
            `Could not find pending conversation in state even though fromPendingId was provided: ${fromPendingId}. Conversations: `,
            state.conversations
          )
        } else {
          // Set the fromPendingId (will trigger a UX redirect in some cases in a useEffect)
          newConversation.fromPendingId = fromPendingId

          // Migrate the currentSource
          newConversation.currentSource = pendingConversation.currentSource

          // Migrate the loading status
          newConversation.isLoading = pendingConversation.isLoading
        }
      }

      // Set the new conversation to state
      state.conversations[metadata.conversation_id] = newConversation

      // Trigger new conversation UX changes with the ID
      state.newConversationIdUXTrigger = {
        id: metadata.conversation_id,
        feature: metadata.feature,
        fromPendingId: fromPendingId,
      }

      // Delete the pending conversation if it exists
      if (fromPendingId) {
        delete state.conversations[fromPendingId]
      }
    },

    chatV2SetConversationCurrentTextInput: (state, action: PayloadAction<{ conversationId: string; text: string }>) => {
      const { conversationId, text } = action.payload

      // Set the conversation's current text input
      state.conversations[conversationId].currentTextInput = text
    },

    chatV2SetConversationLoadingStatus: (state, action: PayloadAction<{ conversationId: string; isLoading: boolean }>) => {
      const { conversationId, isLoading } = action.payload

      // Set the conversation's loading status
      state.conversations[conversationId].isLoading = isLoading
    },

    chatV2SetFormValidationError: (state, action: PayloadAction<{ conversationId: string; formValidationError: boolean }>) => {
      const { conversationId, formValidationError } = action.payload

      // Set the conversation's loading status
      state.conversations[conversationId].formValidationError = formValidationError
    },

    // Add Client Error Message Bubble
    addClientErrorMessageBubble: (state, action: PayloadAction<{ conversationId: string; errorMessage: string }>) => {
      const { conversationId, errorMessage } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Generate a message id
      const messageId = nanoid()

      // Construct a ChatV2Message of type client_error
      const message: ChatV2Message = {
        text: errorMessage,
        metadata: {
          feature: conversation.feature,
          message_type: ChatV2MessageType.client_error,
          created_at: new Date().toISOString(),
          conversation_id: conversation.id,
          message_id: messageId,
          is_error: true,
          get_follow_up_questions: false,
          references: {},
        },
      }

      // Add the client error message to the conversation
      conversation.messages[messageId] = message

      // Set loading to false
      conversation.isLoading = false
    },

    // Add Client Suggested Questions Message Bubble
    addSuggstedQuestionsMessageBubble: (
      state,
      action: PayloadAction<{ conversationId: string; newMessageId: string; loading: boolean; suggestedQuestions?: ChatV2SuggestedQuestions }>
    ) => {
      const { conversationId, newMessageId, loading, suggestedQuestions } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Construct a ChatV2Message of type follow_up_questions
      const message: ChatV2Message = {
        text: 'this should not be rendered on this type of message',
        metadata: {
          feature: conversation.feature,
          message_type: ChatV2MessageType.suggested_questions,
          created_at: new Date().toISOString(),
          conversation_id: conversation.id,
          message_id: newMessageId,
          is_error: false,
          get_follow_up_questions: false,
          suggested_questions: suggestedQuestions,
          suggested_questions_loading: loading,
          references: {},
        },
      }

      // Add the client error message to the conversation
      conversation.messages[newMessageId] = message
    },

    // Update Suggested Questions Message Bubble
    updateSuggstedQuestionsMessageBubble: (
      state,
      action: PayloadAction<{ conversationId: string; messageId: string; isError?: boolean; loading?: boolean; suggestedQuestions?: ChatV2SuggestedQuestions }>
    ) => {
      const { conversationId, messageId, isError, loading, suggestedQuestions } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Get the message reference
      const message = conversation.messages[messageId]

      // Update the message
      if (typeof isError !== 'undefined') {
        message.metadata.is_error = isError
      }

      if (typeof loading !== 'undefined') {
        message.metadata.suggested_questions_loading = loading
      }

      if (suggestedQuestions) {
        message.metadata.suggested_questions = suggestedQuestions
      }
    },

    // Insert user's query message into the conversation (for active conversations)
    chatV2InsertUserQueryMessage: (state, action: PayloadAction<{ conversationId: string; message: ChatV2Message }>) => {
      const { conversationId, message } = action.payload

      // Insert the message if it does not already exist in the conversation
      // (this is to prevent duplicate messages from being inserted)
      // Handled both instantly when we have a conversation id, and after metadata is received for new conversations
      if (!state.conversations[conversationId].messages[message.metadata.message_id]) {
        state.conversations[conversationId].messages[message.metadata.message_id] = message
      }
    },

    // Add Message From Metadata - creates a new message in its conversation
    chatV2AddMessageFromMetadata: (state, action: PayloadAction<ChatV2MessageMetadataType>) => {
      const metadata = action.payload

      // Initialize message_id if it doesn't exist
      if (!state.conversations[metadata.conversation_id].messages[metadata.message_id]) {
        state.conversations[metadata.conversation_id].messages[metadata.message_id] = {
          text: '',
          metadata,
        }
      }
    },

    // Handle a full response
    chatV2HandleFullResponse: (state, action: PayloadAction<ChatV2FullResponseType>) => {
      const message = action.payload

      // Initialize message_id if it doesn't exist
      if (!state.conversations[message.metadata.conversation_id].messages[message.metadata.message_id]) {
        state.conversations[message.metadata.conversation_id].messages[message.metadata.message_id] = message
      }
    },

    // Handle a message chunk
    chatV2HandleMessageChunk: (state, action: PayloadAction<ChatV2MessageChunkType>) => {
      const chunk = action.payload

      // Check if the conversation exists
      if (!state.conversations[chunk.conversation_id]) {
        console.error(`Conversation does not exist in state. Cannot handle chunk:`, chunk)
        return state
      }

      const oldText = state.conversations[chunk.conversation_id].messages[chunk.message_id].text
      const newText = oldText + chunk.value

      // Update the message with the new text
      state.conversations[chunk.conversation_id].messages[chunk.message_id].text = newText
    },

    // Set Visible Reference
    chatV2SetVisibleReference: (
      state,
      action: PayloadAction<{ conversationId: string; reference: ChatV2MessageReferenceType; openRefToCitatorTab?: boolean }>
    ) => {
      const { conversationId, reference, openRefToCitatorTab } = action.payload

      // Set the visible reference
      state.conversations[conversationId].visibleReference = reference
      state.conversations[conversationId].visibleReferenceRenderTrigger = nanoid()
      state.conversations[conversationId].openRefToCitatorTab = openRefToCitatorTab
    },

    // Remove Visible Reference
    chatV2RemoveVisibleReference: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload

      // Remove the visible reference
      state.conversations[conversationId].visibleReference = null
      state.conversations[conversationId].openRefToCitatorTab = false
    },

    // Handle Confidence Payload
    chatV2HandleConfidencePayload: (state, action: PayloadAction<ConfidenceWSEventData>) => {
      const { value, conversation_id, message_id } = action.payload

      // Get the conversation and message
      const message = state.conversations[conversation_id]?.messages[message_id] ?? null

      // Update the message with the confidence data
      if (!message) {
        console.error(`Trying to set confidence on unfound message: `, JSON.stringify(action.payload))
        return
      }

      // Update the message with the confidence data
      message.confidence_label = value
    },

    // currentSource Reducers: LRR_V2
    chatV2CurrentSourceLrrAddSource: reducerCurrentSourceLRRAddSource,
    chatV2CurrentSourceLrrRemoveSource: reducerCurrentSourceLRRRemoveSource,
    chatV2CurrentSourceLrrPathsAdd: reducerCurrentSourceLrrPathsAdd,
    chatV2CurrentSourceLrrPathsRemove: reducerCurrentSourceLrrPathsRemove,
    chatV2CurrentSourceLrrRemoveAll: reducerCurrentSourceLrrRemoveAll,

    // currentSource Reducers: Case Law
    chatV2SetCaseLawDateRange: reducerSetCaseLawDateRange,
    chatV2AddCaseLawCourtIds: reducerAddCaseLawCourtIds,
    chatV2RemoveCaseLawCourtIds: reducerRemoveCaseLawCourtIds,
    chatV2SetCaseLawIncludeUnpublishedCases: reducerSetIncludeUnpublishedCases,

    // currentSource Reducers: Research
    chatV2CurrentSourceResearchSetCaseLawDateRange: reducerCurrentSourceResearchSetCaseLawDateRange,
    chatV2CurrentSourceResearchAddFederalCourtIds: reducerCurrentSourceResearchAddFederalCourtIds,
    chatV2CurrentSourceResearchRemoveFederalCourtIds: reducerCurrentSourceResearchRemoveFederalCourtIds,
    chatV2CurrentSourceResearchRemoveAllFederalCourtIds: reducerCurrentSourceResearchRemoveAllFederalCourtIds,
    chatV2CurrentSourceResearchAddStateCourtIds: reducerCurrentSourceResearchAddStateCourtIds,
    chatV2CurrentSourceResearchRemoveStateCourtIds: reducerCurrentSourceResearchRemoveStateCourtIds,
    chatV2CurrentSourceResearchRemoveAllStateCourtIds: reducerCurrentSourceResearchRemoveAllStateCourtIds,
    chatV2CurrentSourceResearchAddFederalLRRSource: reducerCurrentSourceResearchAddFederalLRRSource,
    chatV2CurrentSourceResearchRemoveFederalLRRSource: reducerCurrentSourceResearchRemoveFederalLRRSource,
    chatV2CurrentSourceResearchRemoveAllFederalLRRSources: reducerCurrentSourceResearchRemoveAllFederalLRRSources,
    chatV2CurrentSourceResearchAddStateLRRSource: reducerCurrentSourceResearchAddStateLRRSource,
    chatV2CurrentSourceResearchRemoveStateLRRSource: reducerCurrentSourceResearchRemoveStateLRRSource,
    chatV2CurrentSourceResearchRemoveAllStateLRRSources: reducerCurrentSourceResearchRemoveAllStateLRRSources,
    chatV2CurrentSourceResearchSetIncludeUnpublishedCases: reducerCurrentSourceResearchSetIncludeUnpublishedCases,

    // currentSource Reducers: Document Query
    chatV2CurrentSourceDocumentQueryAddSelectedFile: reducerCurrentSourceDocumentQueryAddSelectedFile,
    chatV2CurrentSourceDocumentQueryToggleSelectedFile: reducerCurrentSourceDocumentQueryToggleSelectedFile,
    chatV2CurrentSourceDocumentQueryClearAll: reducerCurrentSourceDocumentQueryClearAll,

    // currentSource Reducers: Assistant
    chatV2CurrentSourceAssistantToggleSelectedFile: reducerCurrentSourceAssistantToggleSelectedFile,
    chatV2CurrentSourceAssistantAddFile: reducerCurrentSourceAssistantAddFile,
    chatV2CurrentSourceAssistantSetFocus: reducerCurrentSourceAssistantSetFocus,
    chatV2CurrentSourceAssistantClearAllFiles: reducerCurrentSourceAssistantClearAllFiles,

    // currentSource Reducers: contractanalysis
    chatV2CurrentSourceContractAnalysisToggleSelectedFile: reducerCurrentSourceContractAnalysisToggleSelectedFile,
    chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile: reducerCurrentSourceContractAnalysisSetAsOnlySelectedFile,
    chatV2CurrentSourceContractAnalysisClearAll: reducerCurrentSourceContractAnalysisClearAll,

    // currentSource Reducers: Websearch
    chatV2CurrentSourceWebsearchSetMetadata: reducerCurrentSourceWebsearchSetMetadata,
    chatV2CurrentSourceDraftingMetadata: reducerCurrentSourceDraftingChatMetadata,

    // currentSource Reducers: Document Summarization
    chatV2CurrentSourceDocumentSummarizationToggleSelectedFile: reducerCurrentSourceDocumentSummarizationToggleSelectedFile,
    chatV2CurrentSourceDocumentSummarizationClearAll: reducerCurrentSourceDocumentSummarizationClearAll,
    chatV2CurrentSourceDocumentSummarizationAddSelectedFile: reducerCurrentSourceDocumentSummarizationAddSelectedFile,
    chatV2CurrentSourceContractSearchMetadata: reducerCurrentSourceContractSearchMetadata,
  },
})

export const {
  nullifyData,
  chatV2AddConversation,
  chatV2UpdateConversationTitle,
  chatV2SetConversationLoadingStatus,
  chatV2DeleteConversation,
  chatV2AddConversationFromMetadata,
  chatV2InsertUserQueryMessage,
  chatV2AddMessageFromMetadata,
  chatV2HandleFullResponse,
  chatV2HandleMessageChunk,
  chatV2SetFormValidationError,
  chatV2SetConversationCurrentTextInput,
  chatV2AddConversationFromMessages,
  chatV2SetVisibleReference,
  chatV2RemoveVisibleReference,
  addClientErrorMessageBubble,
  addSuggstedQuestionsMessageBubble,
  updateSuggstedQuestionsMessageBubble,
  chatV2HandleConfidencePayload,
  chatV2CurrentSourceLrrAddSource,
  chatV2CurrentSourceLrrRemoveSource,
  chatV2CurrentSourceLrrPathsAdd,
  chatV2CurrentSourceLrrPathsRemove,
  chatV2CurrentSourceLrrRemoveAll,
  chatV2SetCaseLawDateRange,
  chatV2AddCaseLawCourtIds,
  chatV2RemoveCaseLawCourtIds,
  chatV2SetCaseLawIncludeUnpublishedCases,
  chatV2CurrentSourceResearchSetCaseLawDateRange,
  chatV2CurrentSourceResearchAddFederalCourtIds,
  chatV2CurrentSourceResearchRemoveFederalCourtIds,
  chatV2CurrentSourceResearchRemoveAllFederalCourtIds,
  chatV2CurrentSourceResearchAddStateCourtIds,
  chatV2CurrentSourceResearchRemoveStateCourtIds,
  chatV2CurrentSourceResearchRemoveAllStateCourtIds,
  chatV2CurrentSourceResearchAddFederalLRRSource,
  chatV2CurrentSourceResearchRemoveFederalLRRSource,
  chatV2CurrentSourceResearchRemoveAllFederalLRRSources,
  chatV2CurrentSourceResearchAddStateLRRSource,
  chatV2CurrentSourceResearchRemoveStateLRRSource,
  chatV2CurrentSourceResearchRemoveAllStateLRRSources,
  chatV2CurrentSourceResearchSetIncludeUnpublishedCases,
  chatV2CurrentSourceDocumentQueryAddSelectedFile,
  chatV2CurrentSourceDocumentQueryToggleSelectedFile,
  chatV2CurrentSourceDocumentQueryClearAll,
  chatV2CurrentSourceAssistantToggleSelectedFile,
  chatV2CurrentSourceAssistantAddFile,
  chatV2CurrentSourceAssistantSetFocus,
  chatV2CurrentSourceAssistantClearAllFiles,
  chatV2CurrentSourceContractAnalysisToggleSelectedFile,
  chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile,
  chatV2CurrentSourceContractAnalysisClearAll,
  chatV2CurrentSourceWebsearchSetMetadata,
  chatV2CurrentSourceDraftingMetadata,
  chatV2CurrentSourceContractSearchMetadata,
  chatV2CurrentSourceDocumentSummarizationToggleSelectedFile,
  chatV2CurrentSourceDocumentSummarizationClearAll,
} = chatV2Slice.actions

export default chatV2Slice.reducer
