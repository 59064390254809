import { kCaseLawSourceSanitizationAllowedTags } from '@/constants/constants-ui'
import { Parser } from 'html-to-react'
import { useRef, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import CaseLawReferenceCitatorTab from '@/citator/views/CaseLawReferenceCitatorTab'
import { CaseLawReferenceViewTabs } from '@/chat-common/components/reference-viewer/reference-uri-handler'
import { CaselawReferenceMetadataResponse } from '../schema/caselaw-reference-viewer.schema'
import { useGetCaselawReferenceHtmlQuery } from '../apis/caselaw-reference-viewer.api'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { downloadReferenceAsDocX } from '@/chat-common/util/download-conversation'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { brandedAIName } from '@/util/enterprise'

type StandaloneReferenceViewCaselawTabsProps = {
  metadata: CaselawReferenceMetadataResponse
  defaultToCitatorTab?: boolean
}

export default function StandaloneReferenceViewCaselawTabs(props: StandaloneReferenceViewCaselawTabsProps) {
  const { metadata, defaultToCitatorTab } = props
  const { trackEvent } = useAnalytics()
  const parent_id = metadata.parent_id
  console.log('Rendering standalone caselaw view: ', metadata)

  // Local state
  const containerRef = useRef<HTMLDivElement>(null)
  const [activeTab, setActiveTab] = useState<CaseLawReferenceViewTabs>(defaultToCitatorTab ? CaseLawReferenceViewTabs.citator : CaseLawReferenceViewTabs.source)
  const [downloadLoading, setDownloadLoading] = useState(false)

  // Tab styles
  const inactiveTabStyles =
    'grow border-transparent text-brand-neutral-500 hover:border-brand-neutral-300 hover:text-brand-neutral-700 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
  const activeTabStyles = ' grow border-brand-400 text-brand-500 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'

  // RTY-Query HTML
  const { data: dataHtml, isLoading: isLoadingHtml, isError: isErrorHtml, refetch: refetchHtml } = useGetCaselawReferenceHtmlQuery(parent_id ?? '')

  // Handle tab change
  function handleActiveTabChange(tab: CaseLawReferenceViewTabs) {
    setActiveTab(tab)
  }

  // Configure the HTML parser
  const htmlParser = Parser()

  return (
    <>
      {/* Tabs */}
      <div className="border-b border-brand-neutral-200 mb-2">
        <nav className="w-full flex items-center" aria-label="Tabs">
          <button
            onClick={() => {
              handleActiveTabChange(CaseLawReferenceViewTabs.source)
            }}
            className={activeTab == CaseLawReferenceViewTabs.source ? activeTabStyles : inactiveTabStyles}
          >
            Source View
          </button>
          <button
            onClick={() => {
              handleActiveTabChange(CaseLawReferenceViewTabs.citator)
            }}
            className={activeTab == CaseLawReferenceViewTabs.citator ? activeTabStyles : inactiveTabStyles}
          >
            {brandedAIName} Citator
          </button>
        </nav>
      </div>
      {/* Render Source */}
      {activeTab === CaseLawReferenceViewTabs.source && (
        <div className="reference-view-html overflow-y-scroll py-2 px-4">
          {isLoadingHtml && (
            <div className={''}>
              <CircularProgressContinuousSized size={12} thickness={9} />
            </div>
          )}

          {(isErrorHtml || (!dataHtml?.data && !isLoadingHtml)) && (
            <div className={'pl-2'}>
              <div className={'text-red-700 text-sm'}>Error fetching data.</div>
              <button
                className={
                  'flex-auto items-center rounded-md bg-brand-500 border-[1px] border-brand-500 px-2 py-1 my-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
                }
                onClick={() => refetchHtml()}
              >
                Retry
              </button>
            </div>
          )}

          {dataHtml?.data && !isLoadingHtml && (
            <>
              <div className={'flex items-center mb-3'}>
                <button
                  className={`flex items-end hover:bg-brand-700 text-white bg-brand-500 px-2 py-1 rounded-md transition-all ease-in-out duration-300`}
                  onClick={() => {
                    trackEvent(AnalyticsEvent.DownloadChatSourceToDocx)
                    downloadReferenceAsDocX(
                      sanitizeHtml(dataHtml.data, {
                        allowedTags: kCaseLawSourceSanitizationAllowedTags,
                        transformTags: { pre: 'p', code: 'p' },
                      }),
                      metadata.title ?? 'source',
                      setDownloadLoading
                    )
                  }}
                >
                  <span className={'text-sm font-medium leading-tight'}>.docx</span>
                  <ArrowDownTrayIcon className={'h-4 pl-[4px] pb-[2px]'} />
                </button>
                {downloadLoading && (
                  <span className={'pl-2'}>
                    <CircularProgressContinuousSized size={12} thickness={7} />
                  </span>
                )}
              </div>

              <div id="case-law-source" ref={containerRef} className={'text-sm'}>
                {htmlParser.parse(
                  sanitizeHtml(dataHtml.data, {
                    allowedTags: kCaseLawSourceSanitizationAllowedTags,
                    transformTags: { pre: 'p', code: 'p' },
                  })
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* Render Citator */}
      {activeTab === CaseLawReferenceViewTabs.citator && (
        <div className="overflow-y-scroll py-2 px-4">
          <CaseLawReferenceCitatorTab parent_id={parent_id} />
        </div>
      )}
    </>
  )
}
