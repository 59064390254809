import { SourceReferenceSchema } from '@/store/store-schema'

type ShowSourceButtonProps = {
  onShowSourceClick?: (source: SourceReferenceSchema) => void
  source: SourceReferenceSchema
}

const ShowSourceButton: React.FC<ShowSourceButtonProps> = (props) => {
  const { onShowSourceClick, source } = props
  if (onShowSourceClick) {
    return (
      <div className="mt-1">
        <button onClick={() => onShowSourceClick?.(source)} className="text-xs font-bold text-brand-700">
          show source &#8594;
        </button>
      </div>
    )
  } else {
    return <></>
  }
}

export default ShowSourceButton
