type ChatFootnotePopoverPrintableProps = {
  footnoteText: string
  highlight: boolean
}

export default function ChatFootnotePopoverPrintable(props: ChatFootnotePopoverPrintableProps) {
  const { footnoteText, highlight } = props

  return (
    <button
      className={`text-brand-800 px-1 hover:text-brand-400 ${highlight ? 'bg-brand-500 bg-opacity-20 rounded-md' : ''}`}
      aria-label={`Footnote ${footnoteText}`}
    >
      {footnoteText}
    </button>
  )
}
