import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZoneWithTaskState'

export enum FileProcessingStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  ERROR = 'error',
  SUCCESS = 'success',
}
export type FileUploadTasksState = {
  uploadTasks: Partial<Record<string, UploadTaskStatus>>
  processingStatuses: Partial<Record<string, FileProcessingStatus>>
}

const initialState: FileUploadTasksState = {
  uploadTasks: {},
  processingStatuses: {},
}

/**
 * FileUploadTasksState Required Types
 * KEY: sourcePath
 */
export const fileUploadTasksSlice = createSlice({
  name: 'fileUploadTasksSlice',
  initialState: initialState,
  reducers: {
    addFileUploadTask: (state, action: PayloadAction<UploadTaskStatus[]>) => {
      action.payload.forEach((task: UploadTaskStatus) => {
        state.uploadTasks[task.sourcePath] = task
      })
    },
    updateFileUploadTask: (state, action: PayloadAction<UploadTaskStatus>) => {
      state.uploadTasks[action.payload.sourcePath] = action.payload
    },
    deleteFileUploadTask: (state, action: PayloadAction<{ sourcePath: string }>) => {
      delete state.uploadTasks[action.payload.sourcePath]
    },

    // Set the processing status of a file
    setFileProcessingStatus: (state, action: PayloadAction<{ sourcePath: string; status: FileProcessingStatus }>) => {
      const { sourcePath, status } = action.payload

      state.processingStatuses[sourcePath] = status
    },

    removeFileProcessing: (state, action: PayloadAction<{ sourcePath: string }>) => {
      const { sourcePath } = action.payload
      delete state.processingStatuses[sourcePath]
    },
  },
})

export const { addFileUploadTask, updateFileUploadTask, deleteFileUploadTask, setFileProcessingStatus, removeFileProcessing } = fileUploadTasksSlice.actions

export default fileUploadTasksSlice.reducer
