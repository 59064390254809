import { chatV2CurrentSourceResearchAddFederalCourtIds, chatV2CurrentSourceResearchRemoveFederalCourtIds } from '@/chat-common/store/chat-v2.slice'
import { CourtsResponse } from '@/store/apis/hosted-filters-api'
import { useAppDispatch } from '@/store/store-hooks'
import { ReactElement, useEffect, useRef } from 'react'
import { addAllFederalLRRSources } from './research-source-utils'

type ResearchDialogFederalCourtSelectionProps = {
  conversationId: string
  courts: CourtsResponse
  selectedCourts: string[]
  checkboxClassName?: string
}

export default function ResearchDialogFederalCourtSelection(props: ResearchDialogFederalCourtSelectionProps) {
  const { conversationId, courts, selectedCourts } = props

  // Store
  const dispatch = useAppDispatch()

  // Get All Child Court Ids of a Federal Court
  function getAllChildIds(courtKey: string): string[] {
    const courtValue = courts.Federal[courtKey]
    const childIds: string[] = []

    Object.keys(courtValue).forEach((childKey) => {
      const childValue = courtValue[childKey]

      switch (true) {
        case typeof childValue === 'string':
          childIds.push(childValue)
          break
        case Array.isArray(childValue):
          childIds.push(...childValue)
          break
        default:
          console.error(`Federal court child value from key ${childKey} is not a string or array of strings: `, courtValue)
          break
      }
    })

    return childIds
  }

  // Check if federal court is checked where every child is checked
  function isCourtIdChecked(courtIds: string[]): boolean {
    return courtIds.every((courtId) => selectedCourts.includes(courtId))
  }

  // Check if some but not all children are checked
  function isCourtIdIndeterminate(courtIds: string[]): boolean {
    const someChecked = courtIds.some((courtId) => selectedCourts.includes(courtId))
    const allChecked = isCourtIdChecked(courtIds)
    return someChecked && !allChecked
  }

  // Check if all children are checked
  function areAllCourtChildrenChecked(courtKey: string): boolean {
    // Get the child ids
    const childIds = getAllChildIds(courtKey)

    // Check if all children are checked
    return childIds.every((childId) => selectedCourts.includes(childId))
  }

  // Toggle All Children
  // Action based on checked value
  function toggleCourtChildren(checked: boolean, courtKey: string) {
    // Get the child ids
    const childIds = getAllChildIds(courtKey)

    // Dispatch the action
    if (checked) {
      dispatch(chatV2CurrentSourceResearchAddFederalCourtIds({ conversationId: conversationId, courtIds: childIds }))
      addAllFederalLRRSources(dispatch, conversationId)
    } else {
      dispatch(chatV2CurrentSourceResearchRemoveFederalCourtIds({ conversationId: conversationId, courtIds: childIds }))
    }
  }

  // Toggle Single Court
  // Action based on checked value
  function toggleCourtIds(checked: boolean, courtIds: string[]) {
    if (checked) {
      dispatch(chatV2CurrentSourceResearchAddFederalCourtIds({ conversationId: conversationId, courtIds: courtIds }))
      addAllFederalLRRSources(dispatch, conversationId)
    } else {
      dispatch(chatV2CurrentSourceResearchRemoveFederalCourtIds({ conversationId: conversationId, courtIds: courtIds }))
    }
  }

  function CourtIdsSelector(props: { label: string; courtIds: string[] }) {
    const { label, courtIds } = props
    const htmlLabel = 'courtIds-' + courtIds.join(',')

    const checkboxRef = useRef<HTMLInputElement>(null)

    // Set indeterminate state if only some children are check
    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = isCourtIdIndeterminate(courtIds)
      }
    }, [courtIds])

    return (
      <div className="flex items-center mb-1">
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
          id={htmlLabel}
          name={label}
          value={courtIds}
          checked={isCourtIdChecked(courtIds)}
          ref={checkboxRef}
          onChange={(event) => {
            // console.log(`Checked ${label} with ids: ${courtIds}`)
            toggleCourtIds(event.target.checked, courtIds)
          }}
        />
        <label htmlFor={htmlLabel} className="pl-2 text-sm">
          {label}
        </label>
      </div>
    )
  }

  function RenderFederalCourts(props: { courts: Record<string, any> }): ReactElement[] {
    const { courts } = props

    return Object.keys(courts).map((courtKey) => {
      const courtValue = courts[courtKey]

      // Render the top level selectable courts
      if (typeof courtValue === 'string') {
        return (
          <div key={courtKey} className="mb-2">
            <CourtIdsSelector label={courtKey} courtIds={[courtValue]} />
          </div>
        )
      }
      // Else If the top level is an object, it's checkbox is programmatic and it's children are rendered
      else if (typeof courtValue === 'object') {
        return (
          <div key={courtKey} className="mb-5 break-inside-avoid">
            <div className="flex items-center mb-1">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
                id={courtKey}
                name={courtKey}
                checked={areAllCourtChildrenChecked(courtKey)}
                onChange={(event) => {
                  // console.log(`Toggling ${courtKey} children to`, event.target.checked)
                  toggleCourtChildren(event.target.checked, courtKey)
                }}
              />
              <label htmlFor={courtKey} className="pl-2 text-sm font-medium">
                {courtKey}
              </label>
            </div>
            <div className="pl-5">
              {Object.keys(courtValue).map((childKey) => {
                const childValue = courtValue[childKey]

                switch (true) {
                  // value is string
                  case typeof childValue === 'string':
                    return <CourtIdsSelector key={childKey} label={childKey} courtIds={[childValue]} />

                  // value is array of strings
                  case Array.isArray(childValue):
                    return <CourtIdsSelector key={childKey} label={childKey} courtIds={childValue} />

                  default:
                    console.warn(`Court Is Unknown key: ${childKey}, value: ${JSON.stringify(childValue)}`)
                    return <div key={childKey}></div>
                }
              })}
            </div>
          </div>
        )
      }
      // Other conditions
      else {
        return <div key={courtKey}></div>
      }
    })
  }

  // Render federal courts list
  return (
    <div className="columns-2 md:columns-3 pt-2">
      <RenderFederalCourts courts={courts.Federal} />
    </div>
  )
}
