import { Root, Trigger, Portal, Content, Arrow } from '@radix-ui/react-popover'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useState } from 'react'

export default function IncludeUnpublishedCasesPopover() {
  const [isOpen, setIsOpen] = useState(false)

  const togglePopover = (open: boolean) => {
    setIsOpen(open)
  }

  return (
    <Root open={isOpen} onOpenChange={togglePopover}>
      <Trigger asChild>
        <button onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} onClick={() => setIsOpen((prev) => !prev)}>
          <InfoOutlinedIcon className="text-brand-500" style={{ fontSize: 17 }} />
        </button>
      </Trigger>

      <Portal>
        <Content
          className="z-50 rounded ml-1 -mt-1 px-3 py-2 max-w-44 xs:max-w-md bg-brand-neutral-50 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >
          <div className="text-xs">When selected, unpublished cases will be included in the search.</div>
          <Arrow className="fill-white" />
        </Content>
      </Portal>
    </Root>
  )
}
