import { NodeType, TreeNode } from '../types'
import { BaseQueryFn } from '@reduxjs/toolkit/query'

const mockData: TreeNode = [
  {
    id: '2',
    name: 'Documents',
    type: NodeType.FOLDER,
    parentId: '1',
    createdAt: '2023-10-02T08:30:00Z',
    updatedAt: '2023-10-05T09:15:00Z',
    folderPath: '/files/documents',
  },
  {
    id: '3',
    name: 'Resume.pdf',
    type: NodeType.FILE,
    sourceLocation: '/files/resume.pdf',
    status: 'complete',
    extension: 'pdf',
    size: 150245,
    folderPath: '/files/resume.pdf',
    createdAt: '2023-10-03T10:00:00Z',
    updatedAt: '2023-10-03T10:00:00Z',
    parentId: '2',
  },
  {
    id: '4',
    name: 'CoverLetter.docx',
    type: NodeType.FILE,
    sourceLocation: '/files/cover_letter.docx',
    status: 'processing',
    extension: 'docx',
    size: 80235,
    folderPath: '/files/cover_letter.docx',
    createdAt: '2023-10-04T11:20:00Z',
    updatedAt: '2023-10-04T11:20:00Z',
    parentId: '2',
  },
  {
    id: '5',
    name: 'Photos',
    type: NodeType.FOLDER,
    parentId: '1',
    createdAt: '2023-10-06T14:45:00Z',
    updatedAt: '2023-10-06T14:45:00Z',
    folderPath: '/files/photos',
  },
  {
    id: '6',
    name: 'Vacation.jpg',
    type: NodeType.FILE,
    sourceLocation: '/files/vacation.jpg',
    status: 'ocr',
    extension: 'jpg',
    size: 2048000,
    folderPath: '/files/vacation.jpg',
    createdAt: '2023-10-07T09:00:00Z',
    updatedAt: '2023-10-07T09:00:00Z',
    parentId: '5',
  },
  {
    id: '7',
    name: 'todo.txt',
    type: NodeType.FILE,
    sourceLocation: '/files/todo.txt',
    status: 'complete',
    extension: 'txt',
    size: 1024,
    folderPath: '/files/todo.txt',
    createdAt: '2023-10-08T16:30:00Z',
    updatedAt: '2023-10-08T16:30:00Z',
    parentId: '1',
  },
]

export const mockBaseQuery: BaseQueryFn = async () => {
  return { data: mockData }
}
