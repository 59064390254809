import { useAppDispatch } from '@/store/store-hooks'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { ChatV2Conversation, ChatV2Feature, chatV2AddConversation } from '@/chat-common/store/chat-v2.slice'
import getDefaultQueryMetadataByFeature from '@/chat-common/schemas/chat-default-schema-values'
import { setLastViewedConvoIdByFeature } from '@/chat-common/store/chat-v2-ux.slice'
import { kConversationPendingPrefix } from '@/constants/constants-ui'

export function useStartNewChat() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const startNewChat = (chatFeature: ChatV2Feature, onStartCallback?: () => void) => {
    const newChatId = kConversationPendingPrefix + nanoid()

    // Construct the pending conversation
    const pendingConversation: ChatV2Conversation = {
      id: newChatId,
      isPending: true,
      isLoading: false,
      feature: chatFeature,
      messages: {},
      created_at: new Date().toISOString(),
      currentSource: getDefaultQueryMetadataByFeature(chatFeature),
      currentTextInput: '',
      isError: false,
      formValidationError: false,
      visibleReference: null,
    }

    // Insert it into state
    dispatch(chatV2AddConversation(pendingConversation))

    // Navigate to the empty pending conversation
    navigate(`/dashboard/chat/${chatFeature}/${pendingConversation.id}`)

    // Set the last viewed conversation id for the feature
    dispatch(setLastViewedConvoIdByFeature({ feature: chatFeature, conversationId: newChatId }))

    // Optional callback
    if (onStartCallback) onStartCallback()
  }

  return { startNewChat }
}
