import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuthContext } from '../../context/auth-context'
import signInEmailPaxton from '../../firebase/auth/sign-in-email-paxton'
import { authErrorTranslate } from '../../firebase/auth/auth-error-translate'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import InlineTipError from '../inline-tips/InlineTipError'
import { FirebaseError } from 'firebase/app'
import * as Sentry from '@sentry/browser'
import { brandedAIFriendlyName } from '@/util/enterprise'

type FormInputs = {
  email: string
  password: string
}

type SignInFormPaxtonProps = {
  onSuccess: () => void
  tooltipMessage?: string
}

export default function SignInFormPaxton(props: SignInFormPaxtonProps) {
  const { onSuccess, tooltipMessage } = props

  const authContext = useContext(AuthContext)
  const user = authContext?.user

  // Redirect if the user is not null
  useEffect(() => {
    if (user != null && !user.isAnonymous) {
      onSuccess()
    }
  }, [onSuccess, user])

  // Local State
  const [validationError, setValidationError] = useState('')
  const [loading, setLoading] = useState(false)

  // Form Hook
  const { register, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  // Sign in error component
  function ValidationErrorMessage() {
    if (validationError != '') {
      return (
        <div>
          <p className="text-center text-red-700">{validationError}</p>
        </div>
      )
    } else {
      return null
    }
  }

  // Handle Sign Up
  async function handleSignIn(data: FormInputs) {
    setValidationError('')
    setLoading(true)
    try {
      await signInEmailPaxton(data.email, data.password)
      // console.log('Signed in user: ', result)

      // onSuccess
      onSuccess()
    } catch (e) {
      if (e instanceof FirebaseError) {
        const validationError = await authErrorTranslate(e, data.email)
        setValidationError(validationError)
      } else {
        setValidationError(`Unexpected error. ${brandedAIFriendlyName} has been notified.`)

        Sentry.captureException(e, {
          extra: {
            email: data.email,
          },
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {tooltipMessage && <div className={'mb-4'}>{InlineTipError(tooltipMessage)}</div>}
      <h2 className="mb-2 text-center text-2xl font-bold tracking-tight text-brand-neutral-900">Sign in</h2>

      <form className="space-y-6" method="POST" onSubmit={handleSubmit((data) => handleSignIn(data))}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email-address"
              {...register('email', { required: true })}
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Email address"
            />
          </div>
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              {...register('password', { required: true })}
              type="password"
              autoComplete="current-password"
              required
              minLength={6}
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Password"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-brand-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
          >
            Sign in
            <ButtonLoader loading={loading} />
          </button>
        </div>

        <ValidationErrorMessage />
      </form>
    </>
  )
}
