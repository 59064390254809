import { Parser } from 'html-to-react'
import { Docx2HtmlCommentTree, Docx2HtmlCommentType } from '../../schema/docx2html.schema'
import CommentCircleWithLetter from './CommentCircleWithLetter'
import { DocumentEditingAnnotationType } from '@/document-editing/schema/document-editing-websocket.schema'
import { createHtmlDiffString } from '@/document-editing/service/document-diffing'
import Docx2HtmlCommentTypeChip from './Docx2HtmlCommentTypeChip'

/**
 * Render Docx2Html Comments Props
 * @param commentGroups is an array of comment arrays. Each comment array represents a top-level comment and its children.
 */
type RenderDocx2HtmlCommentsMobileProps = {
  commentTree: Docx2HtmlCommentTree
  activeCommentId: string
  annotations: DocumentEditingAnnotationType[]
}

// Configure the HTML parser (for diff string)
const htmlParser = Parser()

/**
 * Render Docx2Html Comments
 * Renders a flattened list of comments grouped with the parent.
 * @param props
 * @returns react component
 */
export default function RenderDocx2HtmlCommentsMobile(props: RenderDocx2HtmlCommentsMobileProps) {
  const { commentTree, activeCommentId, annotations } = props

  // Get the active comment
  const thisCommentGroup = commentTree[activeCommentId]
  const commentType = thisCommentGroup.commentType

  // IF the comment is a replacement, get the diff string
  function getCommentDiffString(): string | null {
    // Skip if the comment is not a replacement
    if (commentType !== Docx2HtmlCommentType.replacement) return null

    // Get the annotation for the comment, skip if not found
    const annotation = annotations.find((annotation) => annotation.comment_id === activeCommentId) ?? null
    if (!annotation) return null

    // Strip excessive whitespace and newlines from the source and replacement text
    const cleanedSourceText = annotation.source_text.replace(/\s+/g, ' ').trim()
    const cleanedReplacementText = annotation.replacement_text.replace(/\s+/g, ' ').trim()

    // Create a diff between the source_text and replacement_text
    const diffString = createHtmlDiffString(cleanedSourceText, cleanedReplacementText) ?? null

    return diffString
  }
  const commentDiffString = getCommentDiffString()

  return (
    <div role={'button'} id={`comment-group-${activeCommentId}`} key={`comment-group-${activeCommentId}`}>
      {thisCommentGroup.comments.map((comment, index) => {
        const firstCommentInChain = index === 0

        return (
          <div key={`comment-${comment.id}`} className={'p-3 border-b last-of-type:border-0'}>
            <div className={'flex justify-between'}>
              <div className={'flex items-center gap-x-2 font-bold'}>
                <CommentCircleWithLetter word={comment.author ?? 'Comment'} /> {comment.author ?? 'Comment'}
              </div>
            </div>
            {firstCommentInChain && (
              <div className={'pt-2'}>
                <Docx2HtmlCommentTypeChip commentType={commentType} />
              </div>
            )}
            <div className={'pt-3 mr-2'}>{comment.text}</div>
            {commentDiffString && (
              <>
                <div className={'font-bold mt-3 pl-[1px]'}>Diff:</div>
                <div className={'px-2 py-1 mt-1 mb-2 mr-2 bg-brand-neutral-50 rounded-lg shadow'}>
                  <div>{htmlParser.parse(commentDiffString)}</div>
                </div>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}
