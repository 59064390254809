import { CircularProgressDeterminate } from '@/components/loaders/CircularProgressDeterminate'
import { UploadTaskStatus } from './FilesDropZoneWithTaskState'

interface FileUploadStatusListProps {
  tasks: UploadTaskStatus[]
  scrollRef: React.RefObject<HTMLDivElement>
}

const FileUploadStatusListInChat = (props: FileUploadStatusListProps) => {
  const { tasks, scrollRef } = props

  const statusComponents = tasks.map((value, key) => (
    <div key={key} className="mt-4 grid grid-cols-[auto_30px] items-center">
      <div className="inline-block align-middle text-sm overflow-hidden">
        <p>{value.filePath}</p>

        {value.errorMessage && <p className="text-red-600 text-xs">{value.errorMessage}</p>}
      </div>
      <div className="inline-block align-middle">
        <CircularProgressDeterminate value={value.progress} error={!!value.errorMessage} />
      </div>
    </div>
  ))

  return (
    <div className="overflow-y-auto max-h-96 pr-1 pb-2" ref={scrollRef}>
      {statusComponents}
    </div>
  )
}

export { FileUploadStatusListInChat }
