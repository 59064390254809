import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState, useEffect } from 'react'
import postPartnerCode from '@/payments/fetch/post-partner-code'

type PartnerCodeDialogProps = {
  visible: boolean
  onClose: () => void
}

export default function PartnerCodeDialog(props: PartnerCodeDialogProps) {
  const { visible, onClose } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [partnerCode, setPartnerCode] = useState<string | null>(null)

  useEffect(() => {
    if (visible) {
      setPartnerCode('')
      setError(null)
    }
  }, [visible])

  async function submitPartnerCode() {
    try {
      if (!partnerCode || partnerCode == null) {
        setError('Please enter a code.')
        return
      }
      const response = await postPartnerCode({ partner_code: partnerCode })
      console.log(response)
      if (response.error) {
        setError(response.detail || 'An unknown error occurred.')
        return
      }

      onClose()

      // Refresh the window
      window.location.reload()
    } catch (error) {
      console.error(error)
      setError('There was an error applying your code. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  // Do not show if not visible
  if (!visible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => onClose()}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-md">
        <h2 className="mb-2 text-lg font-semibold">Partner Code</h2>

        <p className="mb-2 italic text-sm text-brand-neutral-600">Submit your code to bypass payment.</p>

        <div className={'h-4'} />
        {/* Partner Code Input */}
        <input
          type="text"
          placeholder="Enter your code"
          className="w-full border border-brand-neutral-300 rounded-md p-2 text-sm text-brand-neutral-900"
          onChange={(e) => setPartnerCode(e.target.value)}
        />
        <div className={'flex gap-x-4 py-4 justify-end items-center'}>
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
            onClick={() => onClose()}
          >
            Cancel
          </button>

          {loading && <CircularProgressContinuousSized size={20} thickness={7} />}
          {!loading && (
            <button
              className="inline-flex justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
              onClick={submitPartnerCode}
            >
              Submit
            </button>
          )}
        </div>
        {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
      </div>
    </div>
  )
}
