import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice.ts'

export enum AnalyticsEvent {
  SignUp = 'SignUp',
  PaymentMethodAdded = 'PaymentMethodAdded',
  PaymentMethodAddedMonthly = 'PaymentMethodAddedMonthly',
  PaymentMethodAddedAnnual = 'PaymentMethodAddedAnnual',
  NewChat = 'NewChat',
  NewChatMessage = 'NewChatMessage',
  OpenExistingConversation = 'OpenExistingConversation',
  OpenConversationSubmenu = 'OpenConversationSubmenu',
  OpenConversationDeletionDialog = 'OpenConversationDeletionDialog',
  ConversationDeleted = 'ConversationDeleted',
  DownloadConversationToDocx = 'DownloadConversationToDocx',
  OpenConversationRetitleDialog = 'OpenConversationRetitleDialog',
  ConversationRetitled = 'ConversationRetitled',
  CopyChatResponse = 'CopyChatResponse',
  DownloadChatResponseToDocx = 'DownloadChatResponseToDocx',
  OpenChatDialog = 'OpenChatDialog',
  FileAnalysisFileUpload = 'FileAnalysisFileUpload',
  BooleanComposerSubmission = 'BooleanComposerSubmission',
  CompareDocumentsSubmission = 'CompareDocumentsSubmission',
  SharePaxtonClicked = 'SharePaxtonClicked',
  DownloadChatSourceToDocx = 'DownloadChatSourceToDocx',
  ReferralDialogOpened = 'ReferralDialogOpened',
  ReferralLinkCopied = 'ReferralLinkCopied',
  ReferralLinkCopiedFromInput = 'ReferralLinkCopiedFromInput',
  ContactUsForEnterpriseClicked = 'ContactUsForEnterpriseClicked',
}

export type AnalyticsEventProperties = {
  [AnalyticsEvent.SignUp]: object
  [AnalyticsEvent.PaymentMethodAdded]: object
  [AnalyticsEvent.NewChat]: {
    feature: ChatV2Feature
  }
  [AnalyticsEvent.NewChatMessage]: {
    feature: ChatV2Feature
  }
  [AnalyticsEvent.OpenExistingConversation]: object
  [AnalyticsEvent.OpenConversationSubmenu]: object
  [AnalyticsEvent.OpenConversationDeletionDialog]: object
  [AnalyticsEvent.ConversationDeleted]: object
  [AnalyticsEvent.DownloadConversationToDocx]: object
  [AnalyticsEvent.OpenConversationRetitleDialog]: object
  [AnalyticsEvent.ConversationRetitled]: object
  [AnalyticsEvent.CopyChatResponse]: object
  [AnalyticsEvent.DownloadChatResponseToDocx]: object
  [AnalyticsEvent.OpenChatDialog]: {
    feature: ChatV2Feature
    option?: string
  }
  [AnalyticsEvent.FileAnalysisFileUpload]: object
  [AnalyticsEvent.BooleanComposerSubmission]: object
  [AnalyticsEvent.CompareDocumentsSubmission]: object
  [AnalyticsEvent.SharePaxtonClicked]: object
  [AnalyticsEvent.DownloadChatSourceToDocx]: object
  [AnalyticsEvent.ReferralDialogOpened]: object
  [AnalyticsEvent.ReferralLinkCopied]: object
  [AnalyticsEvent.ReferralLinkCopiedFromInput]: object
  [AnalyticsEvent.ContactUsForEnterpriseClicked]: object
  [AnalyticsEvent.PaymentMethodAddedMonthly]: object
  [AnalyticsEvent.PaymentMethodAddedAnnual]: object
}
