import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@/store/store'

/**
 * Selector: Conversation Selected Files Content Expanded
 * A selector that retrieves the expanded state of the selected files content for a given conversation.
 */
export const selectConversationSelectedFilesContentExpanded = createSelector(
  // Input selector: Extract 'selectedFilesContentExpanded' for the given conversation from the state
  (state: RootState, props: { chatId: string | undefined }) => state.chatV2UxState.selectedFilesContentExpanded[props.chatId ?? ''] ?? false,
  // Result function: Return the expanded state
  (expanded) => expanded
)

/**
 * Selector: Last Viewed Conversation By Feature
 */
export const selectLastViewedConversationIdRecord = createSelector(
  // Input 1: Extract 'feature' directly from the state
  (state: RootState) => state.chatV2UxState.lastViewedConvoIdByFeature ?? null,

  // Returns the conversation based on the feature.
  (conversation) => conversation
)
/**
 * Selector: Prompt Assist Enabled
 */
export const selectPromptAssistEnabled = (state: RootState) => state.chatV2UxState.promptAssistEnabled
