import { PayloadAction } from '@reduxjs/toolkit'
import { ChatV2State } from './chat-v2.slice'
import { ChatV2QueryMetadataAssistant, ChatV2QueryMetadataAssistantSchema, ModeChoice } from '../schemas/chat-query-metadata-schema'
import { kHiddenFileForFolderName } from '@/constants/constants-gcs'

// Default new assistant source
export const defaultNewAssistantValues: ChatV2QueryMetadataAssistant = {
  selected_files: [],
  focus_mode: ModeChoice.ASSISTANT, //Focus choice to BE, but mode to FE - TODO: Check with BE if is ok to change the variable name in BE to keep consistent.
}

// Toggle a selected file
export const reducerCurrentSourceAssistantToggleSelectedFile = (
  state: ChatV2State,
  action: PayloadAction<{ conversationId: string; filePath: string; remove?: boolean }>
) => {
  const { conversationId, filePath, remove } = action.payload

  if (filePath.endsWith(kHiddenFileForFolderName)) {
    //File is a .keep file, skipping.
    return
  }

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataAssistantSchema.safeParse(conversation.currentSource)

  if (conversation.currentSource == null || validation.success) {
    // Get the existing currentSource and updated it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataAssistant

    // If currentSource exists, update it and set state
    if (currentSource) {
      if (remove || currentSource.selected_files.includes(filePath)) {
        currentSource.selected_files = currentSource.selected_files.filter((file) => file !== filePath)
      } else {
        currentSource.selected_files = Array.from(new Set([...currentSource.selected_files, filePath]))
      }
    }
    if (!validation.success) {
      throw new Error(`Attempting to ToggleSelectedFile for wrong metadata type: ${validation.error}`)
    }
  }
}

// Add file if not already in selected files
export const reducerCurrentSourceAssistantAddFile = (state: ChatV2State, action: PayloadAction<{ conversationId: string; filePath: string }>) => {
  const { conversationId, filePath } = action.payload

  if (filePath.endsWith(kHiddenFileForFolderName)) {
    //File is a .keep file, skipping.
    return
  }

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataAssistantSchema.safeParse(conversation.currentSource)

  if (conversation.currentSource == null || validation.success) {
    // Get the existing currentSource and updated it with the new source_name
    const currentSource = conversation.currentSource as ChatV2QueryMetadataAssistant

    // If currentSource exists, update it and set state
    if (currentSource) {
      if (!currentSource.selected_files.includes(filePath)) {
        currentSource.selected_files = Array.from(new Set([...currentSource.selected_files, filePath]))
      }
    }
    if (!validation.success) {
      throw new Error(`Attempting to AddFile for wrong metadata type: ${validation.error}`)
    }
  }
}

//Set focus value
export const reducerCurrentSourceAssistantSetFocus = (
  state: ChatV2State,
  action: PayloadAction<{
    conversationId: string
    focusMode: ModeChoice
  }>
) => {
  const { conversationId, focusMode } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataAssistantSchema.safeParse(conversation.currentSource)

  if (!validation.success) throw new Error(`Attempting to SetFocus for wrong metadata type`)
  // Set focus choice
  if (conversation.currentSource === null && validation.success) {
    conversation.currentSource = {
      focus_mode: focusMode as ModeChoice,
    }
  } else {
    conversation.currentSource = {
      ...conversation.currentSource,
      focus_mode: focusMode as ModeChoice,
    }
  }
}

// Clear all selected files
export const reducerCurrentSourceAssistantClearAllFiles = (state: ChatV2State, action: PayloadAction<{ conversationId: string }>) => {
  const { conversationId } = action.payload

  // Get state conversation reference
  const conversation = state.conversations[conversationId]

  // Validate currentSource against schema
  const validation = ChatV2QueryMetadataAssistantSchema.safeParse(conversation.currentSource)

  if (conversation.currentSource == null || validation.success) {
    // Set currentSource selected_files to empty array
    conversation.currentSource = {
      ...conversation.currentSource,
      selected_files: [],
    }
  } else {
    throw new Error(`Attempting to ToggleSelectedFile for wrong metadata type: ${validation.error}`)
  }
}
