import { useGetSourceLrrQuery } from '@/chat-common/api/source-apis'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kLrrSourceSanitizationAllowedTags } from '@/constants/constants-ui'
import { Parser } from 'html-to-react'
import sanitizeHtml from 'sanitize-html'
import { escapeSpecialCharsFromHtmlSourceForSnippet } from './reference-view-utils'
import { findAll } from 'highlight-words-core'
import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewLrrProps = {
  conversationId: string
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}

export default function ReferenceViewContentLrr(props: ReferenceViewLrrProps) {
  const { conversationId, setReferenceHtmlCallback } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))

  // Configure the HTML parser
  const htmlParser = Parser()

  // Fetch the reference HTML string with RTK-Query
  const { data: sourceHtmlData, isLoading: sourceHtmlIsLoading, isError: sourceHtmlIsError, refetch } = useGetSourceLrrQuery(reference?.metadata.id ?? '')

  // Local state
  const [processedHtml, setProcessedHtml] = useState('')

  // Format Sanitize / Highlight  the HTML every time the sourceHtmlData changes
  useEffect(() => {
    if (!sourceHtmlData || !reference) return

    // Sanitize the HTML
    const sanitizedHtml = sanitizeHtml(sourceHtmlData, {
      allowedTags: kLrrSourceSanitizationAllowedTags,
      transformTags: { pre: 'p', code: 'p' },
    })

    // Generate highlight snippets from the text source
    const snippets = reference.text
      .split('\n')
      .map((s) => s.trim())
      .slice(2)
      .join(' ')
      .split('.')
      .filter((sentence) => sentence.length > 45)
      .map((sentence) => escapeSpecialCharsFromHtmlSourceForSnippet(sentence))

    // Find all snippets to highlight
    const chunks = findAll({
      caseSensitive: false,
      searchWords: snippets,
      textToHighlight: sanitizedHtml,
    })

    // Format the HTML to wrap highlighted snippets in a span with highlight styles
    const formattedHTML = chunks
      .map((chunk: { end: any; highlight: any; start: any }) => {
        const { end, highlight, start } = chunk
        const text = sanitizedHtml.slice(start, end)
        if (highlight) {
          return `<span class="highlighted-snippet bg-yellow-300">${text}</span>`
        } else {
          return text
        }
      })
      .join('')

    setProcessedHtml(formattedHTML)
    setReferenceHtmlCallback?.(formattedHTML)
  }, [sourceHtmlData])

  // If no reference
  if (!reference) return <div>No reference selected</div>

  // Show loading indicator
  if (sourceHtmlIsLoading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (sourceHtmlIsError || !sourceHtmlData)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <button
          className={
            'flex-auto items-center rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
          }
          onClick={refetch}
        >
          Retry
        </button>
      </>
    )

  return (
    <div id="lrr-reference" ref={containerRef} className={'reference-view-html text-sm overflow-y-scroll'}>
      {htmlParser.parse(processedHtml)}
    </div>
  )
}
