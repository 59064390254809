import { SourceReferenceSchema } from '@/store/store-schema'
import ResponseReference from './ResponseReference'

type ResponseReferencesWrapperProps = {
  sectionTitle: string
  sources: SourceReferenceSchema[]
  responseHighlightedFootnote: number
  display: boolean
  onShowSourceClick?: (source: SourceReferenceSchema) => void
}

export function footnoteAnchorIdFromIndex(index: number) {
  return `footnote-anchor-${index}`
}

const ResponseReferencesWrapper: React.FC<ResponseReferencesWrapperProps> = (props: ResponseReferencesWrapperProps) => {
  const { sectionTitle, sources, display, responseHighlightedFootnote, onShowSourceClick } = props

  if (display == false || sources == null || sources.length == 0) return null

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-brand-neutral-900">{sectionTitle}</h3>
      <div className="">
        {sources.map((source, index) => (
          <ResponseReference key={index} source={source} responseHighlightedFootnote={responseHighlightedFootnote} onShowSourceClick={onShowSourceClick} />
        ))}
      </div>
    </>
  )
}

export default ResponseReferencesWrapper
