import { UploadFile } from '@mui/icons-material'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import uploadFileHandler, { UploadableFile } from './upload-file-handler'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { FileUploadStatusPopover } from './FileUploadStatusPopover'
import { kAcceptedFilesList } from '@/constants/constants-gcs'
import { StorageReference } from 'firebase/storage'
import { kSegmentTrackFileUploadStarted } from '@/constants/constants-segment'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { UploadDisclaimer } from '@/constants/constants-components'

export type UploadTaskStatus = {
  progress: number
  complete: boolean
  errorMessage: string
  uploadableFile: UploadableFile
}

export type FilesDropZoneProps = {
  onTaskListItemUpdated: (task: UploadTaskStatus, allTasks: Map<string, UploadTaskStatus>) => void
  currentFolder: StorageReference | null
  hideUploadProgressOverlay?: boolean
  singleFileLimit?: boolean
  showFolderName?: boolean
}

export interface FilesDropZoneRef {
  openSystemFileSelector: () => void
}

const FilesDropZone = function FilesDropZone(props: FilesDropZoneProps) {
  const { onTaskListItemUpdated, currentFolder, singleFileLimit, hideUploadProgressOverlay = false } = props
  const { trackEvent } = useAnalytics()

  const [uploadTasks, setUploadTasks] = useState(new Map<string, UploadTaskStatus>())
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [dropError, setDropError] = useState<string | null>(null)

  // NO CURRENT NEED FOR THIS
  // // When all uploads are complete, call the onSingleFileComplete
  // useEffect(() => {
  //   if (uploadTasks.size === 0) return

  //   const allTasksComplete = Array.from(uploadTasks.values()).every((task) => task.complete)
  //   if (allTasksComplete) {
  //     onAllFilesComplete(uploadTasks)
  //   }
  // }, [uploadTasks, onAllFilesComplete])

  // On Drop
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setDropError(null)

      if (acceptedFiles.length === 0) {
        console.log('No accepted files to upload.')
        return
      }

      // Track
      analytics.track(kSegmentTrackFileUploadStarted, {
        fileCount: acceptedFiles.length,
      })
      // Open the upload progress drawer
      setDrawerOpen(true)

      acceptedFiles.forEach(async (file) => {
        // Create a unique persistent id for the file
        const fileId = nanoid(22)

        // Create an Uploadable File
        const uploadableFile: UploadableFile = {
          file: file,
          fileName: file.name,
          id: fileId,
        }

        // Initialize the task and add it to state
        const newUploadTask: UploadTaskStatus = {
          progress: 0,
          complete: false,
          errorMessage: '',
          uploadableFile: uploadableFile,
        }
        setUploadTasks((prevTasks) => new Map(prevTasks).set(newUploadTask.uploadableFile.id, newUploadTask))

        // Create a new upload task for this file and pass event handlers to update state based on upload progress and status
        uploadFileHandler({
          uploadableFile,
          currentFolder,
          onProgress: function (progress: number): void {
            setUploadTasks((prevTasks) => {
              const thisFileStatus = prevTasks.get(uploadableFile.id)
              if (thisFileStatus === undefined) {
                Sentry.captureException(new Error(`thisFileStatus is undefined, cannot update file upload task onProgress().`))
                return prevTasks // return current state if no updates are made
              }

              const updatedTask: UploadTaskStatus = {
                ...thisFileStatus,
                progress: progress,
                complete: false,
                errorMessage: '',
              }

              // Update the list of all tasks
              const updatedTasks = new Map(prevTasks).set(uploadableFile.id, updatedTask)

              // Call the onTaskListItemUpdated callback with the updated task
              Promise.resolve().then(() => onTaskListItemUpdated(updatedTask, updatedTasks))

              return updatedTasks
            })
          },
          onError: function (error: Error): void {
            setUploadTasks((prevTasks) => {
              const thisFileStatus = prevTasks.get(uploadableFile.id)
              if (thisFileStatus === undefined) {
                Sentry.captureException(new Error(`thisFileStatus is undefined, cannot update file upload task onError(). Error message: ${error.message}`))
                return prevTasks
              }

              const updatedTask: UploadTaskStatus = {
                ...thisFileStatus,
                errorMessage: 'Error uploading file',
              }

              // Update the list of all tasks
              const updatedTasks = new Map(prevTasks).set(uploadableFile.id, updatedTask)

              // Call the onTaskListItemUpdated callback with the updated task
              Promise.resolve().then(() => onTaskListItemUpdated(updatedTask, updatedTasks))

              return updatedTasks
            })
          },
          onComplete: function (): void {
            // Set it as completed
            setUploadTasks((prevTasks) => {
              const thisFileStatus = prevTasks.get(uploadableFile.id)
              if (thisFileStatus === undefined) {
                Sentry.captureException(new Error('thisFileStatus is undefined, cannot update file upload task onComplete().'))
                return prevTasks
              }

              const updatedTask: UploadTaskStatus = {
                ...thisFileStatus,
                complete: true,
              }

              // Update the list of all tasks
              const updatedTasks = new Map(prevTasks).set(uploadableFile.id, updatedTask)

              // Call the onTaskListItemUpdated callback with the updated task
              Promise.resolve().then(() => onTaskListItemUpdated(updatedTask, updatedTasks))

              trackEvent(AnalyticsEvent.FileAnalysisFileUpload)

              // Return the updated state
              return updatedTasks
            })
          },
        })
      })
    },
    [currentFolder, onTaskListItemUpdated] // depend on this and use latest version when running
  )

  // On Drop Rejected
  const onDropRejected = (fileRejections: any) => {
    console.log('FileRejections: ', fileRejections)
    if (singleFileLimit && fileRejections.length > 1) {
      setDropError('Please upload a single file. Use your drive to upload multiple files.')
      return
    }

    fileRejections.forEach((fileRejection: any) => {
      const extensionMatch = fileRejection?.file?.path.match(/\.[0-9a-z]+$/i)
      const extension = extensionMatch ? extensionMatch[0] : 'unknown'
      const errorMessage = `Unsupported file type ${extension}`

      // Create a unique persistent id for the file
      const fileId = nanoid(22)

      // Create an Uploadable File
      const uploadableFile: UploadableFile = {
        file: fileRejection.file,
        fileName: fileRejection.file.name,
        id: fileId,
      }

      // Initialize the task and add it to state
      const newUploadTask: UploadTaskStatus = {
        progress: 0,
        complete: false,
        errorMessage: errorMessage,
        uploadableFile: uploadableFile,
      }

      setUploadTasks((prevTasks) => new Map(prevTasks).set(newUploadTask.uploadableFile.id, newUploadTask))
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: kAcceptedFilesList,
    maxFiles: singleFileLimit ? 1 : undefined,
  })

  return (
    <>
      <div
        className={`p-4 rounded-lg border-2 border-brand-500 transition-all duration-300 ${isDragActive ? 'bg-brand-50' : 'border-opacity-20 '} border-dashed`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <div className={'text-center'}>
          <div className={'mb-3'}>
            <UploadFile />
          </div>
          <p>
            Drag & Drop or <span className={'text-brand-500 underline cursor-pointer'}>Choose{singleFileLimit ? ' a File' : ' Files'}</span>
          </p>
          <p className={'mt-1 text-sm text-brand-neutral-600'}>Supported formats {Object.values(kAcceptedFilesList).flat().join(', ')}</p>
          <UploadDisclaimer />
          {dropError && <p className={'mt-1 text-sm text-red-700'}>{dropError}</p>}
        </div>
      </div>
      {!hideUploadProgressOverlay && (
        <FileUploadStatusPopover tasks={uploadTasks} expanded={drawerOpen} setExpanded={(newValue: boolean) => setDrawerOpen(newValue)} />
      )}
    </>
  )
}

export default FilesDropZone
