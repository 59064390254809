import Skeleton from '@mui/material/Skeleton'
import { useMemo } from 'react'

type SkeletonLoaderProps = {
  lines?: number // Optional number of lines (default to 4)
  addHeader?: boolean // Optional flag to add a header (default to false)
}

export default function SkeletonLoader({ lines = 4, addHeader = false }: SkeletonLoaderProps) {
  // Generate random widths between 50% and 80%
  const barsWidths = useMemo(() => {
    return Array.from({ length: lines }, () => {
      const min = 50 //min 50% width
      const max = 80 //max 80% width
      const width = Math.random() * (max - min) + min
      return `${width}%`
    })
  }, [lines])
  return (
    <>
      {addHeader && <Skeleton variant="text" width="15%" height={30} />}
      {barsWidths.map((w, index) => (
        <Skeleton key={index} variant="text" width={w} height={20} />
      ))}
    </>
  )
}
