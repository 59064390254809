import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuthContext } from '../../context/auth-context'
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail, ActionCodeSettings } from 'firebase/auth'
import { ButtonLoader } from '@/components/loaders/ButtonLoader'
import { kPaxtonAppBaseDomain } from '@/constants/constants-links'
import { FirebaseError } from 'firebase/app'
import { authErrorTranslate } from '@/firebase/auth/auth-error-translate'

type FormInputs = {
  email: string
}

type SendPasswordResetFormProps = {
  goToSignIn?: () => void
  formTitle: string
  formDescription: string
  buttonText: string
}

export default function SendPasswordResetForm(props: SendPasswordResetFormProps) {
  const { goToSignIn, formTitle, formDescription, buttonText } = props
  const [resetEnabled, setResetEnabled] = useState(true) // Add this line to create the state object and set it to true initially

  const authContext = useContext(AuthContext)
  const user = authContext?.user

  // Redirect if the user is not null
  useEffect(() => {
    if (user != null && !user.isAnonymous && goToSignIn != null) {
      goToSignIn()
    }
  }, [goToSignIn, user])

  // Local State
  const [validationError, setValidationError] = useState<string>('')
  const [loading, setLoading] = useState(false)

  // Form Hook
  const { register, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      email: '',
    },
  })

  // Sign in error component
  function ValidationErrorMessage() {
    if (validationError != '') {
      return (
        <div>
          <p className="text-center text-red-700 text-sm">{validationError}</p>
        </div>
      )
    } else {
      return null
    }
  }

  // Execute the password reset flow
  async function executePasswordResetFlow(data: FormInputs) {
    setValidationError('')
    setLoading(true)
    try {
      const auth = getAuth()
      const methods = await fetchSignInMethodsForEmail(auth, data.email)
      if (methods.some((method) => method !== 'password')) {
        setValidationError(
          'This account is connected with Single Sign-on and cannot be reset. Please use your connected account to login or contact support if you still have questions.'
        )
      } else {
        const actionCodeSettings: ActionCodeSettings = {
          url: `${kPaxtonAppBaseDomain()}/dashboard`,
        }

        await sendPasswordResetEmail(auth, data.email, actionCodeSettings)
      }
      setResetEnabled(false)
    } catch (e: any) {
      if (e instanceof FirebaseError) {
        const errorMessage = await authErrorTranslate(e, data.email)
        setValidationError(errorMessage)
      } else {
        setValidationError('Unknown error.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <h2 className="mb-2 text-center text-2xl font-bold tracking-tight text-brand-neutral-900">{formTitle}</h2>
      <p className="text-center text-sm text-brand-neutral-600 mb-2">{formDescription}</p>
      <form className="space-y-6" method="POST" onSubmit={handleSubmit((data) => executePasswordResetFlow(data))}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-brand-neutral-900">
            Email address
          </label>
          <div className="">
            <input
              id="email-address"
              {...register('email', { required: true })}
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 pl-3 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
              placeholder="Email address"
            />
          </div>
        </div>

        <ValidationErrorMessage />

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-brand-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
            disabled={!resetEnabled}
            style={{ cursor: resetEnabled ? 'pointer' : 'not-allowed' }} // Add this line to change the cursor
          >
            {resetEnabled ? buttonText : 'Email Sent'}
            <ButtonLoader loading={loading} />
          </button>

          {goToSignIn && (
            <button
              type="button"
              className="flex mt-4 w-full justify-center rounded-md bg-brand-neutral-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-neutral-600"
              onClick={() => goToSignIn()}
            >
              Back to Sign In
            </button>
          )}
        </div>
      </form>
    </>
  )
}
