import { FirebaseError } from 'firebase/app'
import { fetchSignInMethodsForEmail, getAuth } from 'firebase/auth'
import * as Sentry from '@sentry/browser'
import { brandedAIFriendlyName } from '@/util/enterprise'

export async function authErrorTranslate(error: FirebaseError, email: string): Promise<string> {
  console.log('Error code: ', error.code)

  // Tenant ID related auth error (legacy, should not be encountered in the future.)
  if (error.customData?.tenantId) {
    return `Authentication error. ${brandedAIFriendlyName} has been notified.`
  }

  switch (error.code) {
    case 'auth/user-not-found': {
      return 'Email not found. Please sign up.'
    }
    case 'auth/email-already-exists': {
      if (email) {
        try {
          const providerError = await checkProviderError(email)
          if (providerError != null) {
            return providerError
          }
        } catch (e) {
          Sentry.captureException(new Error(`Error running checkProviderError for auth/email-already-exists.`))
          return 'Unexpected error'
        }
      }

      return 'This email is already registered.'
    }
    case 'auth/email-already-in-use': {
      if (email) {
        try {
          const providerError = await checkProviderError(email)
          if (providerError != null) {
            return providerError
          }
        } catch (e) {
          Sentry.captureException(new Error(`Error running checkProviderError for auth/email-already-in-use.`))
          return 'Unexpected error'
        }
      }

      return 'This email is already registered.'
    }
    case 'auth/invalid-email': {
      return 'This email is not valid.'
    }
    case 'auth/invalid-password': {
      return 'The provided password is not valid. Passwords should be at least 6 characters.'
    }
    case 'auth/weak-password': {
      return 'The provided password is not valid. Passwords should be at least 6 characters.'
    }
    case 'auth/wrong-password': {
      if (email) {
        const providerError = await checkProviderError(email)
        if (providerError != null) {
          return providerError
        }
      }

      return 'The email password combination is incorrect.'
    }
    case 'auth/too-many-requests': {
      return 'Too many requests. Please wait a few minutes.'
    }
    case 'auth/network-request-failed': {
      return 'Network error. Please try again.'
    }
    case 'auth/user-disabled': {
      return 'Unable to sign in this account. Please contact support.'
    }

    default: {
      console.error(`Unexpected auth translate error from email: ${email}: `, error)

      // The switch handled errors are generally login experience errors we do not need to report to sentry
      // This is a catch all for unexpected errors
      Sentry.captureException(new Error(`Unexpected firebase auth error. Code: ${error.code}`), {
        extra: {
          firebaseError: JSON.stringify(error),
          email: email,
        },
      })
      return 'Unexpected error.'
    }
  }
}

async function checkProviderError(email: string): Promise<string | null> {
  const auth = getAuth()
  const result = await fetchSignInMethodsForEmail(auth, email)
  const provider = result[0]

  console.log('provider:', provider)

  if (typeof provider == 'undefined') {
    return null
  }

  switch (provider) {
    case 'google.com': {
      return 'This email was registered with Google.'
    }
    default: {
      return null
    }
  }
}
