import { useContext } from 'react'
import { FileOverwriteModalContext } from './file-overwrite-modal-context'

export const useFileOverwriteModal = () => {
  const context = useContext(FileOverwriteModalContext)
  if (!context) {
    throw new Error('useFileOverwriteModal must be used within a FileOverwriteModalProvider')
  }
  return context
}
