import { CircularProgress } from '@mui/material'
import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { AuthContext } from '@/context/auth-context'
import { setLegacyStripeCheckoutVisible, setLegacyStripeCheckoutMessage, clearLegacyStripeCheckoutMessage } from '@/store/slices/ui-state.slice'
import { useAppDispatch } from '@/store/store-hooks'
import createStripeSubscription from '@/payments/fetch/post-stripe-subscription'
import createHoldPayment from '@/payments/fetch/post-stripe-hold-charge'
import type { createHoldPaymentRequest } from '@/payments/fetch/post-stripe-hold-charge'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import * as Sentry from '@sentry/react'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'
import { BillingCycle } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function SubscriptionPending() {
  const { trackEvent } = useAnalytics()

  const stripe = useStripe()
  const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const { userAccountData } = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const billingCycle = (new URLSearchParams(window.location.search).get('billingCycle') as BillingCycle) ?? BillingCycle.MONTHLY
  const promotionIdParam = new URLSearchParams(window.location.search).get('promotion')

  useEffect(() => {
    console.log('SetupIntent running')
    if (!stripe || !clientSecret) {
      return
    }
    retrieveSetup(stripe, clientSecret, billingCycle)
  }, [stripe, clientSecret])

  useEffect(() => {
    if (userAccountData?.validSubscription) {
      navigate('/dashboard')
    }
  }, [userAccountData])

  function buildHoldPaymentRequest({ setupIntentID }: { setupIntentID: string }): createHoldPaymentRequest {
    const userAgent = window.navigator.userAgent
    return { setupIntentID, userAgent }
  }

  function handleHoldPaymentFailure(error: any) {
    if (error != 'Error: Prepaid card not supported') {
      Sentry.captureException(new Error(`Customer checkout: Hold payment creation failed.`), {
        extra: {
          holdPaymentError: error,
        },
      })
    }
    dispatch(setLegacyStripeCheckoutMessage(`${error}. \n Please try again or use an alternative payment method.`))
    navigate('/dashboard')
    return undefined
  }

  async function sendHoldPayment({ setupIntentID: setupIntentID }: { setupIntentID: string }): Promise<{ paymentIntentID: string } | undefined> {
    try {
      clearLegacyStripeCheckoutMessage()
      const holdPaymentArgs = buildHoldPaymentRequest({ setupIntentID })
      const response = await createHoldPayment(holdPaymentArgs)
      return { paymentIntentID: response.payment_intent_id }
    } catch (error) {
      handleHoldPaymentFailure(`${error}`)
    }
  }

  function handleSubscriptionCreationFailure(error: string) {
    Sentry.captureException(new Error(`Customer checkout: Subscription creation failed.`), {
      extra: {
        subscriptionCreationError: error,
      },
    })
    if (userAccountData?.expiredPaymentMethod) {
      dispatch(
        setLegacyStripeCheckoutMessage(
          'Our attempt to restart your subscription failed. Please try again using the Stripe portal. If the issue persists, please contact support.'
        )
      )
    } else {
      dispatch(setLegacyStripeCheckoutMessage('Our attempt to create a subscription failed. Please try again using the Stripe portal.'))
      dispatch(setLegacyStripeCheckoutVisible(true))
    }
    navigate('/dashboard')
    return undefined
  }

  function handleSetupIntentError(error: any) {
    Sentry.captureException(new Error(`Customer checkout: An error occurred retrieving your subscription intent.`), {
      extra: {
        retrieveSetupIntentError: error,
      },
    })
    setError('An error occurred retrieving your subscription intent.')
  }

  async function createSubscription({
    setup_intent_id,
    payment_intent_id,
    billing_cycle,
  }: {
    setup_intent_id: string
    payment_intent_id: string
    billing_cycle: string
  }) {
    try {
      console.log('Creating subscription')
      const { subscription_id } = await createStripeSubscription({
        setup_intent_id,
        payment_intent_id,
        billing_cycle,
        ...(promotionIdParam && { promotion_id: promotionIdParam }),
      })
      return { subscription_id }
    } catch (error) {
      handleSubscriptionCreationFailure(`${error}`)
    }
  }

  async function retrieveSetup(stripe: Stripe, clientSecret: string, billingCycle: string) {
    console.log('Retrieving setup intent')
    const { setupIntent, error: retrieveSetupIntentError } = await stripe.retrieveSetupIntent(clientSecret)

    if (setupIntent == undefined || setupIntent == null) {
      Sentry.captureException('Setup intent is null')
      return
    }

    switch (setupIntent.status) {
      case 'succeeded': {
        console.log('Setup intent succeeded')
        console.log('Creating subscription')
        const result = await sendHoldPayment({ setupIntentID: setupIntent.id })
        if (!result) break
        const { paymentIntentID: payment_intent_id } = result
        await createSubscription({ setup_intent_id: setupIntent.id, payment_intent_id, billing_cycle: billingCycle })
        trackEvent(AnalyticsEvent.PaymentMethodAdded)
        if (billingCycle === BillingCycle.MONTHLY) {
          trackEvent(AnalyticsEvent.PaymentMethodAddedMonthly)
        } else if (billingCycle === BillingCycle.ANNUAL) {
          trackEvent(AnalyticsEvent.PaymentMethodAddedAnnual)
        }
        break
      }
      case 'processing': {
        console.log('Setup intent processing')
        await new Promise((resolve) => setTimeout(resolve, 2000))
        await retrieveSetup(stripe, clientSecret, billingCycle)
        break
      }

      case 'requires_payment_method':
        setError('Your payment method could not be processed.')
        dispatch(setLegacyStripeCheckoutVisible(true))
        navigate('/dashboard')
        break
    }

    if (retrieveSetupIntentError) {
      handleSetupIntentError(retrieveSetupIntentError)
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-[300px]" src={brandLogoUrl} alt={hostBrand} />
        <div className="mt-10 text-center">{!error && <CircularProgress size={24} thickness={5} />}</div>
        {error && <div className="mt-4 text-center text-sm text-red-600">{error}</div>}
      </div>
    </div>
  )
}
