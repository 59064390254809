import SendPasswordResetForm from '@/components/auth/send-password-reset-form'
import { useNavigate } from 'react-router-dom'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function PasswordCreatePage() {
  const navigate = useNavigate()

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-[200px] sm:w-[280px]" src={brandLogoUrl} alt={hostBrand} />
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-brand-neutral-50 px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <SendPasswordResetForm
              goToSignIn={() => navigate('/sign-in')}
              formTitle="Create Password"
              formDescription="You will receive an email with a link to set your password."
              buttonText="Submit"
            />
          </div>
        </div>
      </div>
    </>
  )
}
