import * as Sentry from '@sentry/browser'
import { IdTokenResult, User } from 'firebase/auth'
import { SubscriptionStatus, SubscriptionType, InferredPollingState, SurveyStatus, BrandEnum, BillingCycle } from './auth-jwt-schema'
import { daysUntilFromUnix } from '@/util/time'
import { OrgData, getOrgData } from './org-services/get-org-data'
import { getBrandFromHost, isPaxtonBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()

export class UserAccountData {
  private tokenResult: IdTokenResult
  private user: User
  orgData: OrgData | null = null

  constructor(user: User, tokenResult: IdTokenResult, orgData: OrgData | null = null) {
    this.user = user
    this.tokenResult = tokenResult
    this.orgData = orgData
  }
  // Factory async constructor that allows the implementation to await the idTokenResult fetching
  // Always forces a token refresh
  static async create(user: User): Promise<UserAccountData | null> {
    try {
      const tokenResult = await user.getIdTokenResult(true)

      // If the user has an organization_id, then fetch the orgData
      const organizationId = (tokenResult?.claims?.organization_id as string) ?? null

      const orgData = organizationId ? await getOrgData() : null
      return new UserAccountData(user, tokenResult, orgData)
    } catch (error) {
      Sentry.captureException(new Error(`Error Constructing UserAccountData`), {
        extra: {
          error: error,
          errorStringified: JSON.stringify(error),
          user: JSON.stringify(user),
        },
      })

      return null // or handle the error as appropriate for your application
    }
  }

  //============================================================================
  // GETTERS
  //============================================================================
  get isAnonymous(): boolean {
    return this.user.isAnonymous
  }

  get inferredPollingState(): InferredPollingState {
    if (this.user.isAnonymous) {
      return InferredPollingState.NONE
    }

    if (this.subscriptionStatus === SubscriptionStatus.NONE) {
      return InferredPollingState.POLL_SUBSCRIPTION
    }

    if (
      this.subscriptionStatus === SubscriptionStatus.PRE_TRIAL ||
      this.subscriptionStatus === SubscriptionStatus.EXPIRED ||
      this.subscriptionStatus === SubscriptionStatus.DELETED
    ) {
      return InferredPollingState.POLL_PAYMENT
    }

    return InferredPollingState.NONE
  }

  get surveyCompleted(): boolean {
    return this.tokenResult.claims.surveyStatus === SurveyStatus.COMPLETED
  }

  get isOrgAccount(): boolean {
    return this.tokenResult.claims.organization_id ? true : false
  }

  get isOrgAdmin(): boolean {
    return this.isOrgAccount && this.tokenResult.claims.role === 'admin'
  }

  get legacyDriveCompatibleOrganizationId(): string | null {
    return (this.tokenResult.claims.organization_id as string) ?? null
  }

  get role(): string | null {
    return (this.tokenResult.claims.role as string) ?? null
  }

  // Get the user's subscription status
  // Initialize as .NONE
  get subscriptionStatus(): SubscriptionStatus {
    // If the user has a orgData, just return organization
    if (this.orgData) return SubscriptionStatus.ORGANIZATION

    const subscriptionStatus = this.tokenResult.claims.subscription_status
    if (Object.values(SubscriptionStatus).includes(subscriptionStatus as SubscriptionStatus)) {
      return subscriptionStatus as SubscriptionStatus
    } else {
      return SubscriptionStatus.NONE
    }
  }

  // Get pre-determined subscription states useful for UX rendering
  // When the user has a valid subscription
  get validSubscription(): boolean {
    return (
      this.orgData?.has_access === true ||
      this.subscriptionStatus === SubscriptionStatus.TRIAL ||
      this.subscriptionStatus === SubscriptionStatus.PAID ||
      this.subscriptionStatus === SubscriptionStatus.PARTNER ||
      this.subscriptionStatus === SubscriptionStatus.PAXTON
    )
  }

  // When the status suggests we are still pending a subscription (possibly user is created but not yet subscribed)
  get pendingSubscription(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.NONE
  }

  get pendingPaymentMethod(): boolean {
    return (
      this.subscriptionStatus === SubscriptionStatus.PRE_TRIAL ||
      this.subscriptionStatus === SubscriptionStatus.EXPIRED ||
      this.subscriptionStatus === SubscriptionStatus.DELETED
    )
  }

  get expiredPaymentMethod(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.EXPIRED || this.subscriptionStatus === SubscriptionStatus.DELETED
  }

  // When the status suggests we need to show the paywall
  get invalidSubscription(): boolean {
    return (
      this.orgData?.has_access === false || this.subscriptionStatus === SubscriptionStatus.EXPIRED || this.subscriptionStatus === SubscriptionStatus.DELETED
    )
  }

  // Get the user's subscription type
  get subscriptionType(): SubscriptionType {
    // IF the email address ends in .edu, then the subscriptionType must be education
    const regex = new RegExp(/\.edu$/)

    return regex.test(this.user.email ?? '') ? SubscriptionType.EDUCATION : SubscriptionType.STANDARD
  }

  get billingCycle(): BillingCycle {
    // existing monthly users will not have a billing cycle set in their claims, so default to monthly
    return (this.tokenResult.claims.billing_cycle as BillingCycle) ?? BillingCycle.MONTHLY
  }

  // Get the number of days until the trial ends
  get trialEnd(): number {
    const trialExp = this.tokenResult.claims.trial_exp

    // Validate that it's a number
    if (typeof trialExp !== 'number') {
      return 0
    }

    return daysUntilFromUnix(trialExp)
  }

  // Get whether the user has a default payment method
  get defaultPayment(): boolean {
    const defaultPayment = this.tokenResult.claims.default_payment

    // If undefined
    if (typeof defaultPayment === 'undefined') {
      return false
    }

    // If not a boolean
    if (typeof defaultPayment !== 'boolean') {
      console.error(`Invalid defaultPayment - not a boolean: ${defaultPayment}`)
      return false
    }

    return defaultPayment
  }

  get brand(): BrandEnum {
    // Get brand from from claims else host
    return hostBrand
  }

  get isPaxton(): boolean {
    return isPaxtonBrand(this.brand)
  }
}
