import { kPaymentsPreAuthorizationPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/react'

export type createHoldPaymentRequest = {
  setupIntentID: string
  userAgent: string
}

type createHoldPaymentResponse = {
  payment_intent_id: string
  success: boolean
  status: string
  error?: boolean
  errorDetail?: string
}

export default async function createHoldPayment({
  setupIntentID: setup_intent_id,
  userAgent: user_agent,
}: createHoldPaymentRequest): Promise<createHoldPaymentResponse> {
  const sentryTransactionId = nanoid()
  const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsPreAuthorizationPath}`

  try {
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)
    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentryTransactionId,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        setup_intent_id,
        user_agent,
      }),
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok || !data) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentryTransactionId })

        Sentry.captureException(new Error(`createHoldPayment: !response.ok || !data, status: ${response.status}`), {
          extra: {
            response: data,
            data: JSON.stringify(data),
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error('Failed to create pre-authorization.', data) // maintain previous throwing behavior for ux
    }

    if (data.success == false || data.error != null || data.error != undefined) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentryTransactionId })

        Sentry.captureException(
          new Error(`createHoldPayment: data.success == false || data.error != null || data.error != undefined, status: ${response.status}`),
          {
            extra: {
              response: data,
              data: JSON.stringify(data),
              requestUrl: apiUrl,
              requestOptions: requestOptions,
              onLine: navigator.onLine,
              cookieEnabled: navigator.cookieEnabled,
            },
          }
        )
      })
      throw new Error(data.error) // maintain previous throwing behavior for ux
    }

    return data
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentryTransactionId })

      Sentry.captureException(new Error(`createHoldPayment: fetch error`)),
        {
          extra: {
            error: JSON.stringify(e),
            requestUrl: apiUrl,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        }
    })

    // Rethrow error to maintain previous throwing behavior for ux
    throw e
  }
}
