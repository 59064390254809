import ChatControlsCaseLaw from './caselaw/chat-controls-caselaw'
import ChatControlsDocumentQuery from './chat-controls-documentquery'
import ChatControlsLRR from './chat-controls-lrr'
import ChatControlsDraftingChat from './chat-controls-drafting'
import ChatControlsWebsearch from './chat-controls-websearch'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatControlsContractAnalysis from './chat-controls-contractanalysis'
import ChatControlsContractSearch from './chat-controls-contractsearch'
import ChatControlsDocumentSummarization from './chat-controls-documentsummarization'
import ChatControlsResearch from './chat-controls-research'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationFeature } from '@/chat-common/store/chat-v2.selectors'
import ChatControlsAssistant from './assistant/chat-controls-assistant'

type ChatControlsProps = {
  conversationId: string
}

/**
 * Chat Controls
 *
 * For a given conversation, loads the appropriate:
 * - Source selection dialog
 * - Form controls
 * @returns
 */
export default function ChatControls(props: ChatControlsProps) {
  const { conversationId } = props

  // Feature
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  switch (true) {
    case feature === ChatV2Feature.lrr_v2:
      return <ChatControlsLRR conversationId={conversationId} />

    case feature === ChatV2Feature.caselaw:
      return <ChatControlsCaseLaw conversationId={conversationId} />

    case feature === ChatV2Feature.research:
      return <ChatControlsResearch conversationId={conversationId} />

    case feature === ChatV2Feature.documentquery:
      return <ChatControlsDocumentQuery conversationId={conversationId} />

    case feature == ChatV2Feature.websearch:
      return <ChatControlsWebsearch conversationId={conversationId} />

    case feature == ChatV2Feature.drafting:
      return <ChatControlsDraftingChat conversationId={conversationId} />

    case feature == ChatV2Feature.contractanalysis:
      return <ChatControlsContractAnalysis conversationId={conversationId} />

    case feature == ChatV2Feature.contractsearch:
      return <ChatControlsContractSearch />

    case feature === ChatV2Feature.documentSummarization:
      return <ChatControlsDocumentSummarization conversationId={conversationId} />

    case feature === ChatV2Feature.assistant:
      return <ChatControlsAssistant conversationId={conversationId} />

    default:
      console.error(`No chat form controls defined for feature ${feature}.`)
      return null
  }
}
