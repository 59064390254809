import { ChatV2QueryMetadataCaseLaw } from '@/chat-common/schemas/chat-query-metadata-schema'
import { chatV2SetCaseLawDateRange, ChatV2Feature, chatV2SetCaseLawIncludeUnpublishedCases } from '@/chat-common/store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'
import CaseLawStateDialog from '../../source-dialogs/caselaw/caselaw-state-dialog'
import CaseLawFederalDialog from '../../source-dialogs/caselaw/caselaw-federal-dialog'
import { CourtsResponse, useGetAllCourtsQuery } from '@/store/apis/hosted-filters-api'
import { FilterList } from '@mui/icons-material'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { countSelectedFederalCourts, countSelectedStateCourts } from '../../source-dialogs/caselaw/caselaw-court-utils'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import DateRangeSelector from './caselaw-date-range-selector'
import IncludeUnpublishedCasesPopover from '../../source-dialogs/caselaw/include-unpublished-cases-popover'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type ChatControlsCaseLawProps = {
  conversationId: string
}

export default function ChatControlsCaseLaw(props: ChatControlsCaseLawProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Local UX state
  const [stateDialogOpen, setStateDialogOpen] = useState(false)
  const [federalDialogOpen, setFederalDialogOpen] = useState(false)

  // Source information from the conversation
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataCaseLaw | null
  const selectedCourts = currentSource?.court_ids ?? []
  const yearLow = currentSource?.year_low ?? null
  const yearHigh = currentSource?.year_high ?? null
  const includeUnpublishedCases = currentSource?.include_unpublished_cases // Default is false

  // Get All Courts Query
  const { data: courtsData, isLoading: courtsLoading, isError: isCourtsError } = useGetAllCourtsQuery()

  const stateCourts = courtsData?.State ?? null
  const federalCourts = courtsData?.Federal ?? null

  // Loading and error states
  if (courtsLoading) return <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
  if (isCourtsError || !stateCourts || !federalCourts) return <div className={'text-red-700 text-sm'}>Error loading courts.</div>

  // Filter Counter
  enum FilterTypeEnum {
    STATE,
    FEDERAL,
  }
  function FilterCounter(props: { type: FilterTypeEnum }) {
    const { type } = props

    const selectedStateCourtsCount = countSelectedStateCourts(courtsData as CourtsResponse, selectedCourts)
    const selectedFederalCourtsCount = countSelectedFederalCourts(courtsData as CourtsResponse, selectedCourts)

    // If both are empty, all are selected
    if (selectedStateCourtsCount == 0 && selectedFederalCourtsCount == 0) {
      return <span className={'text-sm text-brand-neutral-500'}>(all)</span>
    }

    // If this is the indicator for STATE and it is 0, but federal is not 0, then none of state are selected
    else if (type == FilterTypeEnum.STATE && selectedStateCourtsCount == 0 && selectedFederalCourtsCount != 0) {
      return <span className={'text-sm text-brand-neutral-500'}>(0)</span>
    }

    // If this is the indicator for FEDERAL and it is 0, but state is not 0, then none of federal are selected
    else if (type == FilterTypeEnum.FEDERAL && selectedFederalCourtsCount == 0 && selectedStateCourtsCount != 0) {
      return <span className={'text-sm text-brand-neutral-500'}>(0)</span>
    }

    // If state, show state count
    else if (type == FilterTypeEnum.STATE) {
      return <span className={'text-sm text-brand-neutral-500'}>({selectedStateCourtsCount})</span>
    }

    // If federal, show federal count
    else if (type == FilterTypeEnum.FEDERAL) {
      return <span className={'text-sm text-brand-neutral-500'}>({selectedFederalCourtsCount})</span>
    }

    // Other cases
    else {
      return <></>
    }
  }

  function handleDateRangeChange(yearLow: number | null, yearHigh: number | null) {
    dispatch(chatV2SetCaseLawDateRange({ conversationId: conversationId, yearLow, yearHigh }))
  }

  return (
    <>
      {/* Dialogs */}
      <CaseLawStateDialog
        conversationId={conversationId}
        open={stateDialogOpen}
        courts={courtsData as CourtsResponse}
        onClose={() => {
          setStateDialogOpen(false)
        }}
      />
      <CaseLawFederalDialog
        conversationId={conversationId}
        open={federalDialogOpen}
        courts={courtsData as CourtsResponse}
        onClose={() => {
          setFederalDialogOpen(false)
        }}
      />

      {/* Form Controls */}
      <div className={'flex flex-wrap gap-2'}>
        <div>
          <p className="block text-sm font-bold text-brand-neutral-700">Filter courts</p>
          <div className={'flex flex-wrap gap-2'}>
            <button
              className="px-3 py-1 mt-1 text-sm bg-brand-neutral-50 hover:bg-brand-neutral-100 rounded-md ring-1 ring-inset ring-brand-neutral-300 whitespace-nowrap"
              onClick={() => {
                trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.caselaw, option: 'state' })
                setStateDialogOpen(true)
              }}
            >
              <FilterList fontSize="small" />
              &nbsp;State&nbsp;courts&nbsp;
              <FilterCounter type={FilterTypeEnum.STATE} />
            </button>
            <button
              className="px-3 py-1 mt-1 text-sm bg-brand-neutral-50 hover:bg-brand-neutral-100 rounded-md ring-1 ring-inset ring-brand-neutral-300 whitespace-nowrap"
              onClick={() => {
                trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.caselaw, option: 'federal' })
                setFederalDialogOpen(true)
              }}
            >
              <FilterList fontSize="small" />
              &nbsp;Federal&nbsp;courts&nbsp;
              <FilterCounter type={FilterTypeEnum.FEDERAL} />
            </button>
          </div>
        </div>
        <DateRangeSelector conversationId={conversationId} yearLow={yearLow} yearHigh={yearHigh} setDateRange={handleDateRangeChange} />

        <div>
          <p className="block text-sm font-bold text-brand-neutral-700">
            Unpublished cases <IncludeUnpublishedCasesPopover />
          </p>
          <div className={'flex flex-wrap gap-2 mt-1'}>
            <input
              id="include-unpublished-cases-checkbox"
              name="include-unpublished-cases-checkbox"
              type="checkbox"
              checked={includeUnpublishedCases}
              onChange={(event) => {
                dispatch(chatV2SetCaseLawIncludeUnpublishedCases({ conversationId: conversationId, includeUnpublishedCases: event.target.checked }))
              }}
              className="h-5 w-5 mt-1 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
            />
            <label htmlFor="include-unpublished-cases-checkbox" className="mt-1 text-sm text-brand-neutral-600">
              Include
            </label>
          </div>
        </div>
      </div>
    </>
  )
}
