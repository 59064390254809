import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { chatV2CurrentSourceContractAnalysisClearAll, chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile, chatV2CurrentSourceContractAnalysisToggleSelectedFile } from '@/chat-common/store/chat-v2.slice'
import { selectConversationFeature } from '@/chat-common/store/chat-v2.selectors'
import { SourceSelectionDialog } from './source-selection-dialog'

type SelectionDialogContractAnalysisProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * SelectionDialogContractAnalysis component.
 *
 * This component is used exclusively in the Contract Analysis features
 * to allow the user to select documents for the Contract Analysis to use by wrapping the SourceSelectionDialog component with the appropriate dispatch functions.
 *
 * @param props - The properties for the SelectionDialogContractAnalysis component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function SelectionDialogContractAnalysis(props: SelectionDialogContractAnalysisProps) {
  const { open, onClose, conversationId } = props

  const dispatch = useAppDispatch()

  const featureName = useAppSelector((state) => selectConversationFeature(state, { chatId: conversationId }))

  const addFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile({ conversationId: conversationId, filePath: file }))
  }

  const toggleFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceContractAnalysisToggleSelectedFile({ conversationId: conversationId, filePath: file }))
  }

  const clearFilesSelection = () => {
    dispatch(chatV2CurrentSourceContractAnalysisClearAll({ conversationId: conversationId }))
  }

  return <SourceSelectionDialog conversationId={conversationId} open={open} onClose={onClose} featureName={featureName} allowMultipleSelection={false} dispatchFunctions={{
    addFileSelection,
    clearFilesSelection,
    toggleFileSelection,
  }} />
}
