import { lazy, Suspense } from 'react'
import { CircularProgressContinuousSized } from '../loaders/CircularProgressContinuous'
import { PaxtonHighlightArea } from './schema/paxton-highlight-area'

// Lazy load ReactPdfWorkerWrapper
const PdfWorkerWrapper = lazy(() => import('./components/pdf-worker-wrapper'))

type RenderReactPdfViewerProps = {
  highlightAreas: PaxtonHighlightArea[]
  pdfFile: File
}

export default function RenderReactPdfViewer(props: RenderReactPdfViewerProps): JSX.Element {
  const { highlightAreas, pdfFile } = props

  return (
    <Suspense
      fallback={
        <div className={'flex items-center gap-x-1'}>
          Loading PDF libraries... <CircularProgressContinuousSized size={12} thickness={7} />
        </div>
      }
    >
      <PdfWorkerWrapper pdfFile={pdfFile} highlightAreas={highlightAreas} />
    </Suspense>
  )
}
