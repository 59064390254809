import { StorageReference, ref } from 'firebase/storage'
import FilesDropZone, { UploadTaskStatus } from './FilesDropZone'
import { getStorage } from 'firebase/storage'
import { useParams } from 'react-router-dom'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getAuth } from '@firebase/auth'
import FilesList from './FilesList'
import { useContext, useState } from 'react'
import { AuthContext } from '@/context/auth-context'
import { kFeatureFlags, kGetFeatureFlagStatus } from '@/constants/constants-feature-flags'
import FileDriveList from '../file-drive/components/FilesDriveList'

export default function FilesPage() {
  const { driveName, '*': folderPath } = useParams()
  // console.log(`Drive: ${driveName}, Folder Path:`, folderPath)

  // Reload trigger state
  const [reloadTrigger, setReloadTrigger] = useState(0)

  // Context data
  const { userAccountData } = useContext(AuthContext)

  // Get the current user
  const auth = getAuth()
  const uid = auth.currentUser?.uid
  const organizationId = userAccountData?.legacyDriveCompatibleOrganizationId

  // Derive the currentFolder
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const driveRootPath = driveName === 'org-drive' && organizationId ? `tenants/${organizationId}` : `users/${uid}`
  const uriFolderPath = folderPath ? folderPath : ''
  const currentFolderRef: StorageReference | null = ref(storage, `${driveRootPath}/${uriFolderPath}`)

  // Check if URL contains file-drive to show new file driver
  // TODO: Remove this when new file driver is ready
  //http://localhost:5173/dashboard/drives/my-drive&file-drive-2.0
  const showNewFileDriver = window.location.pathname.search('file-drive-2.0') !== -1

  // If currentFolderRef is null, we have an storage reference and cannot render
  if (!currentFolderRef) {
    console.error(`Invalid storage reference for drive: ${driveName}, folderPath: ${folderPath}`)

    return (
      <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0'}>
        <div className="bg-brand-neutral-50 shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6 text-red-700">Error: Invalid storage location</div>
        </div>
      </div>
    )
  }

  if (kGetFeatureFlagStatus(kFeatureFlags.FILE_DRIVE) && showNewFileDriver) {
    return (
      <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0'}>
        <div className="bg-brand-neutral-50 shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6 text-red-700">New File Driver is under development</div>
          <FileDriveList />
        </div>
      </div>
    )
  }
  return (
    <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0'}>
      <div className="bg-brand-neutral-50 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <FilesDropZone
            onTaskListItemUpdated={(uploadTask: UploadTaskStatus, allTasks: Map<string, UploadTaskStatus>) => {
              console.log('File upload completed: ', uploadTask.uploadableFile.fileName)

              // Get number of completed tasks to reload when number of completed uploads changes
              const completedTasks = Array.from(allTasks.values()).filter((task) => task.complete)
              setReloadTrigger(completedTasks.length)
            }}
            currentFolder={currentFolderRef}
          />
        </div>
      </div>
      <div className="flex-grow overflow-y-scroll mt-2 bg-brand-neutral-50 shadow rounded-lg">
        <div className="">
          <FilesList reloadTrigger={reloadTrigger} currentFolderRef={currentFolderRef} />
        </div>
      </div>
    </div>
  )
}

