import { Elements } from '@stripe/react-stripe-js'
import React from 'react'
import SubscriptionPending from './SubscriptionPending'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { loadStripe } from '@stripe/stripe-js'

const SubscriptionPendingContainer: React.FC = () => {
  const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')

  const appearance = {
    theme: 'stripe' as const,
    variables: {
      colorPrimary: '#2A49FF',
      colorBackground: '#fff',
      colorText: '#374151',
      colorDanger: '#b91c1c',
      fontFamily: 'ui-sans-serif, system-ui, sans-serif',
    },
  }

  const options = {
    clientSecret: clientSecret || undefined,
    appearance,
  }

  if (!clientSecret) {
    return <CircularProgressContinuous />
  }

  return (
    <Elements options={options} stripe={loadStripe(import.meta.env.VITE_PUBLISHABLE_STRIPE_KEY)}>
      <SubscriptionPending />
    </Elements>
  )
}

export default SubscriptionPendingContainer
