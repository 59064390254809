import { kDefaultLogoPath, kHaikuLogoPath } from '@/constants/constants-ui'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { UserCredential, OAuthCredential, AuthCredential } from 'firebase/auth'
import onboardBrandOAuth from '@/organizations/fetch/onboard-brand-sso'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { Dispatch, AnyAction } from '@reduxjs/toolkit'
import { GlobalToastType } from '@/constants/constants-ui'
import { useSearchParams } from 'react-router-dom'

const haikuTenant = import.meta.env.VITE_DOMAIN_HAIKU_ENTRA_TENANT
const isPaxtonList = [BrandEnum.NONE, BrandEnum.PAXTON]
export const brandedAIFriendlyName = getBrandName()
export const brandedAIName = getBrandName(true)

export function getBrandFromHost() {
  const host = window.location.host
  // if (host.includes('localhost')) {
  //   return BrandEnum.HAIKU
  // }
  if (host.includes('haiku')) {
    return BrandEnum.HAIKU
  }
  return BrandEnum.PAXTON
}

export function getLogoFromBrand(brand: BrandEnum | null = null) {
  if (!brand || brand === BrandEnum.NONE) {
    brand = getBrandFromHost()
  }
  if (brand === BrandEnum.HAIKU) {
    return kHaikuLogoPath
  }
  return kDefaultLogoPath
}

export function getBrandName(ai = false) {
  const brand = getBrandFromHost()
  if (brand === BrandEnum.HAIKU) {
    return 'Haiku'
  }
  return ai ? 'Paxton AI' : 'Paxton'
}

/**
 * Determines if user is from Paxton.
 * @param brand If null, will use the brand from the host
 * @returns
 */
export function isPaxtonBrand(brand: BrandEnum | null = null) {
  if (!brand || brand === BrandEnum.NONE) {
    brand = getBrandFromHost()
  }
  return isPaxtonList.includes(brand)
}

/**
 * Checks the current host to determine if a Microsoft tenant ID is needed.
 * @returns Microsoft tenant ID to restrict who can login to a URL
 */
export function getMicrosoftTenantId() {
  const hostBrand = getBrandFromHost()

  if (hostBrand === BrandEnum.HAIKU) {
    return haikuTenant
  }

  return 'common'
}

export function isTenantSSO() {
  return getMicrosoftTenantId() !== 'common'
}

export async function validateSSOCredentials(credential: UserCredential | OAuthCredential | AuthCredential, dispatch: Dispatch<AnyAction> | null = null) {
  let valid = true
  if (credential?.providerId === 'saml.haiku' && isTenantSSO()) {
    valid = await onboardBrandOAuth()
  }

  if (!valid) {
    const authFailed = 'Authentication Failed, Please try again.'
    if (dispatch != null) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: authFailed, durationMs: 2000 }))
    }
    throw new Error(authFailed)
  }
}

export function isHaikuHost() {
  return getBrandFromHost() === BrandEnum.HAIKU
}

/**
 * Generic function to check if the URL has an override query param set to 'paxton'
 * @returns boolean
 */
export function overridePaxton() {
  const [searchParams] = useSearchParams()
  return searchParams.get('override') === 'paxton'
}

export function getHelpCenterUrl(suffix: string = '') {
  if (isHaikuHost()) {
    return 'https://hklaw.service-now.com/sp?id=kb_search&kb_knowledge_base=14da4f5f47089a50ef0fa341516d4368&spa=1'
  }
  return 'https://help.paxton.ai/help' + (suffix !== '' ? '/' + suffix : '')
}
