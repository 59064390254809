import { z } from 'zod'

/**
 * React PDF Viewer Highlight Area
 * Extended with additional properties for Paxton
 * @extends HighlightArea from react-pdf-viewer library
 * @property {string} sentenceId - Allows UX scrolling to sentence locations highlighted (multiple highlights per sentence)
 */

// Create the initial zod schema based on the the react-pdf-viewer HighlightArea
// A test is used to ensure this stays in sync with the library through any updates
export const reactPdfViewerHighlightAreaSchema = z.object({
  height: z.number(),
  left: z.number(),
  pageIndex: z.number(),
  top: z.number(),
  width: z.number(),
})

// Extend the schema with the additional Paxton property that the backend provides with the highlight data
export const paxtonHighlightAreaSchema = reactPdfViewerHighlightAreaSchema.extend({
  sentenceId: z.string(),
})
export type PaxtonHighlightArea = z.infer<typeof paxtonHighlightAreaSchema>
