import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type UserAppConfig = {
  organization: string
  theme: string
  locale: string
}

// Define a type for the slice state
interface UIState {
  loadingUserAppConfig: boolean
  userAppConfig: UserAppConfig | null
}

// Define the initial state using that type
const initialState: UIState = {
  loadingUserAppConfig: false,
  userAppConfig: null,
}

export const appInitSlice = createSlice({
  name: 'appInitState',
  initialState,
  reducers: {
    setLoadingUserAppConfig: (state, action: PayloadAction<boolean>) => {
      state.loadingUserAppConfig = action.payload
    },
    setUserAppConfig: (state, action: PayloadAction<UserAppConfig>) => {
      state.userAppConfig = action.payload
    },
  },
})

export const { setLoadingUserAppConfig, setUserAppConfig } = appInitSlice.actions

export default appInitSlice.reducer
