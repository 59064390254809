import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kGcsCaseLawSourceBucketRef } from '@/constants/constants-gcs'
import { kCaseLawSourceSanitizationAllowedTags } from '@/constants/constants-ui'
import { getAuth } from 'firebase/auth'
import { getBlob, getStorage, ref } from 'firebase/storage'
import { findAll } from 'highlight-words-core'
import { Parser } from 'html-to-react'
import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react'
import sanitizeHtml from 'sanitize-html'
import { escapeSpecialCharsFromHtmlSourceForSnippet } from './reference-view-utils'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectVisibleReference } from '@/chat-common/store/chat-v2.selectors'

type ReferenceViewCaseLawProps = {
  conversationId: string
  setReferenceHtmlCallback?: Dispatch<SetStateAction<string>>
}

export default function ReferenceViewContentCaseLaw(props: ReferenceViewCaseLawProps) {
  const { conversationId, setReferenceHtmlCallback } = props
  const auth = getAuth()
  const storage = getStorage(undefined, kGcsCaseLawSourceBucketRef)
  const containerRef = useRef<HTMLDivElement>(null)

  const reference = useAppSelector((state: RootState) => selectVisibleReference(state, { chatId: conversationId }))

  // Local state
  const [loading, setLoading] = useState(false)
  const [fetchError, setFetchError] = useState(false)
  const [htmlString, setHtmlString] = useState('')

  // Define the function to load the HTML file
  async function fetchSanitizeFormatHtmlSource(reference: ChatV2MessageReferenceType) {
    console.log('Fetching reference', reference)

    setFetchError(false)
    setHtmlString('')

    const { metadata } = reference
    const caseId = metadata.case_id
    const uid = auth.currentUser?.uid

    // Null checks
    if (!caseId) {
      console.error('case_id is missing from reference metadata.', reference)
      setFetchError(true)
      return
    }

    if (!uid) {
      console.error('uid is missing from auth.currentUser', auth.currentUser)
      setFetchError(true)
      return
    }

    // Load the HTML file from Firebase storage
    try {
      setLoading(true)

      // Construct the case source path
      const gcsPath = `${uid}/${caseId}.html`

      // Create the storage reference
      const storageRef = ref(storage, gcsPath)

      // Download the blob directly
      const blob: Blob = await getBlob(storageRef)
      const blobType = blob.type

      // Make sure it's text / html before proceeding
      if (blobType !== 'text/plain' && blobType !== 'text/html') {
        console.error(`Cannot render blob of this type: ${blob.type}`, blob)
        setFetchError(true)
        return
      }

      // Get blob text
      const blobText = await blob.text()

      // Sanitize the string
      const sanitizedHtml = sanitizeHtml(blobText, {
        allowedTags: kCaseLawSourceSanitizationAllowedTags,
        transformTags: { pre: 'p', code: 'p' },
      })

      // Generate highlight snippets from the text source
      const snippets = reference.text
        .split('\n')
        .map((s) => s.trim())
        .slice(2)
        .join(' ')
        .split('.')
        .filter((sentence) => sentence.length > 45)
        .map((sentence) => escapeSpecialCharsFromHtmlSourceForSnippet(sentence))

      // Find all snippets to highlight
      const chunks = findAll({
        caseSensitive: false,
        searchWords: snippets,
        textToHighlight: sanitizedHtml,
      })

      // Format the HTML to wrap highlighted snippets in a span with highlight styles
      const formattedHTML = chunks
        .map((chunk: { end: any; highlight: any; start: any }) => {
          const { end, highlight, start } = chunk
          const text = sanitizedHtml.slice(start, end)
          if (highlight) {
            return `<span class="highlighted-snippet bg-yellow-300">${text}</span>`
          } else {
            return text
          }
        })
        .join('')

      setHtmlString(formattedHTML)
      setReferenceHtmlCallback?.(formattedHTML)
    } catch (e) {
      console.error(e)
      setFetchError(true)
    } finally {
      setLoading(false)
    }
  }

  // Fetch the Reference Blob whenever the reference changes
  useEffect(() => {
    if (!reference) return

    // Call the function to load and set the HTML content
    fetchSanitizeFormatHtmlSource(reference)
  }, [reference])

  // If no reference
  if (!reference) return <div>No reference selected</div>

  // Show loading indicator
  if (loading) return <CircularProgressContinuousSized size={18} thickness={7} />

  // Show error
  if (fetchError)
    return (
      <>
        {InlineTipError('Could not load this reference.')}
        <button
          className={
            'flex-auto items-center rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
          }
          onClick={() => fetchSanitizeFormatHtmlSource(reference)}
        >
          Retry
        </button>
      </>
    )

  // Configure the HTML parser
  const htmlParser = Parser()

  return (
    <div id="case-law-source" ref={containerRef} className={'reference-view-html text-sm overflow-y-scroll py-2'}>
      {htmlParser.parse(htmlString)}
    </div>
  )
}
