import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { prepareRtkQueryHeaders, transformRtkQueryResponse } from '@/store/apis/rtk-query-utils'
import { TokenLimitResponse, TokenLimitResponseSchema } from '@/routes/dashboard/files/schema/limit.schema'
import { kTokenLimitApiPath } from '@/constants/constants-api-paths'

export const limitApi = createApi({
  reducerPath: 'limitApi',
  baseQuery: fetchBaseQuery({
    baseUrl: kPaxtonAppApiBaseUrl(),
    prepareHeaders: async (headers) => await prepareRtkQueryHeaders(headers),
  }),
  endpoints: (builder) => ({
    getTokenLimit: builder.query<TokenLimitResponse, void>({
      query: () => ({
        url: kTokenLimitApiPath,
        method: 'GET',
      }),
      keepUnusedDataFor: 6600, // 1 hour and 50 minutes
      transformResponse: (response: TokenLimitResponse) => {
        const validatedResponse = TokenLimitResponseSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data
      },
      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
  }),
})

export const { useGetTokenLimitQuery } = limitApi
