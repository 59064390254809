import { chatV2CurrentSourceContractAnalysisClearAll, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { Source } from '@mui/icons-material'
import { useState } from 'react'
import { ChatV2QueryMetadataContractAnalysis } from '@/chat-common/schemas/chat-query-metadata-schema'
import SelectionDialogContractAnalysis from '../source-dialogs/selection-dialog-contractanalysis'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type ChatControlsContractAnalysisProps = {
  conversationId: string
}

export default function ChatControlsContractAnalysis(props: ChatControlsContractAnalysisProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Local state
  const [sourceWindowOpen, setSourceWindowOpen] = useState<boolean>(false)

  // Source information from the conversation
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataContractAnalysis | null
  const selectedFile = currentSource?.file_path ?? ''

  // Format selected files to only show their names
  const fileName = selectedFile.split('/').pop()

  return (
    <div className={'overflow-hidden max-w-full'}>
      <SelectionDialogContractAnalysis conversationId={conversationId} open={sourceWindowOpen} onClose={(value) => setSourceWindowOpen(value)} />
      <div className={'flex gap-x-3 items-center'}>
        <button
          onClick={() => {
            trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.contractanalysis })
            setSourceWindowOpen(true)
          }}
          className="rounded-md text-sm px-0 font-semibold text-brand-500 hover:underline"
        >
          Select existing contract <Source fontSize="small" />
        </button>
      </div>
      {fileName != '' && (
        <div className={'pt-1 text-sm text-brand-neutral-600 max-h-12 overflow-scroll items-start grid grid-cols-[24px_auto] overflow-x-hidden break-all'}>
          <button
            className={'pt-[2px]'}
            onClick={() => {
              dispatch(chatV2CurrentSourceContractAnalysisClearAll({ conversationId: conversationId }))
            }}
          >
            <CrossCircledIcon className={'h-[18px] w-[18px] mr-2 align-top'} />
          </button>
          <div className={'pt-[1px]'}>{fileName}</div>
        </div>
      )}
      {fileName == '' && <div className={'text-sm text-red-700 text-left'}>Select or upload a contract</div>}
      <div className={'pt-2 text-sm text-brand-neutral-500 leading-tight'}>
        Provide context including the purpose of the contract, the names of the parties, and your role.
      </div>
    </div>
  )
}
