import { kErrorCode404, kErrorCode429, kErrorCode503, kErrorUnknown, translateError } from '@/constants/constants-error-codes'
import * as Sentry from '@sentry/browser'

/**
 * @param streamErrorSetter is the function that will set a stream error in the redux store state
 */
type QueryStreamErrorHandlerArgs = {
  error: any
  query: string
  skipSentry?: boolean
  streamErrorSetter: (message: string) => void
}

/**
 * Query Stream Response Thrower
 * Throws errors for various query stream response situations
 */
export async function queryStreamResponseThrower(response: Response) {
  // Throw other errors generically and include the full response
  if (!response.ok) {
    // Capture to sentry
    Sentry.withScope(() => {
      Sentry.captureException(new Error(`Query stream error - status: ${response.status}`), {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })

    if (response.status === 404) {
      throw Error(kErrorCode404)
    }
    if (response.status === 429) {
      throw Error(kErrorCode429)
    }
    if (response.status === 503) {
      throw Error(kErrorCode503)
    } else {
      throw Error(kErrorUnknown)
    }
  }
}

/**
 * Query Stream Error Handler
 * A universal approach to handling fetch errors that can be used across functions for consistent error handling
 */
export function queryStreamErrorHandler(args: QueryStreamErrorHandlerArgs) {
  const { error, query, skipSentry = false, streamErrorSetter } = args

  // Handle NOT ONLINE errors in UX and return without reporting to sentry
  if (!navigator.onLine) {
    // Call the stream error setter
    streamErrorSetter('You are offline. Please check your internet connection and try again.')
    return
  }

  // Translate the error
  const translatedError = translateError(error)

  // Call the stream error setter with the translated error
  streamErrorSetter(translatedError)

  // If NOT skipping sentry
  if (!skipSentry) {
    // Capture the exception with Sentry using the original error and the query
    Sentry.captureException(error, {
      extra: {
        translatedError,
        query,
      },
    })
  }

  return
}
