import React from 'react'
import { ReactElement } from 'react'
import ChatFootnotePopover from '../components/chat-footnote-popover'
import { ChatV2Message } from '../store/chat-v2.slice'
import referenceMetadataToTitle from './reference-metadata-to-title'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import { rehypeCustomFootnotes } from '@/util/rehype-custom-plugins/rehype-custom-footnotes'
import rehypeTagNameChange from '@/util/rehype-custom-plugins/rehype-tag-swap'
/**
 * Format Response String
 * Isolates the response footnotes as clickable buttons that trigger a popover
 * @param response
 * @param onFootnoteClick
 * @returns
 */
export default function chatV2FormatResponseString(message: ChatV2Message, highlightedReferenceNumber: string): ReactElement {
  const { metadata } = message

  // Footnote numbers source of truth from metadata
  const actualFootnotes = Object.keys(metadata.references)

  // Trim whitespace from the response
  const trimmedResponse = message.text.trim()

  function extractText(node: React.ReactNode): string {
    let text = ''
    React.Children.forEach(node, (child) => {
      if (typeof child === 'string') {
        text += child
      } else if (React.isValidElement(child) && child.props.children) {
        text += extractText(child.props.children)
      }
    })
    return text
  }
  return (
    <Markdown
      className={'markdown-bubble-container max-w-3xl'}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[
        rehypeRaw,
        [rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }],
        rehypeCustomFootnotes,
        [
          // Swap pre and code tags with div tags to fix markdown rendering issues
          rehypeTagNameChange,
          [
            { tagName: 'pre', newTagName: 'div' },
            { tagName: 'code', newTagName: 'div' },
          ],
        ],
      ]}
      components={{
        // This span override is used to render the footnote span as a ChatFootnotePopover component
        // This component is used to render a clickable button that triggers a popover
        // Markdown is unable to accept react components so we must manually place our ChatFootnotePopover component
        // The replacement is done by checking if the span has a data attribute 'data-footnote'
        // Spans with the 'data-footnote' attribute come from the rehypeCustomFootnotes plugin
        // Go to src/chat-common/util/chat-remark-footnote.tsx to see how the plugin works
        div: ({ node, children, ...props }) => {
          // map the children and if there is a child string that contains any more than 2 '/n' for example
          // "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" then repalce it with a max of a 2 new line '\n\n'
          const newLineRegex = /\n{2,}/g
          const filteredChildren = Array.isArray(children)
            ? children.map((child) => {
                if (typeof child === 'string') {
                  return child.replace(newLineRegex, '\n\n')
                }
                return child
              })
            : children

          return <div {...props}>{filteredChildren}</div>
        },
        li: ({ node, children, ...props }) => {
          // filter the children and if there is a child string of '\n' then remove it
          const filteredChildren = Array.isArray(children) ? children.filter((child) => child !== '\n') : children

          return <li {...props}>{filteredChildren}</li>
        },
        span: ({ node, ...props }) => {
          if ('data-footnote' in props) {
            const footnoteIndex = props['data-footnote'] as string
            const isValid = actualFootnotes.includes(footnoteIndex)
            if (!isValid) {
              return (
                // <span key={`${footnoteIndex}-invalid`} className="text-brand-neutral-500">
                //   {props.children}
                // </span>

                <ChatFootnotePopover
                  referenceRecordKey={footnoteIndex}
                  footnoteText={extractText(props.children)} // Pass extracted text
                  content={'Reference unavailable.\nThis reference number may be available in previous messages.'}
                  message={message}
                  highlight={false}
                  invalid={true}
                />
              )
            }

            const reference = message.metadata.references[footnoteIndex]
            const referenceContent = referenceMetadataToTitle(reference)
            const highlight = highlightedReferenceNumber === reference.reference_number

            const footnoteText = extractText(props.children) // Extract text from children

            return (
              <ChatFootnotePopover
                referenceRecordKey={footnoteIndex}
                footnoteText={footnoteText} // Pass extracted text
                content={referenceContent}
                message={message}
                highlight={highlight}
              />
            )
          }

          // Render regular spans as usual if they don't have the footnote data attribute
          return <span {...props}>{props.children}</span>
        },
      }}
    >
      {trimmedResponse}
    </Markdown>
  )
}
