import { useState } from 'react'
import { useValidatePromoCodeQuery } from '@/routes/dashboard/subscribe/store/subscription.slice.ts'

const PromoCode = () => {
  const [inputValue, setInputValue] = useState('')
  const [promoCode, setPromoCode] = useState('')

  const { error, data, isFetching } = useValidatePromoCodeQuery(promoCode, {
    skip: !promoCode,
  })

  const handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (inputValue && inputValue.length >= 3) {
      setPromoCode(inputValue)
    }
  }

  const invalid = error || data?.success === false

  return (
    <div className="flex flex-col gap-2 mb-4 mt-4">
      <label className="flex text-sm font text-brand-neutral-700">Promo Code</label>
      <div className="flex items-center justify-between flex-row gap-2">
        <input
          className={`rounded-md w-2/3 border-brand-neutral-300 border-1 focus:border-brand-300 focus:ring-brand-300 ${invalid ? 'border-red-700 border-2' : ''}`}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          className="cursor-pointer font-medium text-sm leading-6 text-brand-neutral-600 hover:text-brand-neutral-700 bg-brand-neutral-200 hover:bg-brand-neutral-300 px-4 py-2 rounded-md"
          onClick={handleApply}
          disabled={isFetching || inputValue.length < 3}
        >
          {isFetching ? 'Validating...' : 'Apply'}
        </button>
      </div>
      {invalid && <label className="flex text-red-700 text-sm">Invalid promo code.</label>}
      {data?.success && !invalid && <span className="flex text-green-700">Promo code applied.</span>}
    </div>
  )
}

export default PromoCode
