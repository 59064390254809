import DocumentEditingRenderDocument from './DocumentEditingRenderDocument'
import DocumentEditingSessionTopBar from './DocumentEditingSessionTopBar'
import { CircularProgressContinuous, CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useEffect, useState } from 'react'
import { getMarkdownFileFromGcs } from '../apis/get-markdown-file-from-gcs'
import { DocumentEditingSession } from '../store/document-editing.models'

type DocumentEditingSessionProps = {
  editingSession: DocumentEditingSession
}

/**
 * Document Editing Session
 * This is the primary wrapper component for Document Editing Session data.
 *
 * Data Methods:
 * - Websocket: After a new document editing session is created, a websocket query stream is created to handle the
 *              document editing session data. This listens for events and updates the store with the new data.
 *
 * - RTK-Query: The editing session data is fetched via RTK-Query and stored in the store.
 *
 * IF DATA EXISTS: Data will exist when this component is loaded because the /new editing session page will load
 * the editing session into the store before navigating to this page.
 *
 * IF DATA DOES NOT EXIST: If there is no data, it suggests that we need to fetch an existing editing session from the server.
 * RTK-Query will handle fetching the data.
 *
 * RENDERING: The UX will load inferred values prioritizing editingSession websocket data over RTK-Query data.
 * @param props
 * @returns
 */
export default function DocumentEditingSessionV1(props: DocumentEditingSessionProps) {
  const { editingSession } = props

  // Inferred Values
  const documentName = editingSession.header.title

  // Local State - Document Fetch
  const [documentLoading, setDocumentLoading] = useState<boolean>(false)
  const [documentLoadingErrorString, setDocumentLoadingErrorString] = useState<string | null>(null)
  const [markdownString, setMarkdownString] = useState<string | null>(null)

  // Local State - Annotations
  const [currentAnnotationIndex, setCurrentAnnotationIndex] = useState<number>(0)

  // Filtered Annotations (exclude any annotations not found in source text)
  const filteredAnnotations = editingSession.annotations.filter((annotation) => markdownString?.includes(annotation.source_text))

  // Fetch the document from GCS
  async function handleDocumentFetch(path: string) {
    // Do not refetch
    if (path === '') return
    if (documentLoading || markdownString) return

    try {
      setDocumentLoading(true)
      setDocumentLoadingErrorString(null)
      setMarkdownString(null)

      // Append the markdown file to the document
      const markdownDocument = path + '.md'
      const response = await getMarkdownFileFromGcs(markdownDocument)
      setMarkdownString(response)
    } catch (error) {
      console.error('Error fetching file for annotation markup...', error)
      setDocumentLoadingErrorString('Error fetching file for annotation markup...')
    } finally {
      setDocumentLoading(false)
    }
  }

  // Fetch the document when we have a documentPath
  useEffect(() => {
    handleDocumentFetch(editingSession.header.file_path)
  }, [editingSession.header.file_path])

  return (
    // Accommodate the height of the top bar depending on screen size with top padding

    <div className={'grow flex flex-col h-full overflow-y-scroll pb-10'}>
      <DocumentEditingSessionTopBar
        loading={editingSession.loading}
        editingSessionId={editingSession.header.id}
        documentName={documentName}
        annotations={filteredAnnotations}
        currentAnnotationIndex={currentAnnotationIndex}
        setNewAnnotationIndex={(newAnnotationIndex) => {
          setCurrentAnnotationIndex(newAnnotationIndex)
        }}
      />
      <div className={'pt-32 lg:pt-20'}></div>
      <div className={'flex flex-col items-center justify-center mb-10'}>
        {editingSession.header.user_query && (
          <div className={'w-full max-w-3xl mb-5'}>
            <div className={'flex flex-col items-left'}>
              <p className={`font-bold text-base mb-1 ml-3`}>Query</p>
              <div className={'mb-1 ml-3 text-sm'}>{editingSession.header.user_query}</div>
            </div>
          </div>
        )}

        <div className={'w-full max-w-3xl'}>
          <div className={'flex flex-col items-left'}>
            <div className={'py-2 px-3 bg-brand-neutral-50 rounded-lg'}>
              <p className={`font-bold text-base`}>Summary</p>
              {editingSession.summary.length == 0 && editingSession.loading && (
                <div className={'text-sm text-brand-neutral-500 flex gap-x-2 items-center'}>
                  Generating summary... <CircularProgressContinuousSized size={12} thickness={8} />
                </div>
              )}
              <div className={'text-sm'}>{editingSession.summary}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Loader for while document is downloading */}
      {documentLoading && (
        <div className={'grow flex justify-center pt-16'}>
          <CircularProgressContinuous />
        </div>
      )}

      {/* Errors with loading markdown */}
      {(documentLoadingErrorString || !markdownString) && !documentLoading && (
        <div className={'grow flex justify-center pt-16'}>
          <div className={'justify-center'}>
            <div className={'text-red-700 mb-2'}>Error loading document.</div>
            <button
              className={
                'm-auto block rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400'
              }
              onClick={() => {
                setDocumentLoading(true)
                setDocumentLoadingErrorString(null)
                setMarkdownString(null)
                handleDocumentFetch(editingSession.header.file_path)
              }}
            >
              Retry
            </button>
          </div>
        </div>
      )}

      {/* Render Document */}
      {!documentLoadingErrorString && markdownString && (
        <DocumentEditingRenderDocument
          annotations={filteredAnnotations}
          loading={editingSession.loading}
          error={editingSession?.error}
          currentAnnotationIndex={currentAnnotationIndex}
          markdownString={markdownString}
          documentLoading={documentLoading}
        />
      )}
    </div>
  )
}
