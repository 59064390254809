import React from 'react'
import { useState } from 'react'
import { questions } from '@/components/survey/SurveyQuestions.ts'
import { useAppDispatch } from '@/store/store-hooks'
import { advance, setSurveyResponse } from '../store/onboarding.slice'

type OnboardingFormProps = {
  visible: boolean
}

const OnboardingWelcome: React.FC<OnboardingFormProps> = ({ visible }) => {
  const dispatch = useAppDispatch()
  const [formData, setFormData] = useState({
    primaryUse: '',
    role: '',
    otherPrimaryUse: '',
    otherRole: '',
  })

  if (!visible) return null

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(
      setSurveyResponse({
        responseKey: 'primaryUse',
        responseValue: formData.primaryUse === 'Other' ? `Other: ${formData.otherPrimaryUse}` : formData.primaryUse,
      })
    )
    dispatch(
      setSurveyResponse({
        responseKey: 'role',
        responseValue: formData.role === 'Other' ? `Other: ${formData.otherRole}` : formData.role,
      })
    )
    dispatch(advance())
  }

  const question1Input = questions[0].inputs[0]
  const question2Input = questions[1].inputs[0]
  const question1Options = 'options' in question1Input ? question1Input.options : []
  const question2Options = 'options' in question2Input ? question2Input.options : []

  const isFormValid = formData.primaryUse !== '' && formData.role !== ''

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-10 border-b border-brand-neutral-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-brand-neutral-900/10 sm:border-t sm:pb-0">
        <fieldset>
          <legend className="sr-only">Primary Use</legend>
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
            <div aria-hidden="true" className="text-sm font-semibold leading-6 text-brand-neutral-900">
              Primary Use
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg">
                <p className="text-sm leading-6 text-brand-neutral-600">What's your primary use for Paxton AI?</p>
                <div className="mt-6 space-y-6">
                  {question1Options.map((option) => (
                    <div key={option.value} className="flex items-center gap-x-3">
                      <input
                        id={`primaryUse-${option.value}`}
                        name="primaryUse"
                        type="radio"
                        value={option.value}
                        checked={formData.primaryUse === option.value}
                        onChange={handleInputChange}
                        className="h-4 w-4 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 cursor-pointer"
                      />
                      <label htmlFor={`primaryUse-${option.value}`} className="block text-sm font-medium leading-6 text-brand-neutral-900 cursor-pointer">
                        {option.value}
                      </label>
                    </div>
                  ))}
                  {formData.primaryUse === 'Other' && (
                    <div className="mt-2 ml-7" key="other-primary-use">
                      <input
                        type="text"
                        name="otherPrimaryUse"
                        value={formData.otherPrimaryUse}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
                        placeholder="Please specify"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend className="sr-only">Role</legend>
          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
            <div aria-hidden="true" className="text-sm font-semibold leading-6 text-brand-neutral-900">
              Role
            </div>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg">
                <p className="text-sm leading-6 text-brand-neutral-600">What is your current professional role?</p>
                <div className="mt-6 space-y-6">
                  {question2Options.map((option) => (
                    <div key={option.value} className="flex items-center gap-x-3">
                      <input
                        id={`role-${option.value}`}
                        name="role"
                        type="radio"
                        value={option.value}
                        checked={formData.role === option.value}
                        onChange={handleInputChange}
                        className="h-4 w-4 border-brand-neutral-300 text-brand-500 focus:ring-brand-500 cursor-pointer"
                      />
                      <label htmlFor={`role-${option.value}`} className="block text-sm font-medium leading-6 text-brand-neutral-900 cursor-pointer">
                        {option.value}
                      </label>
                    </div>
                  ))}
                  {formData.role === 'Other' && (
                    <div className="mt-2 ml-7" key="other-role">
                      <input
                        type="text"
                        name="otherRole"
                        value={formData.otherRole}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-0 py-1.5 text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
                        placeholder="Please specify"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="flex items-center justify-end p-3 space-x-4">
        {!isFormValid && <p className="text-sm text-brand-neutral-500 italic">Please complete all questions to move forward.</p>}
        <button
          type="submit"
          className="rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!isFormValid}
        >
          Next
        </button>
      </div>
    </form>
  )
}

export default OnboardingWelcome
