import { Article } from '@mui/icons-material'

type FileOverwriteModalProps = {
  filePaths: string[]
  onOverwrite: () => void
  onUseExisting: () => void
  onCancel: () => void
}

export default function FileOverwriteModal(props: FileOverwriteModalProps) {
  const { filePaths, onOverwrite, onUseExisting, onCancel } = props

  const filesLength = filePaths.length
  const filesText = filesLength > 1 ? 'files' : 'file'
  const existsText = filesLength > 1 ? 'exist' : 'exists'

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onCancel}></div>
      <div className="bg-brand-neutral-50 max-w-xl p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px]">
        <h2 className="text-lg font-semibold">Overwrite?</h2>
        <p className="mt-1 text-sm whitespace-pre-wrap mb-2">
          {filesLength} {filesText} already {existsText} in your drive.
        </p>
        <div className={'max-h-48 overflow-y-scroll bg-brand-neutral-50 border border-brand-neutral-200 rounded-sm p-2 flex flex-col gap-y-3'}>
          {filePaths.map((filePath) => {
            // Isolate the file name at the last part of the path
            const fileName = filePath.split('/').pop()

            // Create a list
            return (
              <div key={filePath} className="">
                <div className="flex items-start gap-x-2">
                  <div className="flex-shrink-0">
                    <Article />
                  </div>
                  <div className="flex-1 text-sm pt-[2px]">{fileName}</div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={'h-4'} />
        <div className={'flex flex-col sm:flex-row gap-y-4 gap-x-4 justify-end'}>
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-100"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={onOverwrite}
          >
            <div>Overwrite and reprocess</div>
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={onUseExisting}
          >
            <div>Use existing</div>
          </button>
        </div>
      </div>
    </div>
  )
}
