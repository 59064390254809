import { useEffect, useState } from 'react'
import ModeSelector from './mode-selector'
import SelectedFilesControl from '../../selected-files/selected-files-control'
import SelectedFilesContent from '../../selected-files/selected-files-content'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { isFilesContentOverLimit } from '@/routes/dashboard/files/files-utils'
import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { selectConversationSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.selectors'
import { useGetTokenLimitQuery } from '@/routes/dashboard/files/apis/limit.api'

type ChatControlsAssistantProps = {
  conversationId: string
}

export default function ChatControlsAssistant({ conversationId }: ChatControlsAssistantProps) {
  const { data: tokenLimit, isLoading: isTokenLimitLoading } = useGetTokenLimitQuery(undefined, {
    pollingInterval: 7200000, // Poll every 2 hours
    refetchOnMountOrArgChange: false, // No immediate refetch on remount (this is the default but just being explicit here)
  })

  const [isFilesOverLimit, setIsFilesOverLimit] = useState(false)

  const isExpanded = useAppSelector((state: RootState) => selectConversationSelectedFilesContentExpanded(state, { chatId: conversationId }))

  // Selected Files State
  const selectedFilePaths = useAppSelector((state: RootState) => selectSelectedFiles(state, { chatId: conversationId }))
  const selectedFileProcessingStatuses = useAppSelector((state: RootState) => selectFileProcessingStatusesWithKeys(state, { keys: selectedFilePaths }))
  const isPendingOrProcessing = (status: FileProcessingStatus) => [FileProcessingStatus.PROCESSING, FileProcessingStatus.PENDING].includes(status)
  const someProcessingOrPending = Object.values(selectedFileProcessingStatuses).some((status) => isPendingOrProcessing(status as FileProcessingStatus))

  // Handle file over-limit check when all files are processed
  useEffect(() => {
    // Reset warning if no files are selected or when files are still processing
    if (selectedFilePaths.length === 0 || someProcessingOrPending) {
      setIsFilesOverLimit(false)
      return
    }

    // Token limit is still loading, do not call isFilesContentOverLimit yet
    if (isTokenLimitLoading) {
      return
    }

    isFilesContentOverLimit(selectedFilePaths, tokenLimit?.max_tokens, tokenLimit?.chunk_size).then((overLimit) => {
      setIsFilesOverLimit(overLimit)
    })
  }, [selectedFilePaths, someProcessingOrPending, tokenLimit, isTokenLimitLoading])

  return (
    <div className="flex flex-wrap mt-2 mb-3 items-end">
      <div className="flex-1 mr-2">
        <ModeSelector conversationId={conversationId} />
      </div>
      <div className="flex-1 min-w-[120px]">
        <SelectedFilesControl chatId={conversationId} isOverLimit={isFilesOverLimit} someProcessingOrPending={someProcessingOrPending} />
      </div>
      {isExpanded && (
        <div className="w-full">
          <SelectedFilesContent chatId={conversationId} isOverLimit={isFilesOverLimit} />
        </div>
      )}
    </div>
  )
}
