import { Link } from 'react-router-dom'

export type ErrorComponentProps = {
  code: string
  title: string
  message: string
  showHomeLink?: boolean
  showRefreshButton?: boolean
}

export default function ErrorComponent(props: ErrorComponentProps) {
  const { code, title, message, showHomeLink, showRefreshButton } = props

  return (
    <>
      <div className="w-full h-full grid place-items-center bg-brand-neutral-50 px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-brand-500">{code}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-brand-neutral-900 sm:text-5xl">{title}</h1>
          <p className="mt-6 text-base leading-7 text-brand-neutral-600">{message}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {showHomeLink && (
              <Link
                to={'/dashboard/chat/lrr_v2'}
                className="rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
              >
                Continue home
              </Link>
            )}
            {showRefreshButton && (
              <button
                onClick={() => window.location.reload()}
                className="rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"
              >
                Refresh
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
