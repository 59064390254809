import { useState, useContext } from 'react'
import { useElements, PaymentElement, useStripe, AddressElement } from '@stripe/react-stripe-js'
import { CircularProgressContinuous } from '@/components/loaders/CircularProgressContinuous'
import { kLinkTermsOfServiceUrl, kPaxtonAppBaseDomain } from '@/constants/constants-links'
import PromoCode from '@/routes/dashboard/subscribe/PromoCode'
import * as Sentry from '@sentry/browser'
import { setLegacyStripeCheckoutVisible } from '@/store/slices/ui-state.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useNavigate } from 'react-router'
import { RootState } from '@/store/store'
import { AuthContext } from '@/context/auth-context'
import { SubscriptionType } from '@/firebase/auth/auth-jwt-schema'

type CheckoutFormProps = {
  checkoutButtonCopy?: string
  supportTrial?: boolean
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { checkoutButtonCopy, supportTrial } = props
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const billingCycle = subscriptionState.billingCycle
  const promotion = subscriptionState.promotion
  const { userAccountData } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState('')

  const subscriptionType = userAccountData?.subscriptionType ?? SubscriptionType.STANDARD

  function handleSubmitError(error: any) {
    setDisplayError(error.message ?? 'An error occurred, please try again.')
    if (error.type == 'card_error' || error.type == 'validation_error') {
      return
    }
    Sentry.captureException(new Error(`Customer checkout: Checkout submission error.`), {
      extra: {
        confirmSetupError: error,
      },
    })
  }

  function handleSubmitException(error: any) {
    Sentry.captureException(new Error(`Customer checkout: An exception was raised on stripe.confirmSetup.`), {
      extra: {
        confirmSetupException: error,
      },
    })
    dispatch(setLegacyStripeCheckoutVisible(true))
    navigate('/dashboard')
  }

  function handleStripeUnavailable() {
    Sentry.captureException(new Error(`Customer checkout: Stripe.js has not loaded yet.`))
    setDisplayError('Payment cannot be processed at this time.')
    setIsLoading(false)
  }

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (!stripe || !elements) {
      handleStripeUnavailable()
      return
    }
    setIsLoading(true)
    try {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${kPaxtonAppBaseDomain()}/pending-subscription?billingCycle=${billingCycle}${
            promotion?.coupon ? `&promotion=${promotion.coupon.id}` : ''
          }`,
        },
      })

      if (error) {
        handleSubmitError(error)
      }
    } catch (error) {
      handleSubmitException(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadingOrIncompleteCardStyles = 'bg-brand-neutral-300 hover:bg-brand-neutral-400'
  const doneLoadingAndCompleteCardStyles = 'cursor-pointer bg-brand-500 hover:bg-brand-400'
  const ctaCopy = checkoutButtonCopy ?? 'Start Trial'

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flex flex-col w-full">
      <AddressElement options={{ mode: 'billing' }} />
      <div className="h-4" />
      <PaymentElement options={{ terms: { card: 'never' } }} />

      <PromoCode />

      {/* Disclaimer with the same styling as the CheckoutContainer, less mt */}

      <div className="text-xs text-brand-neutral-600 mt-2 text-justify max-w-sm">
        We place a ${subscriptionType === SubscriptionType.EDUCATION ? '29' : '199'} preauthorization hold on your credit card to verify payment. This is not a
        charge. The hold is removed immediately. {supportTrial ? 'You will not be charged until your trial ends.' : ''} See our
        <a href="https://www.paxton.ai/pricing" target="_blank" className="text-brand-500 underline ml-1">
          FAQ
        </a>{' '}
        for details.
      </div>

      <div className="text-xs text-brand-neutral-600 mt-4 text-justify max-w-sm">
        By providing your card information, you allow Paxton AI, Inc. to charge your card for future payments in accordance with
        <a href={kLinkTermsOfServiceUrl} target="_blank" rel="noopener noreferrer" className="text-brand-500 underline ml-1">
          our terms
        </a>
        .
      </div>

      <button
        type="submit"
        className={` ${isLoading ? loadingOrIncompleteCardStyles : doneLoadingAndCompleteCardStyles} ${
          isLoading && 'cursor-wait'
        } w-full rounded-md  px-2 py-1 mt-4 text-lg  text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-900 `}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text" className="flex items-center justify-center">
          {isLoading ? (
            <div className="mr-5">
              <CircularProgressContinuous />
            </div>
          ) : (
            ctaCopy
          )}
        </span>
      </button>
      {displayError && <div className="text-red-500 text-sm mt-2">{displayError}</div>}
    </form>
  )
}
