import { UploadFile } from '@mui/icons-material'
import { useState, forwardRef, useImperativeHandle } from 'react'
import { useDropzone } from 'react-dropzone'
import uploadFileHandler, { UploadableFile } from './upload-file-handler'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { kAcceptedFilesList } from '@/constants/constants-gcs'
import { StorageReference } from 'firebase/storage'
import { kSegmentTrackFileUploadStarted } from '@/constants/constants-segment'
import { useAppDispatch } from '@/store/store-hooks'
import { addFileUploadTask, FileProcessingStatus, setFileProcessingStatus, updateFileUploadTask } from '@/store/slices/file-upload-tasks.slice'
import { checkIfFilesExistInGCS } from './files-utils'
import { FileUploadOverwriteConfirmationOptions } from './file-overwrite-confirmation/file-overwrite-modal-context'
import { useFileOverwriteModal } from './file-overwrite-confirmation/file-overwrite-model-hooks'
import { UploadDisclaimer } from '@/constants/constants-components'

//possibly change the name for something specific to the chat
export type UploadTaskStatus = {
  progress: number
  complete: boolean
  errorMessage: string
  filePath: string
  sourcePath: string
  chatId?: string
  uploadableFileId: string // This should match UploadTaskStatusWithFile.uploadableFile.id
}

//Create this type since creating a slice for upload tasks, Redux does not allow to set non serialized values like Map/File
export type UploadTaskStatusWithFile = UploadTaskStatus & {
  uploadableFile: UploadableFile
}

export type FilesDropZoneWithTaskStateProps = {
  onTaskListItemUpdated: (task: UploadTaskStatusWithFile, allTasks: UploadTaskStatus[]) => void
  currentFolder: StorageReference
  chatId: string
  singleFileLimit?: boolean
  showFolderName?: boolean
  hideDropZoneUI?: boolean
  onTaskListItemCompleted?: (task: UploadTaskStatusWithFile) => void
  onNewTaskCreated?: (task: UploadTaskStatusWithFile) => void
  gcsConfirmOverwrite?: boolean
  onUseExistingFileOption?: (task: UploadTaskStatusWithFile) => void
}

export interface FilesDropZoneRef {
  openSystemFileSelector: () => void
}

const FilesDropZoneWithTaskState = forwardRef<FilesDropZoneRef, FilesDropZoneWithTaskStateProps>(function FilesDropZone(props, ref) {
  const {
    currentFolder,
    singleFileLimit,
    hideDropZoneUI = false,
    chatId,
    onTaskListItemUpdated,
    onTaskListItemCompleted,
    onNewTaskCreated,
    gcsConfirmOverwrite = false,
    onUseExistingFileOption,
  } = props

  const dispatch = useAppDispatch()
  const { showFileOverwriteConfirmation } = useFileOverwriteModal()

  // Local state
  const [dropError, setDropError] = useState<string | null>(null)

  useImperativeHandle(ref, () => ({
    openSystemFileSelector: open,
  }))

  const completeTask = (newTask: UploadTaskStatusWithFile): void => {
    if (onTaskListItemCompleted) {
      onTaskListItemCompleted(newTask)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uploadableFile, ...rest } = newTask
    try {
      dispatch(updateFileUploadTask({ ...rest, progress: 100, complete: true }))
    } catch (error) {
      Sentry.captureException(new Error(`Can not complete file upload task: ${error}`))
    }
  }

  // Update the progress of a task
  const updateTaskProgress = (newTask: UploadTaskStatusWithFile, progress: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uploadableFile, ...rest } = newTask
    try {
      onTaskListItemUpdated(newTask, [])
      dispatch(updateFileUploadTask({ ...rest, progress: progress, complete: false }))
    } catch (error) {
      Sentry.captureException(new Error(`Can not update file upload task: ${error}`))
    }
  }

  //handle error task
  const handleTaskError = (newTask: UploadTaskStatusWithFile, error: Error): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uploadableFile, ...rest } = newTask
    Sentry.captureException(new Error(`Handling file upload task error: ${error}`))

    //update task with error message
    dispatch(updateFileUploadTask({ ...rest, errorMessage: error.message, complete: false }))
    //update processing status
    dispatch(setFileProcessingStatus({ ...rest, status: FileProcessingStatus.ERROR }))
  }

  const onDrop = async (acceptedFiles: File[]) => {
    setDropError(null)

    if (acceptedFiles.length === 0) {
      console.warn('No accepted files to upload.')
      setDropError('No accepted files to upload.')
      return
    }

    // Track
    analytics.track(kSegmentTrackFileUploadStarted, {
      fileCount: acceptedFiles.length,
    })

    // Construct Upload Tasks For All Accepted Files
    const acceptedFileTasks: UploadTaskStatusWithFile[] = acceptedFiles.map((file) => {
      // Create a unique persistent id for the file
      const fileId = nanoid()

      // Create an Uploadable File
      const uploadableFile: UploadableFile = {
        file: file,
        fileName: file.name,
        id: fileId,
      }

      // Initialize the task
      const newUploadTask: UploadTaskStatus = {
        progress: 0,
        complete: false,
        errorMessage: '',
        filePath: file.name,
        sourcePath: `${currentFolder.fullPath}/${file.name}`,
        uploadableFileId: fileId,
      }

      const taskWithFileUpload: UploadTaskStatusWithFile = { ...newUploadTask, uploadableFile: uploadableFile }

      return taskWithFileUpload
    })

    /**
     * If gcsConfirmOverwrite is true and we have provided an onUseExistingFile callback,
     * 1. Check if the file(s) already exists in the storage ref folder
     * 2. If any do, show a confirmation dialog to the user to
     *   - Overwrite the file(s)
     *   - Use the existing file(s)
     *     - This will add the tasks to useExistingTasks for different handling
     *   - Cancel the upload
     */
    let useExistingTasks: UploadTaskStatusWithFile[] = []

    // Allow flexibility for potential future use where gcsConfirmOverwrite is true but no onUseExistingFileOption callback is provided (only overwrite option)
    if (gcsConfirmOverwrite && onUseExistingFileOption) {
      // Create a list of file paths to check
      const pathsToCheck = acceptedFiles.map((file) => `${currentFolder.fullPath}/${file.name}`)

      // Check if the file already exists at this google cloud storage reference
      const existingFiles: string[] = await checkIfFilesExistInGCS(pathsToCheck)

      // If any files already exist, show a confirmation dialog
      if (existingFiles.length > 0) {
        try {
          // Show confirmation dialog and wait for user response
          const userChoice = await showFileOverwriteConfirmation(existingFiles)

          switch (userChoice) {
            case FileUploadOverwriteConfirmationOptions.CANCEL: {
              // User canceled the upload and return early
              return
            }
            case FileUploadOverwriteConfirmationOptions.USE_EXISTING: {
              // Identify the tasks we will skip uploading
              useExistingTasks = acceptedFileTasks.filter((task) => existingFiles.includes(task.sourcePath))
              break
            }
            case FileUploadOverwriteConfirmationOptions.OVERWRITE: {
              // Proceed with all files, do nothing here
              break
            }
            default:
              Sentry.captureException(new Error(`Invalid file overwrite choice: ${userChoice}`))
              break
          }
        } catch (error) {
          Sentry.captureException(new Error(`Error showing file overwrite confirmation dialog`), { extra: { error: JSON.stringify(error) } })
          return
        }
      }
    }

    // Process all accepted files
    acceptedFileTasks.forEach(async (task) => {
      // IF useExistingTasks includes this task, we will not upload it
      // Instead, we will call the onUseExistingFileOption callback and return
      if (onUseExistingFileOption && useExistingTasks.includes(task)) {
        onUseExistingFileOption(task)
        return
      }

      // Call onNewTaskCreated callback if provided
      if (onNewTaskCreated) {
        onNewTaskCreated(task)
      }

      // Execute the upload handler
      uploadFileHandler({
        uploadableFile: task.uploadableFile,
        currentFolder,
        onProgress: function (progress: number): void {
          updateTaskProgress(task, progress)
        },
        onError: function (error: Error): void {
          handleTaskError(task, error)
        },
        onComplete: function (): void {
          completeTask(task)
        },
      })
    })
  }

  // On Drop Rejected
  const onDropRejected = (fileRejections: any) => {
    if (singleFileLimit && fileRejections.length > 1) {
      setDropError('Please upload a single file. Use your drive to upload multiple files.')
      return
    }

    fileRejections.forEach((fileRejection: any) => {
      const extensionMatch = fileRejection?.file?.path.match(/\.[0-9a-z]+$/i)
      const extension = extensionMatch ? extensionMatch[0] : 'unknown'
      const errorMessage = `Unsupported file type ${extension}`

      // Initialize the task and add it to state
      const newUploadTask: UploadTaskStatus = {
        progress: 0,
        complete: false,
        errorMessage: errorMessage,
        filePath: fileRejection.file.name,
        sourcePath: currentFolder + fileRejection.file.name,
        uploadableFileId: '',
        chatId: chatId,
      }
      addFileUploadTask([newUploadTask])
    })
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept: kAcceptedFilesList,
    maxFiles: singleFileLimit ? 1 : undefined,
    noClick: hideDropZoneUI,
  })

  return (
    <>
      {!hideDropZoneUI ? (
        <div
          className={`p-4 rounded-lg border-2 border-brand-500 transition-all duration-300 ${isDragActive ? 'bg-brand-50' : 'border-opacity-20 '} border-dashed`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <div className={'text-center'}>
            <div className={'mb-3'}>
              <UploadFile />
            </div>
            <p>
              Drag & Drop or <span className={'text-brand-500 underline cursor-pointer'}>Choose{singleFileLimit ? ' a File' : ' Files'}</span>
            </p>
            <p className={'mt-1 text-sm text-brand-neutral-600'}>Supported formats {Object.values(kAcceptedFilesList).flat().join(', ')}</p>
            <UploadDisclaimer />
            {dropError && <p className={'mt-1 text-sm text-red-700'}>{dropError}</p>}
          </div>
        </div>
      ) : (
        // Render an invisible dropzone to capture events when UI is hidden
        <div {...getRootProps({ style: { display: 'none' } })}>
          <input {...getInputProps()} />
        </div>
      )}
    </>
  )
})

export default FilesDropZoneWithTaskState
