import { getBrandFromHost, getLogoFromBrand, isPaxtonBrand } from '@/util/enterprise'
import { AuthContext } from '@/context/auth-context'
import { useContext } from 'react'
import { CircularProgressContinuousSized } from './CircularProgressContinuous'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)
const isPaxton = isPaxtonBrand(hostBrand)

type FullPageLoaderProps = {
  statusMessage?: string
}

const FullPageLoader: React.FC<FullPageLoaderProps> = ({ statusMessage }) => {
  const authContext = useContext(AuthContext)
  const { user } = authContext
  const userIsAnon = !(user?.isAnonymous === false)

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {(isPaxton || !userIsAnon) && <img className="mx-auto w-[240px]" src={brandLogoUrl} alt={hostBrand} />}
        <h2 className="mt-6 text-center text-1xl leading-9 tracking-tight text-brand-neutral-900">{statusMessage ? statusMessage : ''}</h2>
        <div className="mt-10 text-center">
          <CircularProgressContinuousSized size={24} thickness={5} />
        </div>
      </div>
    </div>
  )
}

export default FullPageLoader
