import { useState, useContext } from 'react'
import { advance } from '@/onboarding/store/onboarding.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { AuthContext } from '@/context/auth-context'
import * as Switch from '@radix-ui/react-switch'
import OnboardingOrganizationSetup from '@/onboarding/components/OnboardingOrganizationSetup'
import { useCreateOrganizationMutation } from '@/onboarding/store/onboarding.slice.ts'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

const CreateOrganizationCard = () => {
  const { refreshUserAccountData } = useContext(AuthContext)
  const [enabled, setEnabled] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [emailValue, setEmailValue] = useState('')
  const [annualBilling, setAnnualBilling] = useState(true)
  const [createOrganization, { isLoading, error }] = useCreateOrganizationMutation()
  const [finalLoading, setFinalLoading] = useState(false)

  const dispatch = useAppDispatch()

  const handleAdvance = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const billingFrequency = annualBilling ? 'annual' : 'monthly'

    if (enabled && orgName) {
      try {
        setFinalLoading(true)
        const result = await createOrganization({ name: orgName, seats: 1, users: emails, billing_frequency: billingFrequency }).unwrap()
        if (result.organization) {
          dispatch(advance())
          await new Promise((resolve) => setTimeout(resolve, 2000))
          refreshUserAccountData()
        }
      } catch (error) {
        console.error('Failed to create organization:', error)
      }
    } else {
      dispatch(advance())
    }
  }

  const isCreateDisabled = !orgName.trim() || isLoading

  return (
    <div className="bg-brand-neutral-50 shadow rounded-lg mt-6">
      <div className="p-4">
        <div className=" py-5">
          <h3 className="text-xl font-semibold text-brand-neutral-900">Create an organization</h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-brand-neutral-500">
              <p>Create an organization in Paxton to unlock collaboration features, centralize billing for your team, and manage organizational settings.</p>
            </div>
            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <div className="flex items-center">
                <Switch.Root
                  className="w-[42px] h-[25px] bg-brand-neutral-400 hover:bg-brand-neutral-500 rounded-full relative shadow-black focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-brand-500 outline-none cursor-pointer"
                  id="airplane-mode"
                  checked={enabled}
                  onCheckedChange={setEnabled}
                >
                  <Switch.Thumb className="block w-[21px] h-[21px] bg-brand-neutral-50 rounded-full shadow-[0_1px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                </Switch.Root>
              </div>
            </div>
          </div>
          {enabled && (
            <>
              <OnboardingOrganizationSetup
                orgName={orgName}
                setOrgName={setOrgName}
                emails={emails}
                setEmails={setEmails}
                emailValue={emailValue}
                setEmailValue={setEmailValue}
                annualBilling={annualBilling}
                setAnnualBilling={setAnnualBilling}
              />
              <div className="mt-6 flex items-center justify-end space-x-4">
                {isCreateDisabled && !isLoading && (
                  <p className="text-sm text-brand-neutral-500 italic">Please enter an organization name to create the organization.</p>
                )}
                <button
                  type="button"
                  className={`flex items-center gap-2 rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 disabled:opacity-50 disabled:cursor-not-allowed`}
                  onClick={handleAdvance}
                  disabled={isCreateDisabled || finalLoading || isLoading}
                >
                  Create my organization
                  {(isLoading || finalLoading) && <CircularProgressContinuousSized hexColor={'#eee'} size={20} thickness={4} />}
                </button>
                {error && <p className="text-red-500">Error creating organization</p>}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateOrganizationCard
