import { createApi } from '@reduxjs/toolkit/query/react'
import { mockBaseQuery } from '../mocks/mock-data'
import { TreeNode } from '../types'

export const getFilesApi = createApi({
  reducerPath: 'getFilesApi',
  baseQuery: mockBaseQuery,
  endpoints: (build) => ({
    getAllFiles: build.query<TreeNode, void>({
      query: () => '',
    }),
  }),
})

export const { useGetAllFilesQuery } = getFilesApi
