import { chatV2Api } from '@/chat-common/chat-v2-api'
import SignInForm from '@/components/auth/sign-in-form'
import { useAppDispatch } from '@/store/store-hooks'
import { ArrowRightAlt } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { getBrandFromHost, getLogoFromBrand } from '@/util/enterprise'

const hostBrand = getBrandFromHost()
const brandLogoUrl = getLogoFromBrand(hostBrand)

export default function SignInPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto w-[200px] sm:w-[280px]" src={brandLogoUrl} alt={hostBrand} />
          <p className={'text-brand-500 underline text-center mt-4'}>
            <Link to={'/'}>
              Try it without an account <ArrowRightAlt />
            </Link>
          </p>
        </div>

        <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-brand-neutral-50 px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <SignInForm
              switchToForgotPassword={() => {
                navigate('/forgot-password')
              }}
              switchToSignUp={() => navigate('/sign-up')}
              onSuccess={() => {
                dispatch(chatV2Api.util.resetApiState())
                navigate('/dashboard')
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
