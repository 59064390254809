// Copy search string button
// Returns null if there is no matched search string

import { Toast } from '@/components/ui/toast'
import { kSegmentTrackBooleanComposerCopySearchString } from '@/constants/constants-segment'
import { ContentCopy } from '@mui/icons-material'
import { useState } from 'react'

type CopySearchStringButtonProps = {
  matchedSearchString: string | null
}

export default function CopySearchStringButton(props: CopySearchStringButtonProps) {
  const { matchedSearchString } = props

  // Toast State
  const [toastOpen, setToastOpen] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const [toastDescription, setToastDescription] = useState('')

  if (matchedSearchString == null) {
    return null
  }

  return (
    <>
      <Toast
        title={toastTitle}
        description={toastDescription}
        open={toastOpen}
        onOpenChange={(open: boolean) => {
          setToastOpen(open)
        }}
      />
      <button
        onClick={() => {
          // Track
          analytics.track(kSegmentTrackBooleanComposerCopySearchString)

          navigator.clipboard.writeText(matchedSearchString).then(
            function () {
              setToastTitle('Copied')
              setToastDescription('Search string copied to clipboard')
              setToastOpen(true)
              setTimeout(() => {
                setToastOpen(false)
              }, 3000)
            },
            function (_) {
              setToastTitle('Error')
              setToastDescription('Could not copy search string')
              setToastOpen(true)
              setTimeout(() => {
                setToastOpen(false)
              }, 3000)
            }
          )
        }}
        className={
          'mb-2 sm:ml-2 float-none sm:float-right w-full sm:w-auto rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
        }
      >
        Copy search string &nbsp; <ContentCopy fontSize={'small'} />
      </button>
    </>
  )
}
