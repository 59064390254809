import { ChatV2QueryMetadataLrrV2 } from '@/chat-common/schemas/chat-query-metadata-schema'
import { LrrSourceSource } from '@/store/apis/hosted-filters-api'
import LrrSourceDialogPathDrillDown from './lrr-source-dialog-path-drilldown'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type LRRSourceDialogSourceDrillDownProps = {
  conversationId: string
  sources: LrrSourceSource[]
  sourceHandlers: {
    addingSourcesDispatcher: (source: LrrSourceSource, conversationId: string) => void
    removingSourcesDispatcher: (source: LrrSourceSource, conversationId: string) => void
  }
}

export default function LRRSourceDialogSourceDrillDown(props: LRRSourceDialogSourceDrillDownProps) {
  const { sources, conversationId, sourceHandlers } = props

  // Redux state selectors
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataLrrV2 | null
  const currentSourceSelections = currentSource?.lrr_selections ?? []

  function allowSectionSearch(source: LrrSourceSource) {
    return source.children && source.children.length > 0
  }

  function areAllSourcesChecked(sources: LrrSourceSource[]) {
    return sources.every((source) => currentSourceSelections.some((selection) => selection.source_name === source.value))
  }

  return (
    <div className={'flex flex-col gap-y-2 pl-11 mb-4 mt-1'}>
      {sources.map((source) => {
        // Checked
        const checked = currentSourceSelections.some((selection) => selection.source_name === source.value)
        return (
          <div key={`drill-${source.value}`}>
            <div className={`cursor-default select-none grid grid-cols-[24px_auto] items-center`}>
              <input
                id={`item-${source.value}`}
                type="checkbox"
                checked={source.sources ? areAllSourcesChecked(source.sources) : checked}
                onChange={(event) => {
                  event.target.checked
                    ? sourceHandlers.addingSourcesDispatcher(source, conversationId)
                    : sourceHandlers.removingSourcesDispatcher(source, conversationId)
                }}
                className="h-4 w-4 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
              />

              <label htmlFor={`item-${source.value}`} className={'text-sm'}>
                {source.label}
              </label>
            </div>
            {source.sources && source.sources.length > 0 && (
              <LRRSourceDialogSourceDrillDown conversationId={conversationId} sources={source.sources} sourceHandlers={sourceHandlers} />
            )}
            {allowSectionSearch(source) && <LrrSourceDialogPathDrillDown source={source} conversationId={conversationId} />}
          </div>
        )
      })}
    </div>
  )
}
