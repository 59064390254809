// Screen Sizes
export enum ScreenSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

// Breakpoints
export const SMALL_BREAKPOINT = 940
export const MEDIUM_BREAKPOINT = 1600

/**
 * Get Screen Size
 * @returns
 */
export function getScreenSize(): ScreenSize {
  const width = window.innerWidth
  if (width < SMALL_BREAKPOINT) {
    return ScreenSize.SMALL
  } else if (width < MEDIUM_BREAKPOINT) {
    return ScreenSize.MEDIUM
  } else {
    return ScreenSize.LARGE
  }
}
